/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TradeCentricInitializeLogInRequest } from '../models/TradeCentricInitializeLogInRequest';
import type { TradeCentricInitializeLogInResponse } from '../models/TradeCentricInitializeLogInResponse';
import type { TradeCentricPunchOutRequest } from '../models/TradeCentricPunchOutRequest';
import type { TradeCentricStartUrl } from '../models/TradeCentricStartUrl';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class TradeCentricService {

    /**
     * Processes a PunchOut request from TradeCentric
     * This request contains all necessary data for the supplier to (1) search its database for an existing customer account; (2) if the account does not exist, auto-provision it; (3) generate a new session for this user; and (4) respond with a tokenized URL to which the user's browser will be redirected.
     * @returns TradeCentricStartUrl customer account exists for this user; user may be redirected to the provided URL to begin their PunchOut session
     * @throws ApiError
     */
    public static createPunchOutRequest({
        requestBody,
    }: {
        requestBody?: TradeCentricPunchOutRequest,
    }): CancelablePromise<TradeCentricStartUrl> {
        return __request({
            method: 'POST',
            path: `/tradeCentric/punchout`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `some error occurred while attempting to authenticate or auto-provision this customer account; redirect the user to an appropriate user-friendly error page on the supplier's storefront`,
            },
        });
    }

    /**
     * Returns everything necessary to sing user in and redirect to a needed page based on punch out request ID.
     * @returns TradeCentricInitializeLogInResponse successful authentication response
     * @throws ApiError
     */
    public static initializeLogIn({
        requestBody,
    }: {
        requestBody?: TradeCentricInitializeLogInRequest,
    }): CancelablePromise<TradeCentricInitializeLogInResponse> {
        return __request({
            method: 'POST',
            path: `/tradeCentric/punchout/actions/initializeLogIn`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `some error occurred while attempting to authenticate`,
            },
        });
    }

}