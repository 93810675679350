import * as React from "react";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

interface Props {
  label: string | React.ReactNode;
  value?: string | React.ReactNode;
  verticalAlignment?: "top" | "bottom" | "center";
}

const LabelValue: React.FC<Props> = ({ label, value, verticalAlignment = "center" }) => (
  <Stack alignItems={verticalAlignment} flexWrap="wrap" direction="row" spacing={1}>
    <Typography sx={theme => ({ color: theme.palette.grey["700"], fontWeight: 600 })}>{label}:</Typography>
    <Box sx={{ flexGrow: 1 }}>{value}</Box>
  </Stack>
);

export { LabelValue };
