import { TFunction } from "react-i18next";
import { UserGroup } from "../../gen/clients/llts";

export const INHOUSE_ROLES = [
  UserGroup.CLIENT_MANAGER,
  UserGroup.DESKTOP_PUBLISHER,
  UserGroup.ADMIN,
  UserGroup.ENGINEER,
  UserGroup.PROJECT_COORDINATOR,
  UserGroup.QA,
  UserGroup.QUOTER,
  UserGroup.POWER_USER
];

export const getTableHeads = (langFunc: TFunction, resourceKey: string): string[] => {
  const headerMap = langFunc(`DASHBOARD.${resourceKey}.headers`).split(",");
  return headerMap.map((head: string) => langFunc(`DASHBOARD.headers.${head}`));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const showDashBoards = (roles: (UserGroup | string)[], isLoggedIn: boolean, username: string | undefined) =>
  (isLoggedIn && username) || INHOUSE_ROLES.filter(value => roles.includes(value)).length > 0;
