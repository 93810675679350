import React, { FC } from "react";
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Grid,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DashBoardComponent, { DashboardType } from "../../DashBoardComponent";

export type AccordionType = {
  title: string;
  dashboardComps?: null | DashboardType[];
  embeddedAccordion?: null | AccordionType;
  id: string;
  onChangeListener: (expanded: boolean, id: string) => void;
  expansionState: (id: string) => boolean;
};

export const DashboardAccordion: FC<AccordionType> = props => {
  const { title, dashboardComps = null, onChangeListener, id, expansionState } = props;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    onChangeListener(isExpanded, panel);
  };

  const handleExpansionState = (panel: string) => expansionState(panel);

  return (
    <>
      <MUIAccordion
        style={{ marginBottom: 20 }}
        onChange={handleChange(id)}
        expanded={handleExpansionState(id)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {dashboardComps && (
            <>
              <Container maxWidth="xl">
                <Grid container spacing={2}>
                  {dashboardComps.map((comp, index) => (
                    <DashBoardComponent key={`${title}${index}`} {...comp} />
                  ))}
                </Grid>
              </Container>
            </>
          )}
        </AccordionDetails>
      </MUIAccordion>
    </>
  );
};
