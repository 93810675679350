import * as React from "react";
import { ClientConfig, JobRequestOrigination } from "gen/clients/llts";
import { InitialEntryForm } from "./components/InitialEntryForm/InitialEntryForm";
import { ProjectEntryForm } from "./components/ProjectEntryForm/ProjectEntryForm";
import { UnknownUserCreateJobRequestPage } from "../../CreateJobRequestPage/UnknownUserCreateJobRequestPage/UnknownUserCreateJobRequestPage";

const UnsignedInUserPage: React.FC = () => {
  const [email, setEmail] = React.useState<string>();
  const [clientConfig, setClientConfig] = React.useState<ClientConfig>();

  const onInitialEntrySuccess = React.useCallback((providedEmail: string, loadedClientConfig?: ClientConfig) => {
    setEmail(providedEmail);
    setClientConfig(loadedClientConfig);
  }, []);

  if (email && clientConfig && clientConfig.status === "ACTIVE") {
    return <ProjectEntryForm email={email} clientConfig={clientConfig} />;
  }
  if (email) {
    return (
      <UnknownUserCreateJobRequestPage
        email={email}
        origination={JobRequestOrigination.WEBFORM}
        allowSendToTranslation={false}
      />
    );
  }
  return <InitialEntryForm onSuccess={onInitialEntrySuccess} />;
};

export { UnsignedInUserPage };
