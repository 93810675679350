import React from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { AlertDialog } from "components/AlertDialog/AlertDialog";
import { ReportMetadata, ReportsService } from "gen/clients/llts";
import { Box, LinearProgress, Stack } from "@mui/material";
import { createExporter } from "./DataExporter";
import { ReportExportType } from "./ExportTypes";

interface Props {
  report: ReportMetadata;
  exportType: ReportExportType;
  filters: string | undefined;
}

const ExportReportData: React.FC<Props> = ({ report, exportType, filters }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(ReportsService.getReportRows, {
    onSuccess: data => {
      const exporter = createExporter(exportType);
      const { blob, filename } = exporter.exportData(report, data);

      const a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);

      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      a.click();
    },
    retry: 3
  });

  const onExport = React.useCallback(() => {
    mutate({
      reportId: report.id,
      filters,
      page: 1,
      limit: 1000
    });
  }, [mutate, report.id, filters]);

  return (
    <>
      <Button startIcon={<SaveAltIcon />} onClick={onExport}>
        {t("reports.exportData.button.title")}
      </Button>
      {isLoading && (
        <AlertDialog title={t("reports.exportData.alert.title")}>
          <Stack spacing={1}>
            <Box>{t("reports.exportData.alert.text")}</Box>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </Stack>
        </AlertDialog>
      )}
    </>
  );
};

export { ExportReportData };
