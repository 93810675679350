import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { JobRequest, JobRequestFileCategory } from "gen/clients/llts";
import { JobRequestFileList } from "components/jobRequests/JobRequestFileList/JobRequestFileList";

interface Props {
  jobRequest: JobRequest;
}

const SourceFilesTab: React.FC<Props> = ({ jobRequest }) => {
  const sourceFiles = React.useMemo(
    () =>
      jobRequest.files
        ?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT)
        ?.sort((f1, f2) =>
          f1.fileName
            .substring(0, f1.fileName.lastIndexOf("."))
            .localeCompare(f2.fileName.substring(0, f2.fileName.lastIndexOf(".")))
        ),
    [jobRequest]
  );

  const referenceFiles = React.useMemo(
    () =>
      jobRequest.files
        ?.filter(f => f.category === JobRequestFileCategory.REFERENCE)
        ?.sort((f1, f2) =>
          f1.fileName
            .substring(0, f1.fileName.lastIndexOf("."))
            .localeCompare(f2.fileName.substring(0, f2.fileName.lastIndexOf(".")))
        ),
    [jobRequest]
  );

  const sourceFileLinks = React.useMemo(
    () => jobRequest.fileLinks?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT),
    [jobRequest]
  );

  return (
    <Stack spacing={3}>
      {!!sourceFiles?.length && (
        <Stack spacing={1}>
          <Typography fontWeight="bold">Source Files</Typography>
          <JobRequestFileList jobRequestId={jobRequest.id} files={sourceFiles} />
        </Stack>
      )}
      {!!referenceFiles?.length && (
        <Stack spacing={1}>
          <Typography fontWeight="bold">Reference Files</Typography>
          <JobRequestFileList jobRequestId={jobRequest.id} files={referenceFiles} />
        </Stack>
      )}
      {!!sourceFileLinks?.length && (
        <Stack spacing={1}>
          <Typography fontWeight="bold">Source File Links</Typography>
          <Stack spacing={0.5}>
            {sourceFileLinks.map(fileLink => (
              <Link href={fileLink.url} target="_blank">
                {fileLink.name}
              </Link>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export { SourceFilesTab };
