import * as React from "react";
import { Drawer } from "@mui/material";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { useQuery } from "react-query";
import {
  ClientConfigsService,
  CustomFieldDefinitionCategory,
  JobRequestFile,
  UserAccountsService
} from "gen/clients/llts";
import { useUserSession } from "hooks/useUserSession";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RequestHumanTranslationPanelForm } from "./RequestHumanTranslationPanelForm";

interface Props {
  jobRequestId: string;
  requestName?: string;
  sourceLanguageId: string;
  targetLanguageIds: string[];
  files: JobRequestFile[];
  onClose: () => void;
  onSuccess: () => void;
}

const RequestHumanTranslationPanel: React.FC<Props> = ({
  jobRequestId,
  requestName,
  sourceLanguageId,
  targetLanguageIds,
  files,
  onClose,
  onSuccess
}) => {
  const { t } = useTranslation();
  const { email } = useUserSession();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    data: userAccount,
    isLoading: isUserAccountLoading,
    error: userAccountError
  } = useQuery(["getUserAccount", email], {
    queryFn: UserAccountsService.getUserAccount
  });
  const clientId = userAccount?.clientConfig?.clientId;
  const {
    data: projectCustomFieldDefinitions,
    isLoading: areCustomFieldDefinitionsLoading,
    error: customFieldDefinitionsError
  } = useQuery(["listCustomFieldDefinitions", clientId, CustomFieldDefinitionCategory.PROJECT], {
    enabled: !!clientId,
    queryFn: () =>
      ClientConfigsService.getCustomFieldDefinitions({
        clientId: clientId || "",
        category: CustomFieldDefinitionCategory.PROJECT
      })
  });

  const isLoading = isUserAccountLoading || areCustomFieldDefinitionsLoading;
  const error = userAccountError || customFieldDefinitionsError;

  const content = React.useMemo(() => {
    if (error) {
      return <ApiErrorMessage apiError={error} />;
    }
    if (isLoading || !clientId || !projectCustomFieldDefinitions || !email) {
      return <LoadingPage />;
    }
    return (
      <RequestHumanTranslationPanelForm
        jobRequestId={jobRequestId}
        email={email}
        requestName={requestName}
        sourceLanguageId={sourceLanguageId}
        targetLanguageIds={targetLanguageIds}
        projectCustomFieldDefinitions={projectCustomFieldDefinitions}
        files={files}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    );
  }, [
    error,
    isLoading,
    clientId,
    projectCustomFieldDefinitions,
    email,
    jobRequestId,
    requestName,
    sourceLanguageId,
    targetLanguageIds,
    files,
    onClose,
    onSuccess
  ]);

  return (
    <Drawer open={true} anchor="right" PaperProps={{ sx: { width: isSmallScreen ? "100vw" : "50vw" } }}>
      <DrawerHeader title={t("machineTranslation.requestHumanTranslation.title")} onClose={onClose} />
      <DrawerBody>{content}</DrawerBody>
    </Drawer>
  );
};

export { RequestHumanTranslationPanel };
