/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TradeCentricPunchOutItem } from './TradeCentricPunchOutItem';

export type TradeCentricPunchOutRequest = {
    /**
     * The shared secret for TradeCentric transactions
     */
    sharedSecret: string;
    /**
     * The unique key for this PunchOut session.
     */
    pos: string;
    /**
     * For the 'create' operation, the buyer expects to be given a new empty cart. For the 'edit' and 'inspect' operations, the buyer will send an array of item data in body.data.cart_items with the expectation that the supplier will rebuild or retrieve a cart defined by that item data and will redirect the buyer to the cart page. The difference between 'edit' and 'inspect' is that a buyer should only be allowed to make changes to the cart if they initiated the PunchOut as an 'edit' session.
     */
    operation?: TradeCentricPunchOutRequest.operation;
    /**
     * Location for the cart to be posted to which is unique per punchout session. This can be server side or client side request. Please confirm with you Project Manager
     */
    return_url: string;
    /**
     * The object containing all parameters defining the user's PunchOut request.
     */
    params: {
        /**
         * This can be ignored.
         */
        header?: any;
        /**
         * Static value.
         */
        type?: string;
        /**
         * This can be ignored.
         */
        mode?: TradeCentricPunchOutRequest.mode;
        body: {
            /**
             * Any user-level identifiers or other data fields are added here.
             */
            data?: any;
            contact: {
                /**
                 * The user's email address is passed from the buyer's procurement system where provided. Should the buyer not provide a email address the TradeCentric will either provide a default address for all user connecting from that Trading Partner or implement our round robin solution for an array of addresses to be used.
                 */
                email: string;
                name?: string;
                unique?: string;
            };
            /**
             * Can be ignored.
             */
            buyercookie?: string;
            /**
             * The URL to which TradeCentric will return the cart. Can be ignored.
             */
            postform?: string;
            shipping?: {
                data?: {
                    address_name?: string;
                    shipping_id?: string;
                    shipping_business?: string;
                    shipping_to?: string;
                    shipping_street?: string;
                    shipping_city?: string;
                    shipping_state?: string;
                    shipping_zip?: string;
                    shipping_country?: string;
                    country_id?: string;
                };
            };
            /**
             * The set of item data that defines a cart that the buyer wishes to be rebuilt or retrieved on the supplier's storefront. Only attempt to rebuild or retrieve this cart if operation is 'edit' or 'inspect'.
             */
            items?: Array<TradeCentricPunchOutItem>;
        };
        custom?: {
            /**
             * Name to represent the buying organisation
             */
            organisation_name?: string;
            /**
             * ID to represent the buying organisation like an account number.
             */
            organisation_id?: string;
        };
    };
}

export namespace TradeCentricPunchOutRequest {

    /**
     * For the 'create' operation, the buyer expects to be given a new empty cart. For the 'edit' and 'inspect' operations, the buyer will send an array of item data in body.data.cart_items with the expectation that the supplier will rebuild or retrieve a cart defined by that item data and will redirect the buyer to the cart page. The difference between 'edit' and 'inspect' is that a buyer should only be allowed to make changes to the cart if they initiated the PunchOut as an 'edit' session.
     */
    export enum operation {
        CREATE = 'create',
        EDIT = 'edit',
        INSPECT = 'inspect',
    }

    /**
     * This can be ignored.
     */
    export enum mode {
        PRODUCTION = 'production',
        TEST = 'test',
    }


}
