import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Auth } from "@aws-amplify/auth";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDialogState } from "../../../../hooks/useDialogState/useDialogState";
import { useUserSession } from "../../../../hooks/useUserSession";
import { ImpersonateUserDialog } from "./ImpersonateUserDialog";
import { UserGroup } from "../../../../gen/clients/llts";
import { signIn } from "../../../../store/userSession/userSessionActions";
import { useUserSessionOverride } from "../../../../hooks/useUserSessionOverride";
import { AccountSettingsPanel } from "./AccountSettingsPanel/AccountSettingsPanel";

interface Props {
  username: string;
}

const UserMenu: React.FC<Props> = ({ username }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { roles } = useUserSession();
  const [userMenuAnchor, setUserMenuAnchor] = React.useState<HTMLElement | null>(null);
  const [isAccountSettingsOpen, openAccountSettings, closeAccountSettings] = useDialogState();
  const [isLogInAsOpen, openLogInAs, closeLogInAs] = useDialogState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [userSessionOverride, , clearUserSessionOverride] = useUserSessionOverride();

  React.useEffect(() => {
    if (!userSessionOverride?.username || isLogInAsOpen) {
      return;
    }
    dispatch(
      signIn(
        userSessionOverride.username,
        userSessionOverride.email || "",
        userSessionOverride.roles || [],
        undefined,
        undefined,
        undefined
      )
    );
  }, [dispatch, isLogInAsOpen, userSessionOverride]);

  const onUserMenuButtonClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setUserMenuAnchor(event.currentTarget);
    },
    [setUserMenuAnchor]
  );

  const closeUserMenu = React.useCallback(() => setUserMenuAnchor(null), [setUserMenuAnchor]);

  const signOut = React.useCallback(() => {
    Auth.signOut();
  }, []);

  const isUserOverrideAllowed = React.useMemo(() => !!roles?.includes(UserGroup.ADMIN), [roles]);

  const onTurnOffLogInAsClick = React.useCallback(() => {
    clearUserSessionOverride();
    window.location.href = "/";
  }, [clearUserSessionOverride]);

  const onAccountSettingsClick = React.useCallback(() => {
    openAccountSettings();
    closeUserMenu();
  }, [closeUserMenu, openAccountSettings]);

  const onImpersonateUserClick = React.useCallback(() => {
    openLogInAs();
    closeUserMenu();
  }, [closeUserMenu, openLogInAs]);

  return (
    <>
      {username && (
        <>
          {isSmallScreen ? (
            <IconButton onClick={onUserMenuButtonClick}>
              <Avatar />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              disableElevation={true}
              endIcon={<KeyboardArrowDownIcon />}
              onClick={onUserMenuButtonClick}
              sx={{ textTransform: "unset" }}
            >
              {`${username} ${userSessionOverride ? "(Impersonation Mode)" : ""}`}
            </Button>
          )}
          <Menu
            anchorEl={userMenuAnchor}
            open={!!userMenuAnchor}
            onClose={closeUserMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            PaperProps={{ sx: { minWidth: 180 } }}
          >
            <MenuItem onClick={onAccountSettingsClick}>{t("appHeader.userMenu.accountSettings")}</MenuItem>
            {!userSessionOverride && isUserOverrideAllowed && (
              <MenuItem onClick={onImpersonateUserClick}>{t("appHeader.userMenu.startImpersonateUser")}</MenuItem>
            )}
            {userSessionOverride && (
              <MenuItem onClick={onTurnOffLogInAsClick}>{t("appHeader.userMenu.endImpersonateUser")}</MenuItem>
            )}
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={signOut}>{t("appHeader.userMenu.signOut")}</MenuItem>
          </Menu>
        </>
      )}

      {isAccountSettingsOpen && <AccountSettingsPanel onClose={closeAccountSettings} />}

      {isLogInAsOpen && (
        <ImpersonateUserDialog
          onClose={closeLogInAs}
          onSuccess={() => {
            closeLogInAs();
            history.push("/");
          }}
        />
      )}
    </>
  );
};

export default UserMenu;
