import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { JobRequestStatus } from "gen/clients/llts";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { SelectOption } from "@mui/base";
import { useTranslation } from "react-i18next";
import { FormFieldObserver } from "components/formikFields/FormFieldObserver/FormFieldObserver";
import { MtRequestStatus, MtRequestUtils } from "pages/MachineTranslationPage/utils/MtRequestUtils";
import Box from "@mui/material/Box";

interface Props {
  initialValues?: JobRequestFilterSearchParams;
  onSearch: (searchParams: JobRequestFilterSearchParams) => void;
}

export interface JobRequestFilterSearchParams {
  statuses?: JobRequestStatus[];
}

enum FieldNames {
  status = "status"
}

interface FormValues {
  [FieldNames.status]: SelectOption<MtRequestStatus>[];
}

const mtRequestStatuses = [
  ...new Set(
    Object.values(JobRequestStatus)
      .map(value => MtRequestUtils.getMtRequestStatus(value))
      .filter(value => value !== undefined && value !== MtRequestStatus.UNKNOWN)
  )
];

const JobRequestFilter: React.FC<Props> = ({ initialValues, onSearch }) => {
  const { t } = useTranslation();
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const optionsRequestStatus: { label: string; value: string }[] = [
    ...mtRequestStatuses
      .map(value => ({
        label: MtRequestUtils.describeMtRequestStatus(value, t),
        value
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  ];

  const onSubmit = React.useCallback(
    (values: FormValues, formikHelpers?: FormikHelpers<FormValues>) => {
      const statuses = values[FieldNames.status].flatMap(v => MtRequestUtils.getJobRequestStatuses(v.value));
      onSearch({ statuses });
      formikHelpers?.setSubmitting(false);
    },
    [onSearch]
  );

  const onFormFieldObserverChange = React.useCallback(() => {
    const formValues = formikRef?.current?.values;
    if (formValues) {
      onSubmit(formValues);
    }
  }, [onSubmit]);

  return (
    <Card sx={{ px: 2 }}>
      <Formik
        initialValues={{
          [FieldNames.status]:
            initialValues?.statuses?.map(el => {
              const value = MtRequestUtils.getMtRequestStatus(el);
              return {
                label: MtRequestUtils.describeMtRequestStatus(value, t),
                value
              };
            }) || []
        }}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <FormFieldObserver fieldName={FieldNames.status} onChange={onFormFieldObserverChange} />
          <Stack direction="row" whiteSpace="nowrap" spacing={2} alignItems="center" my={1}>
            <Typography>{t("machineTranslation.fileTranslation.filterBy")}:</Typography>
            <Box sx={{ width: 350 }}>
              <SelectManyAutocompleteField
                name={FieldNames.status}
                label="Status"
                options={optionsRequestStatus}
                variant="outlined"
                size="small"
              />
            </Box>
          </Stack>
        </Form>
      </Formik>
    </Card>
  );
};

export { JobRequestFilter };
