import { Hub } from "aws-amplify";
import { HubCapsule } from "@aws-amplify/core";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Store } from "redux";
import { amplifyLogger } from "./AmplifyLogger";
import { setInitialPagePath, signIn } from "./store/userSession/userSessionActions";
import { CustomOAuthState } from "./types/CustomOAuthState";
import { LocalStorageUtils } from "./utils/LocalStorageUtils";
import { UserGroup } from "./gen/clients/llts";
import { USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY } from "./utils/constants";

const COUPA_USERS_GROUP_NAME = "CoupaUser";

export class AuthEventListener {
  public static async init(store: Store): Promise<void> {
    const listener = (data: HubCapsule) => {
      switch (data.payload.event) {
        case "signIn":
          {
            amplifyLogger.info("user signed in");
            const user: CognitoUser = data.payload.data;
            const username = user.getUsername();
            const email = this.getEmail(user);
            const userGroups = this.getUserGroups(user);
            const isFederatedSsoAccount = this.isFederatedSsoAccount(user, userGroups);
            const firstName = this.getFirstName(user);
            const lastName = this.getLastName(user);
            store.dispatch(signIn(username, email || "", userGroups, isFederatedSsoAccount, firstName, lastName));
            // Clear session timestamp when a new session starts.
            LocalStorageUtils.storeSessionTimestamp(null);
          }
          break;
        case "signUp":
          amplifyLogger.info("user signed up");
          break;
        case "signOut":
          window.sessionStorage.removeItem(USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY);
          window.location.href = "/";
          break;
        case "oAuthSignOut":
          window.sessionStorage.removeItem(USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY);
          amplifyLogger.info("user signed out");
          break;
        case "signIn_failure":
          amplifyLogger.error("user sign in failed");
          break;
        case "tokenRefresh":
          amplifyLogger.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          amplifyLogger.error("token refresh failed");
          break;
        case "configured":
          amplifyLogger.info("the Auth module is configured");
          break;
        case "customOAuthState": {
          const stateData = data.payload.data;
          if (stateData) {
            const customOAuthState: CustomOAuthState = JSON.parse(stateData);
            if (customOAuthState.initialPagePath) {
              store.dispatch(setInitialPagePath(customOAuthState.initialPagePath));
            }
          }
          break;
        }
        default: {
          // do nothing
        }
      }
    };

    Hub.listen("auth", listener);

    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      if (user) {
        const userGroups = this.getUserGroups(user);
        const email = this.getEmail(user);
        const isFederatedSsoAccount = this.isFederatedSsoAccount(user, userGroups);
        const firstName = this.getFirstName(user);
        const lastName = this.getLastName(user);
        store.dispatch(signIn(user.getUsername(), email || "", userGroups, isFederatedSsoAccount, firstName, lastName));
      }
    } catch (e) {
      amplifyLogger.info("User is not authenticated.", e);
    }
  }

  private static getUserGroups(user: CognitoUser): (UserGroup | string)[] {
    const signInUserSession = user.getSignInUserSession();
    if (signInUserSession) {
      // If the user is authenticated using a 3-rd party identity provider, then assume it is a client employee.
      const decodedIdToken = signInUserSession.getIdToken().decodePayload();
      if (decodedIdToken.identities?.length > 0) {
        return [UserGroup.CLIENT_EMPLOYEE];
      }
      return decodedIdToken["cognito:groups"] || [];
    }
    return [];
  }

  private static getEmail(user: CognitoUser): string | undefined {
    const signInUserSession = user.getSignInUserSession();
    const email = signInUserSession?.getIdToken().decodePayload().email;
    if (!email) {
      amplifyLogger.error("Email is not provided.");
    }
    return email;
  }

  private static isFederatedSsoAccount(user: CognitoUser, userGroups: (UserGroup | string)[] = []): boolean {
    const signInUserSession = user.getSignInUserSession();
    const decodedIdToken = signInUserSession?.getIdToken().decodePayload();
    return decodedIdToken?.identities?.length > 0 || userGroups.includes(COUPA_USERS_GROUP_NAME);
  }

  private static getFirstName(user: CognitoUser): string | undefined {
    const signInUserSession = user.getSignInUserSession();
    const firstName = signInUserSession?.getIdToken().decodePayload().given_name;
    if (!firstName) {
      window.console.log("given_name not found");
    }
    return firstName;
  }

  private static getLastName(user: CognitoUser): string | undefined {
    const signInUserSession = user.getSignInUserSession();
    const lastName = signInUserSession?.getIdToken().decodePayload().family_name;
    if (!lastName) {
      window.console.log("family_name not found");
    }
    return lastName;
  }
}

export default AuthEventListener;
