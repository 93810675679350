import * as React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "react-query";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { LltsLanguage, MachineTranslationService, UtilsService } from "gen/clients/llts";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { FormFieldObserver } from "components/formikFields/FormFieldObserver/FormFieldObserver";
import { CopyToClipboardButton } from "components/CopyToClipboardButton/CopyToClipboardButton";
import { SelectOption } from "@mui/base";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import Typography from "@mui/material/Typography";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "@mui/material";
import { LoadingButton } from "components/LoadingButton/LoadingButton";

enum FieldNames {
  sourceLanguage = "sourceLanguage",
  targetLanguage = "targetLanguage",
  sourceText = "sourceText"
}

interface FormValues {
  [FieldNames.sourceLanguage]?: SelectOption<string>;
  [FieldNames.targetLanguage]?: SelectOption<string>;
  [FieldNames.sourceText]: string;
}

const AUTODETECT_LANGUAGE = "AUTODETECT_LANGUAGE";

const TextTranslationTab: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [sourceTextLength, setSourceTextLength] = React.useState<number>(0);
  const [translatedText, setTranslatedText] = React.useState<string>("0");
  const [isInputChanged, setInputChanged] = React.useState(false);
  const {
    data: mtSettings,
    isLoading: areMtSettingsLoading,
    error: mtSettingsError
  } = useQuery(["getMtSettings"], {
    queryFn: MachineTranslationService.getMachineTranslationSettings
  });
  const {
    data: lltsLanguages,
    isLoading: areLltsLanguagesLoading,
    error: lltsLanguagesError
  } = useQuery(["listIntentoLltsLanguages"], {
    queryFn: () =>
      UtilsService.listLltsLanguages({
        intentoIdExists: true
      })
  });
  const lltsLanguageById: Record<string, LltsLanguage> = React.useMemo(
    () =>
      (lltsLanguages || []).reduce(
        (prevValue, currentValue) => {
          // eslint-disable-next-line no-param-reassign
          prevValue[currentValue.id] = currentValue;
          return prevValue;
        },
        {} as Record<string, LltsLanguage>
      ),
    [lltsLanguages]
  );
  const languageOptions = React.useMemo(
    () =>
      mtSettings?.supportedLltsLanguages
        ?.filter(langId => lltsLanguageById[langId] && !lltsLanguageById[langId].isDisabled)
        .map(langId => ({
          label: lltsLanguageById[langId]?.name || langId,
          value: langId
        }))
        .sort(SELECT_OPTION_COMPARATOR) || [],
    [mtSettings, lltsLanguageById]
  );

  const sourceLanguageOptions: SelectOption<string>[] = React.useMemo(
    () => [{ label: t("machineTranslation.autoDetect"), value: AUTODETECT_LANGUAGE }, ...languageOptions],
    [languageOptions, t]
  );

  const {
    mutateAsync: translate,
    data: translation,
    isLoading: isTranslating,
    error: translateError
  } = useMutation(MachineTranslationService.translateText, { onSuccess: () => setInputChanged(false) });

  React.useEffect(() => {
    setTranslatedText(translation?.translatedText || "");
  }, [translation?.translatedText]);

  const languageDetectedLabel = React.useMemo(() => {
    if (translation?.detectedLanguage && !isInputChanged) {
      return `${t("machineTranslation.languageDetected", { language: translation.detectedLanguage })}`;
    }
    return undefined;
  }, [t, translation?.detectedLanguage, isInputChanged]);

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const sourceLanguage = values[FieldNames.sourceLanguage];
      const targetLanguage = values[FieldNames.targetLanguage];
      const sourceText = values[FieldNames.sourceText];
      if (!targetLanguage || !sourceText.trim() || !isInputChanged) {
        return;
      }
      await translate({
        requestBody: {
          text: sourceText,
          sourceLanguageCode:
            !sourceLanguage || sourceLanguage.value === AUTODETECT_LANGUAGE ? undefined : sourceLanguage.value,
          targetLanguageCode: targetLanguage.value
        }
      });
    },
    [translate, isInputChanged]
  );

  const onSourceTextChange = React.useCallback(
    (text: string) => {
      setSourceTextLength(text.length);
      setInputChanged(true);
    },
    [setSourceTextLength]
  );

  const onSourceLanguageChanged = React.useCallback(() => {
    setInputChanged(true);
  }, [setInputChanged]);

  const sourceTextHelper = React.useMemo(() => {
    if (!mtSettings?.maxCharactersPerRequest) {
      return undefined;
    }
    return `${sourceTextLength.toLocaleString()}/${mtSettings.maxCharactersPerRequest.toLocaleString()}`;
  }, [sourceTextLength, mtSettings?.maxCharactersPerRequest]);

  const isLoading = areMtSettingsLoading || areLltsLanguagesLoading;
  const error = mtSettingsError || lltsLanguagesError;

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  return (
    <Box>
      {translateError && <SnackbarApiError error={translateError} />}

      <Formik
        initialValues={{
          [FieldNames.sourceText]: "",
          [FieldNames.sourceLanguage]: { label: t("machineTranslation.autoDetect"), value: AUTODETECT_LANGUAGE },
          [FieldNames.targetLanguage]: undefined
        }}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form noValidate={true} autoComplete="off">
            <FormFieldObserver fieldName={FieldNames.sourceText} onChange={onSourceTextChange} />
            <FormFieldObserver fieldName={FieldNames.sourceLanguage} onChange={onSourceLanguageChanged} />
            <FormFieldObserver fieldName={FieldNames.targetLanguage} onChange={() => setInputChanged(true)} />
            <Grid container={true} spacing={isSmallScreen ? 2 : 1} mt={2}>
              <Grid item={true} xs={12} md={5}>
                <SelectOneAutocompleteField
                  name={FieldNames.sourceLanguage}
                  label={t("machineTranslation.sourceLanguage")}
                  size="small"
                  options={sourceLanguageOptions}
                  variant="outlined"
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12} md={2} textAlign="center" order={isSmallScreen ? 6 : 2}>
                <Stack>
                  <LoadingButton isLoading={isTranslating}>{t("machineTranslation.translate")}</LoadingButton>
                  <Link
                    component="button"
                    type="button"
                    sx={{ my: 1 }}
                    onClick={() => {
                      setFieldValue(FieldNames.sourceText, "");
                      setTranslatedText("");
                    }}
                  >
                    {t("machineTranslation.clear")}
                  </Link>
                </Stack>
              </Grid>
              <Grid item={true} xs={12} md={5} order={isSmallScreen ? 2 : 3}>
                <SelectOneAutocompleteField
                  name={FieldNames.targetLanguage}
                  label={t("machineTranslation.targetLanguage")}
                  size="small"
                  options={languageOptions}
                  variant="outlined"
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12} md={6} order={isSmallScreen ? 3 : 4}>
                <TextInputField
                  name={FieldNames.sourceText}
                  multiline={true}
                  rows={isSmallScreen ? undefined : 20}
                  variant="outlined"
                  label={t("machineTranslation.sourceTextPrompt")}
                  placeholder={t("machineTranslation.sourceTextPrompt")}
                  maxLength={mtSettings?.maxCharactersPerRequest}
                />
                <Stack direction="row" spacing={2} ml={2} mr={2} order={isSmallScreen ? 4 : 5}>
                  <Typography variant="body2" color="textSecondary" flexGrow={1}>
                    {sourceTextHelper}
                  </Typography>
                  {values[FieldNames.sourceLanguage]?.value === AUTODETECT_LANGUAGE && (
                    <Typography variant="body2" color="textSecondary" align="right" justifyContent="flex-end">
                      {languageDetectedLabel}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              {(!isSmallScreen || translatedText) && (
                <Grid item={true} xs={12} md={6} order={isSmallScreen ? 5 : 6}>
                  <Stack>
                    <TextField
                      multiline={true}
                      fullWidth={true}
                      rows={isSmallScreen ? undefined : 20}
                      label={t("machineTranslation.translation")}
                      value={translatedText}
                      disabled={true}
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: theme.palette.grey["700"]
                        }
                      }}
                    />
                    <Box textAlign="right">
                      <CopyToClipboardButton textToCopy={translatedText} disabled={!translatedText}>
                        {t("machineTranslation.copy")}
                      </CopyToClipboardButton>
                    </Box>
                  </Stack>
                </Grid>
              )}
              <Grid item={true} xs={12} order={7}>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.grey["700"] }}
                  dangerouslySetInnerHTML={{ __html: t("machineTranslation.disclaimer") }}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export { TextTranslationTab };
