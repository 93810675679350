import * as React from "react";
import Stack from "@mui/material/Stack";

interface Props {
  children: React.ReactNode;
}

const DrawerActions: React.FC<Props> = ({ children }) => (
  <Stack direction="row" spacing={1} sx={{ px: 3, py: 3 }}>
    {children}
  </Stack>
);

export { DrawerActions };
