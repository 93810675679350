import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JobRequestAutoQuote } from "gen/clients/llts";
import { CssBaseline, Container, Alert, AlertTitle } from "@mui/material";

interface Props {
  status: JobRequestAutoQuote.acceptanceStatus;
}

const AutoQuoteAcceptanceUpdated: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="md">
        <Alert severity="success" sx={{ mt: 5, width: "100%" }}>
          {status === JobRequestAutoQuote.acceptanceStatus.ACCEPTED && (
            <>
              <AlertTitle>{t("autoQuote.accepted.title")}</AlertTitle>
              <p>{t("autoQuote.accepted.paragraph1")}</p>
              <p>{t("autoQuote.accepted.paragraph2")}</p>
            </>
          )}
          {status === JobRequestAutoQuote.acceptanceStatus.REJECTED && (
            <>
              <AlertTitle>{t("autoQuote.rejected.title")}</AlertTitle>
              <p>{t("autoQuote.rejected.paragraph1")}</p>
            </>
          )}
          <p>
            <RouterLink to="/">{t("autoQuote.returnHome")}</RouterLink>
          </p>
        </Alert>
      </Container>
    </div>
  );
};

export default AutoQuoteAcceptanceUpdated;
