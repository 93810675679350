import * as React from "react";
import { Form, useFormikContext } from "formik";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import { ScrollToFieldError } from "components/ScrollToFieldError/ScrollToFieldError";
import { CustomFieldDefinition, WorkflowOption } from "gen/clients/llts";
import { useTranslation } from "react-i18next";
import { LinearProgressWithLabel } from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import { UserContactSection } from "../UserContactSection/UserContactSection";
import { ProjectInfoSection } from "../ProjectInfoSection/ProjectInfoSection";
import { FilesSection } from "../FilesSection/FilesSection";
import { AlertDialog } from "../../../../components/AlertDialog/AlertDialog";

interface Props {
  clientId: string;
  projectCustomFieldDefinitions?: CustomFieldDefinition[];
  userCustomFieldDefinitions?: CustomFieldDefinition[];
  workflowOptions?: WorkflowOption[];
  hideContactSection?: boolean;
  contactSectionTitle?: React.ReactNode;
  documentInfoTitle?: React.ReactNode;
  projectInfoTitle?: React.ReactNode;
  hideSubmitForEstimation?: boolean;
  hideSubmitForTranslation?: boolean;
  footerMessage?: string;
  submissionProgress?: number;
}

export enum SubmitType {
  PROJECT = "PROJECT",
  ESTIMATE = "ESTIMATE"
}

export enum ContentInputMethod {
  FILES = "FILES",
  TEXT = "TEXT",
  FILELINKS = "FILELINKS"
}

export enum FieldNames {
  submitType = "submitType"
}

const CreateProjectForm: React.FC<Props> = ({
  clientId,
  projectCustomFieldDefinitions,
  userCustomFieldDefinitions,
  workflowOptions,
  hideContactSection,
  contactSectionTitle,
  documentInfoTitle,
  projectInfoTitle,
  hideSubmitForEstimation = false,
  hideSubmitForTranslation = false,
  footerMessage,
  submissionProgress
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue, isSubmitting, submitForm, validateForm } = useFormikContext<Record<string, string>>();

  const onSendForEstimationClick = React.useCallback(async () => {
    const errors = await validateForm(values);
    if (errors && Object.keys(errors).length > 0) {
      submitForm();
    } else {
      setFieldValue(FieldNames.submitType, SubmitType.ESTIMATE);
      submitForm();
    }
  }, [validateForm, values, submitForm, setFieldValue]);

  return (
    <Form noValidate={true} autoComplete="off" autoCorrect="off">
      <ScrollToFieldError />
      {!hideContactSection && (
        <>
          <Typography variant="h4">
            {contactSectionTitle || t("createProject.createProjectForm.contactSectionTitle")}
          </Typography>
          <UserContactSection userCustomFieldDefinitions={userCustomFieldDefinitions} />
        </>
      )}

      {/* Document info section */}
      <Box marginTop={6}>
        <Typography variant="h4">
          {documentInfoTitle || t("createProject.createProjectForm.documentInfoTitle")}
        </Typography>
        <FilesSection />
      </Box>

      {/* Project info section */}
      <Box marginTop={10}>
        <Typography variant="h4">
          {projectInfoTitle || t("createProject.createProjectForm.projectInfoTitle")}
        </Typography>
        <ProjectInfoSection
          clientId={clientId}
          projectCustomFieldDefinitions={projectCustomFieldDefinitions}
          isProjectNameVisible={true}
          workflowOptions={workflowOptions}
        />
      </Box>
      {footerMessage && (
        <Alert severity="warning" sx={{ mt: 5 }}>
          <Box sx={{ mb: 1 }}>
            {footerMessage.split("\n").map(line => (
              <div key={line}>
                {line}
                <br />
              </div>
            ))}
          </Box>
        </Alert>
      )}
      <Box marginY={5}>
        {!hideSubmitForEstimation && (
          <LoadingButton
            type="button"
            variant="contained"
            size="large"
            sx={{ mr: 1 }}
            disabled={isSubmitting}
            loading={isSubmitting && values[FieldNames.submitType] === SubmitType.ESTIMATE}
            onClick={onSendForEstimationClick}
          >
            {t("createProject.createProjectForm.sendForEstimation")}
          </LoadingButton>
        )}
        {!hideSubmitForTranslation && (
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            disabled={isSubmitting}
            loading={isSubmitting && values[FieldNames.submitType] === SubmitType.PROJECT}
            onClick={() => {
              setFieldValue(FieldNames.submitType, SubmitType.PROJECT);
            }}
          >
            {t("createProject.createProjectForm.sendToTranslation")}
          </LoadingButton>
        )}
      </Box>
      {isSubmitting && (
        <AlertDialog title={t("createProject.createProjectForm.submittingAlert.title")}>
          <Stack spacing={1}>
            <Box>{t("createProject.createProjectForm.submittingAlert.text")}</Box>
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={submissionProgress || 0} />
            </Box>
          </Stack>
        </AlertDialog>
      )}
    </Form>
  );
};

export { CreateProjectForm };
