import * as React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledMenu } from "./StyledMenu";
import { SelectOption } from "@mui/base";
import { ButtonProps } from "@mui/material";

interface LocalProps {
  label: React.ReactNode;
  options: SelectOption<string>[];
  onSelect: (value: string, index: number) => void;
}

type Props = Omit<ButtonProps, "onClick" | "onSelect"> & LocalProps;

const ButtonWithOptions: React.FC<Props> = ({ label, options, onSelect, ...buttonProps }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOptionClick = (value: string, index: number) => {
    onSelect(value, index);
    handleClose();
  };

  return (
    <div>
      <Button
        {...buttonProps}
        id="button-with-options"
        aria-controls={open ? "button-with-options-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {label}
      </Button>
      <StyledMenu
        id="button-with-options-menu"
        MenuListProps={{
          "aria-labelledby": "button-with-options"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={option.value} disableRipple={true} onClick={() => onOptionClick(option.value, index)}>
            {option.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export { ButtonWithOptions };
