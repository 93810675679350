import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { JobRequest, JobRequestFileCategory } from "gen/clients/llts";
import { JobRequestFileList } from "components/jobRequests/JobRequestFileList/JobRequestFileList";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import { SelectInput } from "components/SelectInput/SelectInput";
import { SelectOption } from "@mui/base";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { DownloadTranslatedFilesDialog } from "components/jobRequests/DownloadTranslatedFilesDialog/DownloadTranslatedFilesDialog";
import { Alert } from "@mui/material";
import { LabelValue } from "components/LabelValue/LabelValue";

interface Props {
  jobRequest: JobRequest;
}

const DeliverablesTab: React.FC<Props> = ({ jobRequest }) => {
  const [selectedTargetLanguageId, setSelectedTargetLanguageId] = React.useState<string>();
  const [isDownloadTranslatedFilesDialogOpen, openDownloadTranslatedFilesDialog, closeDownloadTranslatedFilesDialog] =
    useDialogState();

  const translatedFiles = React.useMemo(
    () =>
      jobRequest.files
        ?.filter(f => f.category === JobRequestFileCategory.TRANSLATED_DOCUMENT)
        ?.sort((f1, f2) =>
          f1.fileName
            .substring(0, f1.fileName.lastIndexOf("."))
            .localeCompare(f2.fileName.substring(0, f2.fileName.lastIndexOf(".")))
        ),
    [jobRequest]
  );

  const selectedLanguageFiles = React.useMemo(
    () => translatedFiles?.filter(f => `${f.languageId}` === selectedTargetLanguageId) || [],
    [translatedFiles, selectedTargetLanguageId]
  );

  const targetLanguageOptions: SelectOption<string>[] = React.useMemo(
    () =>
      jobRequest?.targetLanguages
        ?.map(l => ({
          label: l.name,
          value: l.id.toString()
        }))
        .sort(SELECT_OPTION_COMPARATOR) || [],
    [jobRequest?.targetLanguages]
  );

  React.useEffect(() => {
    if (!selectedTargetLanguageId) {
      setSelectedTargetLanguageId(targetLanguageOptions[0]?.value);
    }
  }, [selectedTargetLanguageId, targetLanguageOptions]);

  const onDownloadAllClick = React.useCallback(async () => {
    openDownloadTranslatedFilesDialog();
  }, [openDownloadTranslatedFilesDialog]);

  if (!translatedFiles || translatedFiles.length === 0) {
    return <Alert severity="warning">Deliverable files are not available yet. Please try again later.</Alert>;
  }

  return (
    <>
      <Stack spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <LabelValue
            label="Language"
            value={
              <SelectInput
                options={targetLanguageOptions}
                onChange={setSelectedTargetLanguageId}
                value={selectedTargetLanguageId}
                size="small"
                SelectProps={{
                  native: true
                }}
              />
            }
          />
          <Button startIcon={<DownloadIcon />} variant="outlined" onClick={onDownloadAllClick} size="small">
            Download All
          </Button>
        </Stack>
        <Stack spacing={0.5}>
          {selectedLanguageFiles.length === 0 && (
            <Typography variant="body2">There are no files available for the selected language</Typography>
          )}
          {selectedLanguageFiles.length > 0 && (
            <JobRequestFileList jobRequestId={jobRequest.id} files={selectedLanguageFiles} />
          )}
        </Stack>
      </Stack>
      {isDownloadTranslatedFilesDialogOpen && (
        <DownloadTranslatedFilesDialog
          jobRequestId={jobRequest.id}
          zipFileName={`coupa-${jobRequest.id}.zip`}
          onClose={closeDownloadTranslatedFilesDialog}
          onSuccess={closeDownloadTranslatedFilesDialog}
        />
      )}
    </>
  );
};

export { DeliverablesTab };
