import * as React from "react";
import { useUserSession } from "hooks/useUserSession";
import { LuckyOrangeUtils } from "utils/LuckyOrangeUtils";

const LuckyOrangeUserIdentifier: React.FC = () => {
  const { username, email, isFederatedSsoAccount, firstName, lastName } = useUserSession();

  React.useEffect(() => {
    if (username) {
      LuckyOrangeUtils.identifyUser({
        username,
        email: email || "",
        firstName,
        lastName
      });
    }
  }, [username, email, isFederatedSsoAccount, firstName, lastName]);

  return <></>;
};

export { LuckyOrangeUserIdentifier };
