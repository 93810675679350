/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection JSUnusedLocalSymbols

import * as React from "react";
import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { ApplicationState } from "store/store";
import { useSelector } from "react-redux";
import { IPage } from "../../interfaces/page";
import { FohQuoteType, StatisticsService, StatProjectType } from "../../gen/clients/llts";
import logging from "../../config/logging";
import { DashboardTableType, DashboardType } from "./DashBoardComponent";
import { DashboardAccordion } from "./components/DashboardAccordion/DashboardAccordion";
import HomeCrumb from "../../components/Breadcrumb/HomeCrumb";
import { showDashBoards } from "./resources";

interface IAccordionObj {
  id: string;
  show: boolean;
}

const DashboardPage: React.FC<IPage> = props => {
  const [accordionState, setAccordionState] = useState<IAccordionObj[]>(
    JSON.parse(sessionStorage.getItem("accordionData") ?? "[]")
  );
  const [showCollapseButton, setShowCollapseButton] = useState<boolean>(false);

  const { id } = props;
  const { t } = useTranslation();
  const name = t(`${id}.title`);

  const { username, roles } = useSelector((state: ApplicationState) => ({
    roles: state.userSession.roles || [],
    username: state.userSession.username
  }));

  useEffect(() => {
    logging.info(`Loading ${name}`);
  }, [name]);

  useEffect(() => {
    sessionStorage.setItem("accordionData", JSON.stringify(accordionState));
  }, [accordionState]);

  const showCollapseAllButton = React.useCallback(() => {
    const openAccordions = accordionState.filter(accordion => accordion.show);

    setShowCollapseButton(openAccordions.length > 0);
  }, [accordionState]);

  useEffect(() => {
    showCollapseAllButton();
  }, [accordionState, showCollapseAllButton]);

  const expansionState = (panelId: string): boolean => {
    if (accordionState.length) {
      const index = accordionState.findIndex(x => x.id === panelId);
      if (index > -1) {
        return accordionState[index].show;
      }
    }
    return false;
  };

  const accordionChange = (expanded: boolean, panelId: string) => {
    // nothing here... add it.
    if (!accordionState.length) {
      setAccordionState(current => [...current, { id: panelId, show: expanded }]);
    } else {
      const index = accordionState.findIndex(x => x.id === panelId);
      if (index === -1) {
        // add it...
        setAccordionState(current => [...current, { id: panelId, show: expanded }]);
      } else {
        // toggle state.
        setAccordionState(prevAccordions =>
          prevAccordions.map(accordion =>
            accordion.id === panelId ? { ...accordion, show: !accordion.show } : accordion
          )
        );
      }
    }
  };

  const handleClick = () => {
    const data = JSON.parse(sessionStorage.getItem("accordionData") ?? "[]");

    setAccordionState(data.map((accordion: any) => ({ ...accordion, show: false })));
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    accordionChange(isExpanded, panel);
  };

  const {
    data: comprehensionStats,
    isLoading,
    isError
  } = useQuery("compProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.COMPREHENSION
      })
  });

  const { data: healthStats } = useQuery("healthProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.HEALTHCARE
      })
  });
  const { data: insuranceStats } = useQuery("insuranceProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.INSURANCE
      })
  });
  const { data: techStats } = useQuery("techProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TECHNICAL
      })
  });
  const { data: govStats } = useQuery("govProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.GOVERNMENT
      })
  });
  const { data: taiwanStats } = useQuery("taiwanProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TAIWAN
      })
  });
  const { data: compPastDueStats } = useQuery("compPastDueProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.COMPREHENSIONPASTDUE
      })
  });
  const { data: healthPastDueStats } = useQuery("healthPastDueProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.HEALTHCAREPASTDUE
      })
  });
  const { data: insurancePastDueStats } = useQuery("insurancePastDueProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.INSURANCEPASTDUE
      })
  });
  const { data: technicalPastDueStats } = useQuery("technicalPastDueProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TECHNICALPASTDUE
      })
  });
  const { data: govPastDueStats } = useQuery("governmentPastDueProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.GOVERNMENTPASTDUE
      })
  });
  const { data: taiwanPastDueStats } = useQuery("taiwanPastDueProjectStats", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TAIWANPASTDUE
      })
  });
  const { data: compDueTodayStats } = useQuery("compProjectsDueToday", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.COMPREHENSIONDUETODAY
      })
  });
  const { data: healthDueTodayStats } = useQuery("healthProjectsDueToday", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.HEALTHCAREDUETODAY
      })
  });
  const { data: insuranceDueTodayStats } = useQuery("insuranceProjectsDueToday", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.INSURANCEDUETODAY
      })
  });
  const { data: technicalDueTodayStats } = useQuery("technicalProjectsDueToday", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TECHNICALDUETODAY
      })
  });
  const { data: govDueTodayStats } = useQuery("governmentProjectsDueToday", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.GOVERNMENTDUETODAY
      })
  });
  const { data: taiwanDueTodayStats } = useQuery("taiwanProjectsDueToday", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TAIWANDUETODAY
      })
  });
  const { data: tatComp } = useQuery("tatComp", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TATCOMP
      })
  });
  const { data: tatHealth } = useQuery("tatHealth", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TATHEALTH
      })
  });
  const { data: tatInsurance } = useQuery("tatInsurance", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TATINSURANCE
      })
  });
  const { data: tatTech } = useQuery("tatTech", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TATTECH
      })
  });
  const { data: tatGov } = useQuery("tatGov", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TATGOVERMENT
      })
  });
  const { data: tatTaiwan } = useQuery("tatTaiwan", {
    queryFn: () =>
      StatisticsService.getStatisticsByType({
        projectType: StatProjectType.TATTAIWAN
      })
  });
  const { data: qaStats } = useQuery("qaStats", {
    queryFn: () => StatisticsService.getQaStats()
  });
  const { data: fohCurrentStats } = useQuery("fohCurrentStats", {
    queryFn: () => StatisticsService.getFohCurrentStatistics()
  });
  const { data: fohCompletedStats } = useQuery("fohCompletedStats", {
    queryFn: () => StatisticsService.getFohCompletedStatistics()
  });
  const { data: fohPendingReviewStats } = useQuery("fohPendingReviewStats", {
    queryFn: () => StatisticsService.getFohPendingStatistics()
  });
  const { data: fohTechnicalPastDueStats } = useQuery("fohTechnicalPastDueStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHTECHNICALPASTDUE
      })
  });
  const { data: fohTechnicalDueTodayStats } = useQuery("fohTechnicalDueTodayStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHTECHNICALDUETODAY
      })
  });
  const { data: fohComprehensionPastDueStats } = useQuery("fohComprehensionPastDueStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHCOMPREHENSIONPASTDUE
      })
  });
  const { data: fohComprehensionDueTodayStats } = useQuery("fohComprehensionDueTodayStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHCOMPREHENSIONDUETODAY
      })
  });
  const { data: fohHealthPastDueStats } = useQuery("fohHealthPastDueStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHHEALTHCAREPASTDUE
      })
  });
  const { data: fohHealthDueTodayStats } = useQuery("fohHealthDueTodayStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHHEALTHCAREDUETODAY
      })
  });
  const { data: fohGovernmentPastDueStats } = useQuery("fohGovernmentPastDueStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHGOVERNMENTPASTDUE
      })
  });
  const { data: fohGovernmentDueTodayStats } = useQuery("fohGovernmentDueTodayStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHGOVERNMENTDUETODAY
      })
  });
  const { data: fohTaiwanPastDueStats } = useQuery("fohTaiwanPastDueStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHTAIWANPASTDUE
      })
  });
  const { data: fohTaiwanDueTodayStats } = useQuery("fohTaiwanDueTodayStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.FOHTAIWANDUETODAY
      })
  });
  const { data: fohUnassignedPastDueStats } = useQuery("fohUnassignedPastDueStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.UNASSIGNEDPASTDUE
      })
  });
  const { data: fohUnassignedDueTodayStats } = useQuery("fohUnassignedDueTodayStats", {
    queryFn: () =>
      StatisticsService.getFohPcStatisticsByType({
        quoteType: FohQuoteType.UNASSIGNEDDUETODAY
      })
  });

  const mainDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.verticals.compAndCompliance"),
      link: "pmdetails",
      data: comprehensionStats,
      isLoading,
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.verticals.healthcare"),
      link: "pmdetails",
      data: healthStats,
      isLoading,
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.verticals.insurance"),
      link: "pmdetails",
      data: insuranceStats,
      isLoading,
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.verticals.technical"),
      link: "pmdetails",
      data: techStats,
      isLoading,
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.verticals.government"),
      data: govStats,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.verticals.taiwan"),
      data: taiwanStats,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.STAT
    }
  ];

  const compDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.tat"),
      data: tatComp,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.TAT
    },
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: compPastDueStats,
      isLoading,
      link: "pastdue",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: compDueTodayStats,
      isLoading,
      link: "duetoday",
      tableType: DashboardTableType.STAT
    }
  ];
  const healthDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.tat"),
      data: tatHealth,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.TAT
    },
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: healthPastDueStats,
      isLoading,
      link: "pastdue",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: healthDueTodayStats,
      isLoading,
      link: "duetoday",
      tableType: DashboardTableType.STAT
    }
  ];
  const insuranceDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.tat"),
      data: tatInsurance,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.TAT
    },
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: insurancePastDueStats,
      isLoading,
      link: "pastdue",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: insuranceDueTodayStats,
      isLoading,
      link: "duetoday",
      tableType: DashboardTableType.STAT
    }
  ];

  const technicalDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.tat"),
      data: tatTech,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.TAT
    },
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: technicalPastDueStats,
      isLoading,
      link: "pastdue",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: technicalDueTodayStats,
      isLoading,
      link: "duetoday",
      tableType: DashboardTableType.STAT
    }
  ];

  const governmentDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.tat"),
      data: tatGov,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.TAT
    },
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: govPastDueStats,
      isLoading,
      link: "pastdue",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: govDueTodayStats,
      isLoading,
      link: "duetoday",
      tableType: DashboardTableType.STAT
    }
  ];

  const taiwanDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.tat"),
      data: tatTaiwan,
      isLoading,
      link: "pmdetails",
      tableType: DashboardTableType.TAT
    },
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: taiwanPastDueStats,
      isLoading,
      link: "pastdue",
      tableType: DashboardTableType.STAT
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: taiwanDueTodayStats,
      isLoading,
      link: "duetoday",
      tableType: DashboardTableType.STAT
    }
  ];

  const qaDashBoardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.dashboards.qa"),
      data: qaStats,
      isLoading,
      link: "qadetails",
      tableType: DashboardTableType.QA
    }
  ];

  const fohDashBoardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.fohCurrent"),
      data: fohCurrentStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.fohCompleted"),
      data: fohCompletedStats,
      isLoading,
      link: "completedquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.fohPendingReview"),
      data: fohPendingReviewStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOHPENDING
    }
  ];

  const fohTechnicalDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: fohTechnicalPastDueStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: fohTechnicalDueTodayStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    }
  ];
  const fohComprehensionDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: fohComprehensionPastDueStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: fohComprehensionDueTodayStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    }
  ];
  const fohHealthDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: fohHealthPastDueStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: fohHealthDueTodayStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    }
  ];
  const fohGovernmentDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: fohGovernmentPastDueStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: fohGovernmentDueTodayStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    }
  ];
  const fohTaiwanDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: fohTaiwanPastDueStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: fohTaiwanDueTodayStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    }
  ];
  const fohUnassignedDashboardComps: DashboardType[] = [
    {
      title: t("DASHBOARD.topics.pastdue"),
      data: fohUnassignedPastDueStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    },
    {
      title: t("DASHBOARD.topics.duetoday"),
      data: fohUnassignedDueTodayStats,
      isLoading,
      link: "currentquotes",
      tableType: DashboardTableType.FOH
    }
  ];

  const history = useHistory();
  const isLoggedIn = username !== undefined;
  if (!showDashBoards(roles, isLoggedIn, username)) {
    history.push("/");
  }

  if (isError) {
    return <h2>{t("common.systemError")}</h2>;
  }

  return (
    <>
      <div>
        <div>
          <HomeCrumb />
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <h4>{name}</h4>
      </div>
      <div style={{ padding: 60 }}>
        <Button onClick={handleClick} hidden={!showCollapseButton}>
          {t("DASHBOARD.collapseAllButton")}
        </Button>
        <DashboardAccordion
          id="starts"
          expansionState={expansionState}
          onChangeListener={accordionChange}
          title={t("DASHBOARD.dashboards.starts")}
          dashboardComps={mainDashboardComps}
        />
        <Accordion
          onChange={handleChange("main-1")}
          expanded={expansionState("main-1")}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("DASHBOARD.dashboards.pm-sv")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DashboardAccordion
              id="comp-compliance"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.compAndCompliance")}
              dashboardComps={compDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="healthcare"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.healthcare")}
              dashboardComps={healthDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="insurance"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.insurance")}
              dashboardComps={insuranceDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="technical2"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.technical")}
              dashboardComps={technicalDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="government"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.government")}
              dashboardComps={governmentDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="taiwan"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.taiwan")}
              dashboardComps={taiwanDashboardComps}
            />
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: 20 }}>
          <DashboardAccordion
            id="qa"
            expansionState={expansionState}
            onChangeListener={accordionChange}
            title={t("DASHBOARD.dashboards.qa")}
            dashboardComps={qaDashBoardComps}
          />
          <DashboardAccordion
            id="foh"
            expansionState={expansionState}
            onChangeListener={accordionChange}
            title={t("DASHBOARD.dashboards.foh")}
            dashboardComps={fohDashBoardComps}
          />
        </div>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          onChange={handleChange("foh-pc")}
          expanded={expansionState("foh-pc")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("DASHBOARD.dashboards.foh-pc")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DashboardAccordion
              id="compAndCompliance"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.compAndCompliance")}
              dashboardComps={fohComprehensionDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="healthcare2"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.healthcare")}
              dashboardComps={fohHealthDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="technical"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.technical")}
              dashboardComps={fohTechnicalDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="government2"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.government")}
              dashboardComps={fohGovernmentDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="taiwan2"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.taiwan")}
              dashboardComps={fohTaiwanDashboardComps}
            />
          </AccordionDetails>
          <AccordionDetails>
            <DashboardAccordion
              id="unassigned"
              expansionState={expansionState}
              onChangeListener={accordionChange}
              title={t("DASHBOARD.verticals.unassigned")}
              dashboardComps={fohUnassignedDashboardComps}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default DashboardPage;
