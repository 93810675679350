import * as React from "react";
import { useParams } from "react-router-dom";
import { MtJobRequestDetailsPage } from "./MtJobRequestDetailsPage/MtJobRequestDetailsPage";
import { FileTranslationMainPage } from "./FileTranslationMainPage/FileTranslationMainPage";

const FileTranslationTab: React.FC = () => {
  const { jobRequestId } = useParams<{ jobRequestId?: string }>();

  if (jobRequestId) {
    return <MtJobRequestDetailsPage jobRequestId={jobRequestId} />;
  }

  return <FileTranslationMainPage />;
};

export { FileTranslationTab };
