import * as React from "react";
import { CustomFieldDefinition } from "gen/clients/llts";
import { TextInputField } from "../TextInputField/TextInputField";
import { SelectOneField } from "../SelectOneField/SelectOneField";
import { AutocompleteField } from "../AutocompleteField/AutocompleteField";
import { DatePickerField } from "../DatePickerField/DatePickerField";
import { sanitizeFieldName } from "../formikUtils";
import { PipeSeparatedSelectManyField } from "../PipeSeparatedSelectManyField/PipeSeparatedSelectManyField";

interface Props {
  customFieldDefinition: CustomFieldDefinition;
  variant?: "outlined" | "filled" | "standard";
}

const CustomField: React.FC<Props> = ({ customFieldDefinition, variant }) => {
  const fieldName = sanitizeFieldName(customFieldDefinition.name);

  switch (customFieldDefinition.type) {
    case CustomFieldDefinition.type.INPUT_TEXT:
      return (
        <TextInputField
          name={fieldName}
          label={customFieldDefinition.name}
          required={customFieldDefinition.required}
          validationRegex={customFieldDefinition.validationRegex}
          validationMessage={customFieldDefinition.validationMessage}
          helperText={customFieldDefinition.description}
          variant={variant}
          disabled={customFieldDefinition.disabled}
        />
      );
    case CustomFieldDefinition.type.LISTONLY:
      return (
        <SelectOneField
          name={fieldName}
          label={customFieldDefinition.name}
          options={customFieldDefinition.options || []}
          required={customFieldDefinition.required}
          helperText={customFieldDefinition.description}
          variant={variant}
          disabled={customFieldDefinition.disabled}
        />
      );
    case CustomFieldDefinition.type.LIST:
      return (
        <AutocompleteField
          name={fieldName}
          label={customFieldDefinition.name}
          options={customFieldDefinition.options || []}
          required={customFieldDefinition.required}
          helperText={customFieldDefinition.description}
          variant={variant}
          disabled={customFieldDefinition.disabled}
        />
      );
    case CustomFieldDefinition.type.SELECT_MANY_LIST:
      return (
        <PipeSeparatedSelectManyField
          name={fieldName}
          label={customFieldDefinition.name}
          options={customFieldDefinition.options || []}
          required={customFieldDefinition.required}
          helperText={customFieldDefinition.description}
          disabled={customFieldDefinition.disabled}
        />
      );
    case CustomFieldDefinition.type.DATE:
      return (
        <DatePickerField
          name={fieldName}
          label={customFieldDefinition.name}
          required={customFieldDefinition.required}
          helperText={customFieldDefinition.description}
          variant={variant}
          validationRegex={customFieldDefinition.validationRegex}
          validationMessage={customFieldDefinition.validationMessage}
          disabled={customFieldDefinition.disabled}
        />
      );
    default:
      return <div>Unknown custom field type: {customFieldDefinition.type}</div>;
  }
};

export { CustomField };
