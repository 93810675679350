/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddFilesRequest } from '../models/AddFilesRequest';
import type { CreateQuoteRequest } from '../models/CreateQuoteRequest';
import type { CreateQuoteResponse } from '../models/CreateQuoteResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class QuotesService {

    /**
     * Creates a quote
     * @returns CreateQuoteResponse Returns an object with quote ID
     * @throws ApiError
     */
    public static createQuote({
        requestBody,
    }: {
        /** Create quote request parameters **/
        requestBody?: CreateQuoteRequest,
    }): CancelablePromise<CreateQuoteResponse> {
        return __request({
            method: 'POST',
            path: `/quotes`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Registers files that were previously uploaded to S3 in XTRF
     * @returns any Successfully accepted request.
     * @throws ApiError
     */
    public static addQuoteFiles({
        quoteId,
        requestBody,
    }: {
        /** Quote ID. **/
        quoteId: string,
        /** File names **/
        requestBody?: AddFilesRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/quotes/${quoteId}/actions/addFiles`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}