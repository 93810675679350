/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportMetadataSummary = {
    id: string;
    name: string;
    description?: string;
    type: ReportMetadataSummary.type;
}

export namespace ReportMetadataSummary {

    export enum type {
        PROJECT = 'PROJECT',
        QUOTE = 'QUOTE',
        TASK = 'TASK',
        CLIENT_CONTACT = 'CLIENT_CONTACT',
    }


}
