import { useSessionStorage } from "usehooks-ts";
import React, { Dispatch, SetStateAction } from "react";
import { USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY } from "../utils/constants";
import { UserGroup } from "../gen/clients/llts";

export interface UserSessionOverride {
  username: string;
  email?: string;
  roles?: UserGroup[];
}

type UseUserSessionOverrideType = [
  UserSessionOverride | undefined,
  Dispatch<SetStateAction<UserSessionOverride | undefined>>,
  () => void
];

/**
 * Retrieves the user session override value from session storage and provides functions to manipulate it.
 */
export const useUserSessionOverride = (): UseUserSessionOverrideType => {
  const [userSessionOverride, setUserSessionOverride] = useSessionStorage<UserSessionOverride | undefined>(
    USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY,
    undefined
  );

  const clearUserSessionOverride = React.useCallback(() => {
    setUserSessionOverride(undefined);
    window.sessionStorage.removeItem(USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY);
  }, [setUserSessionOverride]);
  return [userSessionOverride, setUserSessionOverride, clearUserSessionOverride];
};
