/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MtLanguage = {
    name: string;
    code: string;
    direction?: MtLanguage.direction;
}

export namespace MtLanguage {

    export enum direction {
        LTR = 'ltr',
        RTL = 'rtl',
    }


}
