import * as React from "react";
import { Formik, Form } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions } from "@mui/material";
import Grid from "@mui/material/Grid";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import { validateURLFormat } from "utils/stringUtils";
import { FileCategory, FileLink } from "../../../../gen/clients/llts";

interface Props {
  uniqueLinks?: FileLink[];
  onClose: () => void;
  onAdd: (fileLink: FileLink) => void;
}

interface FormValues {
  url: string;
  name: string;
}

const AddFileLinkDialog: React.FC<Props> = ({ uniqueLinks, onAdd, onClose }) => {
  const { t } = useTranslation();

  const onSubmit = React.useCallback(
    values => {
      onAdd({ url: values.url, name: values.name, category: FileCategory.SOURCE_DOCUMENT });
    },
    [onAdd]
  );

  const validate = React.useCallback(
    (formValues: FormValues) => {
      const errors: Record<string, string> = {};
      if (uniqueLinks?.map(link => link.url).includes(formValues.url.trim())) {
        errors.url = t("createProject.createProjectForm.addFileLinkDialog.validationMessages.duplicateUrl");
      } else if (!validateURLFormat(formValues.url.trim())) {
        errors.url = t("createProject.createProjectForm.addFileLinkDialog.validationMessages.urlFormat");
      }
      if (uniqueLinks?.map(link => link.name).includes(formValues.name.trim())) {
        errors.name = t("createProject.createProjectForm.addFileLinkDialog.validationMessages.duplicateName");
      }
      return errors;
    },
    [t, uniqueLinks]
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{t("createProject.createProjectForm.addFileLinkDialog.title")}</DialogTitle>
      <Formik
        initialValues={{
          url: "",
          name: ""
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form noValidate={true}>
          <DialogContent>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <TextInputField
                  name="url"
                  label={t("createProject.createProjectForm.addFileLinkDialog.fileLinkUrl")}
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextInputField
                  name="name"
                  label={t("createProject.createProjectForm.addFileLinkDialog.fileLinkName")}
                  required={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit" variant="contained">
              {t("createProject.createProjectForm.addFileLinkDialog.addButton")}
            </Button>
            <Button color="secondary" onClick={onClose}>
              {t("common.cancel")}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { AddFileLinkDialog };
