import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Auth } from "@aws-amplify/auth";
import { useSessionStorage } from "usehooks-ts";
import { TradeCentricService } from "../../gen/clients/llts";
import { LoadingPage } from "../../components/LoadingPage/LoadingPage";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import { COUPA_REQUEST_ID_SESSION_STORAGE_KEY } from "../../utils/constants";

interface PathParams {
  requestId: string;
}

const CoupaStarterPage: React.FC = () => {
  const { requestId } = useParams<PathParams>();
  const history = useHistory();
  const [, storeRequestId] = useSessionStorage(COUPA_REQUEST_ID_SESSION_STORAGE_KEY, "");
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const { data, error } = useQuery(["initializeLogIn", requestId], {
    queryFn: () => TradeCentricService.initializeLogIn({ requestBody: { requestId } })
  });

  React.useEffect(() => {
    storeRequestId(requestId || "");
  }, [requestId, storeRequestId]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    window.setTimeout(async () => {
      try {
        await Auth.signIn({ username: data.username, password: data.token });
        history.push("/coupa");
      } catch (e) {
        setErrorMessage("Unable to sign in.  Please try again later.");
      }
    }, 0);
  }, [data, history]);

  if (error || errorMessage) {
    return <ErrorPage apiError={error || errorMessage} />;
  }

  return <LoadingPage />;
};

export { CoupaStarterPage };
