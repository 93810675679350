import { JobRequestStatus } from "../gen/clients/llts";

export class JobRequestUtils {
  public static isInProgress(status?: JobRequestStatus): boolean {
    switch (status) {
      case JobRequestStatus.CREATED:
      case JobRequestStatus.ANALYZING:
      case JobRequestStatus.CANCELLING:
      case JobRequestStatus.SCREENING:
      case JobRequestStatus.SUBMITTING_TO_XTRF:
      case JobRequestStatus.TRANSLATING:
        return true;
      default:
        return false;
    }
  }
}
