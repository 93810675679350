import * as React from "react";
import Stack from "@mui/material/Stack";
import { useUserSession } from "hooks/useUserSession";
import { LabelValue } from "components/LabelValue/LabelValue";
import Button from "@mui/material/Button";
import { ChangePasswordDialog } from "../../ChangePasswordDialog";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import { useTranslation } from "react-i18next";

const ProfileTab: React.FC = () => {
  const { t } = useTranslation();
  const { username, email, firstName, lastName, isFederatedSsoAccount } = useUserSession();
  const [isChangePasswordOpen, openChangePassword, closeChangePassword] = useDialogState();
  const [isSuccessPasswordChangeOpen, openSuccessPasswordChange, closeSuccessPasswordChange] = useDialogState();

  const onChangePasswordSuccess = React.useCallback(() => {
    closeChangePassword();
    openSuccessPasswordChange();
  }, [closeChangePassword, openSuccessPasswordChange]);

  const onChangePasswordClick = React.useCallback(() => {
    openChangePassword();
    closeSuccessPasswordChange();
  }, [openChangePassword, closeSuccessPasswordChange]);
  return (
    <>
      <Stack spacing={1} mt={4}>
        <LabelValue label={t("accountSettings.profile.username")} value={username} />
        <LabelValue
          label={t("accountSettings.profile.password")}
          value={
            <Stack direction="row" spacing={1} alignItems="center">
              <div>*********</div>
              {!isFederatedSsoAccount && (
                <Button onClick={onChangePasswordClick}>{t("accountSettings.profile.changePassword")}</Button>
              )}
            </Stack>
          }
        />
        {email && <LabelValue label={t("accountSettings.profile.email")} value={email} />}
        {firstName && <LabelValue label={t("accountSettings.profile.firstName")} value={firstName} />}
        {lastName && <LabelValue label={t("accountSettings.profile.lastName")} value={lastName} />}
      </Stack>
      {isChangePasswordOpen && (
        <ChangePasswordDialog onClose={closeChangePassword} onSuccess={onChangePasswordSuccess} />
      )}
      {isSuccessPasswordChangeOpen && (
        <SnackbarAlert message={t("appHeader.userMenu.changePassword.title")} severity="success" />
      )}
    </>
  );
};

export { ProfileTab };
