import * as React from "react";

/**
 * Hook simplifying state management of dialogs and panels.
 * @param initialState
 */
export const useDialogState = (initialState = false): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = React.useState(initialState);

  const open = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, open, close];
};
