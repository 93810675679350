import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { LoadingButton } from "../LoadingButton/LoadingButton";
import Box from "@mui/material/Box";

interface Props {
  title?: React.ReactNode;
  content: React.ReactNode;
  onClose: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  error?: React.ReactNode;
}

const ConfirmationDialog: React.FC<Props> = ({ title, content, onConfirm, onClose, isLoading = false, error }) => {
  const onConfirmClick = React.useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content}
        {error && <Box sx={{ mt: 2 }}>{error}</Box>}
      </DialogContent>
      <DialogActions>
        <LoadingButton variant="contained" onClick={onConfirmClick} isLoading={isLoading}>
          OK
        </LoadingButton>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmationDialog };
