import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Stack from "@mui/material/Stack";
import { JobRequest, JobRequestsService } from "gen/clients/llts";
import { useTranslation } from "react-i18next";

interface Props {
  jobRequest: JobRequest;
  onSuccess: () => void;
  onClose: () => void;
}

const ArchiveJobRequestDialog: React.FC<Props> = ({ jobRequest, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const { mutate, isLoading, error, isSuccess } = useMutation("archiveJobRequest", {
    mutationFn: JobRequestsService.archiveJobRequest
  });

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const onSubmit = React.useCallback(() => {
    mutate({
      jobRequestId: jobRequest.id
    });
  }, [jobRequest.id, mutate]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <Formik initialValues={{}} onSubmit={onSubmit} validateOnMount={false}>
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <DialogTitle>{t("archiveJobRequestDialog.title")}</DialogTitle>
          <DialogContent>
            {error && <ApiErrorMessage apiError={error} />}
            <Stack spacing={2}>
              <Box>{t("archiveJobRequestDialog.message")}</Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant="contained" isLoading={isLoading}>
              {t("archiveJobRequestDialog.archiveBtn")}
            </LoadingButton>
            <Button onClick={onClose}>{t("common.cancel")}</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { ArchiveJobRequestDialog };
