import * as React from "react";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";

interface Props {
  logoUrl?: string;
}

const AccountCreated: React.FC<Props> = ({ logoUrl }) => {
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {logoUrl && <img src={logoUrl} alt={t("signUp.logo.alt")} />}
        {!logoUrl && (
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        )}
        <Typography component="h1" variant="h5">
          {t("signUp.signUp")}
        </Typography>
        <Typography
          variant="body1"
          sx={{ my: 5, textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: t("signUp.emailVerificationSent") }}
        />
      </Box>
    </Container>
  );
};

export { AccountCreated };
