import * as React from "react";
import { useUserSession } from "hooks/useUserSession";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { ChangePasswordDialog } from "components/AppHeader/components/UserMenu/ChangePasswordDialog";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { AlertDialog } from "components/AlertDialog/AlertDialog";

const ChangePasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { username, isFederatedSsoAccount } = useUserSession();
  const history = useHistory();
  const [isChangePasswordDialogOpen, , closeChangePasswordDialog] = useDialogState(true);
  const [isSuccessDialogOpen, openSuccessDialog, closeSuccessDialog] = useDialogState();

  React.useEffect(() => {
    async function signIn() {
      // Check if the user is signed-in, if not then perform federatedSignIn.
      try {
        await Auth.currentAuthenticatedUser();
      } catch (e) {
        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
      }
    }

    signIn();
  }, []);

  const onClose = React.useCallback(() => {
    closeChangePasswordDialog();
    history.push("/");
  }, [history, closeChangePasswordDialog]);

  const onSuccess = React.useCallback(() => {
    closeChangePasswordDialog();
    openSuccessDialog();
  }, [closeChangePasswordDialog, openSuccessDialog]);

  const onSuccessDialogClose = React.useCallback(() => {
    closeSuccessDialog();
    history.push("/");
  }, [closeSuccessDialog, history]);

  if (isFederatedSsoAccount) {
    return (
      <Container sx={{ mt: 4 }}>
        <Card sx={{ p: 3 }}>
          <Alert severity="warning">{t("changePasswordPage.federatedSsoWarning")}</Alert>
        </Card>
      </Container>
    );
  }

  return (
    <>
      {username && isChangePasswordDialogOpen && <ChangePasswordDialog onClose={onClose} onSuccess={onSuccess} />}
      {isSuccessDialogOpen && (
        <AlertDialog title={t("common.operationCompleted")} onClose={onSuccessDialogClose}>
          {t("changePasswordPage.passwordChangedMessage")}
        </AlertDialog>
      )}
    </>
  );
};

export { ChangePasswordPage };
