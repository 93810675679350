/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LogMessage = {
    level: LogMessage.level;
    message: string;
    details?: any;
}

export namespace LogMessage {

    export enum level {
        DEBUG = 'DEBUG',
        INFO = 'INFO',
        WARN = 'WARN',
        ERROR = 'ERROR',
    }


}
