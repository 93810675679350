import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useUserSession } from "../../hooks/useUserSession";
import { type IPage } from "../../interfaces/page";
import logging from "../../config/logging";
import AppCardPage from "../AppCardPage/AppCardPage";
import PreLogin from "../PreLogin/PreLogin";
import { INHOUSE_ROLES } from "../Dashboard/resources";
import { LoadingPage } from "../../components/LoadingPage/LoadingPage";
import { UserAccountsService, UserGroup } from "../../gen/clients/llts";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";
import { SUPPORTED_APPS } from "../../utils/constants";
import { ClientSelector } from "./SubPages/ClientSelector";

const CLIENT_ROLES = [UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE];

const HomePage: FC<IPage> = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = props;
  const name = t(`${id}.title`);

  const { username, email, isFederatedSsoAccount, roles = [], initialPagePath } = useUserSession();

  const {
    data: userAccount,
    isLoading: isUserAccountLoading,
    isFetching: isUserAccountFetching,
    error: userAccountError,
    refetch: refetchUserAccount
  } = useQuery(["getUserAccount", username], {
    enabled: !!username,
    queryFn: UserAccountsService.getUserAccount
  });

  useEffect(() => {
    logging.info(`Loading ${name}`);
  }, [name]);

  useEffect(() => {
    sessionStorage.removeItem("accordionData");
  }, []);

  useEffect(() => {
    if (initialPagePath) {
      history.push(initialPagePath);
    }
  }, [history, initialPagePath]);

  const isInHouseRole = React.useMemo(() => {
    let isInHouse = false;
    if (username && INHOUSE_ROLES.filter(value => roles.includes(value)).length > 0) {
      isInHouse = true;
    }
    return isInHouse;
  }, [roles, username]);

  const cardItems = React.useMemo(() => {
    const applications = userAccount?.applications;

    let theNames: string[] = [];
    if (applications && applications.length) {
      theNames = SUPPORTED_APPS.filter(appName => applications.includes(appName));
    }

    if (isInHouseRole) {
      theNames.push("DASHBOARD");
    }
    return theNames;
  }, [isInHouseRole, userAccount?.applications]);

  if (isUserAccountLoading || isUserAccountFetching) {
    return <LoadingPage />;
  }

  if (userAccountError) {
    return <ErrorPage apiError={userAccountError} />;
  }

  // For client/client contacts, if clientConfig is not set, display client selector.
  if (
    userAccount &&
    email &&
    !userAccount.clientConfig &&
    CLIENT_ROLES.filter(value => roles.includes(value)).length > 0
  ) {
    return <ClientSelector email={email} onSuccess={refetchUserAccount} />;
  }

  return (
    <>
      {email ? (
        <AppCardPage appCardNames={cardItems || []} name={isFederatedSsoAccount ? email : username} />
      ) : (
        <PreLogin />
      )}
    </>
  );
};

export default HomePage;
