/**
 * Formats a price value into a human-readable string with two decimal places.
 * @param price - The price value to be formatted.
 * @returns A string representing the formatted price.
 */
export function formatPrice(price: number): string {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // Minimum fraction digits (for cents)
    maximumFractionDigits: 2 // Maximum fraction digits (for cents)
  }).format(price);
}
