import * as React from "react";
import { Box, CircularProgress, Container, CssBaseline } from "@mui/material";

const LoadingPage: React.FC = () => (
  <div>
    <CssBaseline />
    <Container maxWidth="xl">
      <Box sx={{ display: "flex" }} justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
    </Container>
  </div>
);

export { LoadingPage };
