const LAST_SESSION_TIMESTAMP_KEY = "llts.portal.session.timestamp";

export class LocalStorageUtils {
  public static storeSessionTimestamp(date: Date | null): void {
    if (!date) {
      localStorage.removeItem(LAST_SESSION_TIMESTAMP_KEY);
    } else {
      const dateStr = date.toISOString();
      localStorage.setItem(LAST_SESSION_TIMESTAMP_KEY, dateStr);
    }
  }

  public static readSessionTimestamp(): Date | null {
    const dateStr = localStorage.getItem(LAST_SESSION_TIMESTAMP_KEY);
    return dateStr ? new Date(dateStr) : null;
  }
}
