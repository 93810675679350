import * as React from "react";
import { TablePagination } from "@mui/material";

interface Props {
  rowsPerPage: number;
  onPageTokenChange: (pageToken: string | undefined) => void;
  onRowsPerPageChange: (newRowsPerPage: number) => void;
  nextPageToken: string | undefined;
  currentPageDataRows: number | undefined;
  rowsPerPageOptions?: Array<number | { value: number; label: string }>;
  resetRef?: React.MutableRefObject<() => void>;
}

const TokenBasedPaginator: React.FC<Props> = ({
  rowsPerPage,
  onPageTokenChange,
  onRowsPerPageChange,
  nextPageToken,
  currentPageDataRows,
  rowsPerPageOptions,
  resetRef
}) => {
  const [page, setPage] = React.useState<number>(0);
  const [pageToken, setPageToken] = React.useState<string>();
  const [prevPageTokens, setPrevPageTokens] = React.useState<(string | undefined)[]>([]);

  const reset = React.useCallback(() => {
    setPage(0);
    setPageToken(undefined);
    setPrevPageTokens([]);
    onPageTokenChange(undefined);
  }, [onPageTokenChange]);

  React.useEffect(() => {
    if (resetRef) {
      // eslint-disable-next-line no-param-reassign
      resetRef.current = reset;
    }
  }, [reset, resetRef]);

  const onPageChange = React.useCallback(
    (event, newPage: number) => {
      if (newPage > page) {
        setPrevPageTokens([...prevPageTokens, pageToken]);
        setPageToken(nextPageToken);
        onPageTokenChange(nextPageToken);
      } else {
        const updatedPrevPageTokens = [...prevPageTokens];
        const newPageToken = updatedPrevPageTokens.pop();
        setPageToken(newPageToken);
        onPageTokenChange(newPageToken);
        setPrevPageTokens(updatedPrevPageTokens);
      }
      setPage(newPage);
    },
    [page, prevPageTokens, pageToken, nextPageToken, onPageTokenChange]
  );

  const handleRowsPerPageChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setPageToken(undefined);
      onPageTokenChange(undefined);
      setPrevPageTokens([]);
      onRowsPerPageChange(+event.target.value);
    },
    [onPageTokenChange, onRowsPerPageChange]
  );

  return (
    <TablePagination
      component="div"
      count={-1}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleRowsPerPageChange}
      labelDisplayedRows={({ page: pageNumber }) => `Page ${pageNumber + 1}`}
      nextIconButtonProps={{ disabled: !nextPageToken || (currentPageDataRows || 0) < rowsPerPage }}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

export { TokenBasedPaginator };
