/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserGroup {
    ADMIN = 'ADMIN',
    SUPER_USER = 'SUPER_USER',
    OPERATOR = 'OPERATOR',
    POWER_USER = 'POWER_USER',
    CLIENT = 'CLIENT',
    CLIENT_EMPLOYEE = 'CLIENT_EMPLOYEE',
    LINGUIST = 'LINGUIST',
    PROJECT_COORDINATOR = 'PROJECT_COORDINATOR',
    CLIENT_MANAGER = 'CLIENT_MANAGER',
    DESKTOP_PUBLISHER = 'DESKTOP_PUBLISHER',
    ENGINEER = 'ENGINEER',
    QA = 'QA',
    QUOTER = 'QUOTER',
}