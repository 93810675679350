/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddFilesRequest } from '../models/AddFilesRequest';
import type { CreateProjectRequest } from '../models/CreateProjectRequest';
import type { CreateProjectResponse } from '../models/CreateProjectResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * Creates a project
     * @returns CreateProjectResponse Returns an object with project ID
     * @throws ApiError
     */
    public static createProject({
        requestBody,
    }: {
        /** Create project request parameters **/
        requestBody?: CreateProjectRequest,
    }): CancelablePromise<CreateProjectResponse> {
        return __request({
            method: 'POST',
            path: `/projects`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Registers files that were previously uploaded to S3 in XTRF
     * @returns any Successfully accepted request.
     * @throws ApiError
     */
    public static addProjectFiles({
        projectId,
        requestBody,
    }: {
        /** Project ID. **/
        projectId: string,
        /** File names **/
        requestBody?: AddFilesRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/projects/${projectId}/actions/addFiles`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}