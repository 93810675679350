import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"; // import icon
import { Box } from "@mui/material";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { LocalStorageUtils } from "../../utils/LocalStorageUtils";

const SESSION_TIMEOUT_MS: number = 60 * 60 * 1000; // 1 hour
const PRESENCE_CONFIRMATION_TIMEOUT_MS: number = 30 * 1000; // Set timer for logging out

const logoutUser = async () => {
  try {
    await Auth.signOut();
    window.console.log("User successfully logged out");
  } catch (error) {
    window.console.log("Error signing out: ", error);
  }
};

/**
 * SessionExpirationManager is a React function component that manages the expiration of a session.
 * It includes a dialog that will be displayed to the user when the session is about to expire.
 *
 * @returns {ReactElement} - The component that manages the session expiration and displays the dialog.
 */
const SessionExpirationManager: React.FC = () => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [sessionTimeoutId, setSessionTimeoutId] = React.useState<NodeJS.Timeout | null>(null); // Identifier for session expiration timer
  const [confirmationTimeoutId, setConfirmationTimeoutId] = React.useState<NodeJS.Timeout | null>(null); // Identifier for confirmation timer

  React.useEffect(() => {
    // check if the session timestamp exists and if the current time exceeds session timeout
    const lastSessionTimestamp = LocalStorageUtils.readSessionTimestamp();
    const now = new Date();
    if (lastSessionTimestamp && now.getTime() - lastSessionTimestamp.getTime() > SESSION_TIMEOUT_MS) {
      logoutUser();
    }
    // executed once before unmounting the component
    return () => {
      LocalStorageUtils.storeSessionTimestamp(now);
      if (sessionTimeoutId) {
        clearTimeout(sessionTimeoutId);
      }
      if (confirmationTimeoutId) {
        clearTimeout(confirmationTimeoutId);
      }
    };
  }, [confirmationTimeoutId, sessionTimeoutId]);

  const resetSessionTimeout = React.useCallback(() => {
    setIsActive(true);
    const timeoutId = setTimeout(() => setIsActive(false), SESSION_TIMEOUT_MS);
    setSessionTimeoutId(prevSessionTimeoutId => {
      if (prevSessionTimeoutId) {
        clearTimeout(prevSessionTimeoutId);
      }
      return timeoutId;
    });
  }, []);

  const clearConfirmationTimeout = React.useCallback(() => {
    if (confirmationTimeoutId) {
      clearTimeout(confirmationTimeoutId);
    }
    setConfirmationTimeoutId(null);
  }, [confirmationTimeoutId]);

  const onActivity = React.useCallback(() => {
    setIsActive(true);
    resetSessionTimeout();
  }, [resetSessionTimeout]);

  const handleButtonClick = () => {
    resetSessionTimeout();
    clearConfirmationTimeout();
    setOpenDialog(false);
  };

  const onDialogClose = React.useCallback((event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", onActivity, false);
    window.addEventListener("keypress", onActivity, false);
    window.addEventListener("DOMMouseScroll", onActivity, false);
    window.addEventListener("mousewheel", onActivity, false);
    window.addEventListener("beforeunload", () => {
      LocalStorageUtils.storeSessionTimestamp(new Date());
    });

    return () => {
      window.removeEventListener("mousedown", onActivity, false);
      window.removeEventListener("keypress", onActivity, false);
      window.removeEventListener("DOMMouseScroll", onActivity, false);
      window.removeEventListener("mousewheel", onActivity, false);
    };
  }, [onActivity]);

  useEffect(() => {
    if (!isActive) {
      setOpenDialog(true);
      const timeoutId = setTimeout(logoutUser, PRESENCE_CONFIRMATION_TIMEOUT_MS);
      setConfirmationTimeoutId(timeoutId);
    } else if (!openDialog) {
      resetSessionTimeout();
      setOpenDialog(false);
    }
  }, [isActive, openDialog, resetSessionTimeout]);

  return (
    <Dialog
      open={openDialog}
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center" component="span">
          <InfoOutlinedIcon color="primary" fontSize="large" style={{ marginRight: "10px" }} />
          {t("sessionExpirationDialog.title")}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t("sessionExpirationDialog.text")}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button onClick={handleButtonClick} color="primary" variant="contained">
          {t("sessionExpirationDialog.button")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { SessionExpirationManager };
