import React, { FC, useState } from "react";
import styles from "./Button.module.scss";

export interface IButton {
  children?: React.ReactNode;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
}

const Button: FC<IButton> = props => {
  const { children, onClick, id, backgroundColor = "#0057a0", hoverBackgroundColor = "#004077" } = props;

  const [isMouseOver, setMouseOver] = useState(false);
  const boxMouseOverHandler = () => {
    setMouseOver(true);
  };

  const boxMouseOutHandler = () => {
    setMouseOver(false);
  };

  const getButtonState = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const buttonStateObject: any = {};
    if (!isMouseOver) {
      buttonStateObject.backgroundColor = backgroundColor;
    } else {
      buttonStateObject.outline = "0";
      buttonStateObject.backgroundColor = hoverBackgroundColor;
      buttonStateObject.textDecoration = "underline";
    }
    return buttonStateObject;
  };

  return (
    <div className={styles.buttonWrapper}>
      <button
        style={getButtonState()}
        type="button"
        id={id}
        onClick={onClick}
        onMouseOver={boxMouseOverHandler}
        onMouseOut={boxMouseOutHandler}
        onFocus={() => 0}
        onBlur={() => 0}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
