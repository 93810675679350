/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientConfig } from '../models/ClientConfig';
import type { CustomFieldDefinition } from '../models/CustomFieldDefinition';
import type { CustomFieldDefinitionCategory } from '../models/CustomFieldDefinitionCategory';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ClientConfigsService {

    /**
     * Returns client configs matching filter criteria
     * @returns ClientConfig Returns client configs matching filter criteria
     * @throws ApiError
     */
    public static listClientConfigs({
        emailDomain,
    }: {
        emailDomain: string,
    }): CancelablePromise<Array<ClientConfig>> {
        return __request({
            method: 'GET',
            path: `/clientConfigs`,
            query: {
                'emailDomain': emailDomain,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Returns a client config by client ID
     * @returns ClientConfig Returns client config.
     * @throws ApiError
     */
    public static getClientConfig({
        clientId,
    }: {
        /** Client ID. **/
        clientId: string,
    }): CancelablePromise<ClientConfig> {
        return __request({
            method: 'GET',
            path: `/clientConfigs/${clientId}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches project custom field definitions for a client that the user belongs to
     * @returns CustomFieldDefinition Returns project custom field definitions.
     * @throws ApiError
     */
    public static getCustomFieldDefinitions({
        clientId,
        category,
    }: {
        /** Client ID. **/
        clientId: string,
        /** Type of custom field definitions.  Supported values are PROJECT and USER. **/
        category?: CustomFieldDefinitionCategory,
    }): CancelablePromise<Array<CustomFieldDefinition>> {
        return __request({
            method: 'GET',
            path: `/clientConfigs/${clientId}/customFieldDefinitions`,
            query: {
                'category': category,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * get client specific languages
     * @returns number Successful operation.
     * @throws ApiError
     */
    public static getAvailableLanguages({
        clientId,
    }: {
        /** Client ID. **/
        clientId: string,
    }): CancelablePromise<Array<number>> {
        return __request({
            method: 'GET',
            path: `/clientConfigs/${clientId}/availableLanguages`,
        });
    }

}