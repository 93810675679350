import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useMutation, useQuery } from "react-query";
import { ClientConfigsService, UserAccountsService } from "gen/clients/llts";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { Form, Formik } from "formik";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { SelectOption } from "@mui/base";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

interface Props {
  email: string;
  onSuccess: () => void;
}

interface FormValues {
  organization: string;
}

const ClientSelector: React.FC<Props> = ({ email, onSuccess }) => {
  const { t } = useTranslation();
  const emailDomain = email.substring(email.indexOf("@") + 1);

  const {
    data: clientConfigs,
    isLoading: areClientConfigsLoading,
    error: clientConfigsError
  } = useQuery(["listClientConfigs", emailDomain], {
    queryFn: () => ClientConfigsService.listClientConfigs({ emailDomain })
  });

  const { mutateAsync: assignClient, error: assignClientError } = useMutation(UserAccountsService.assignClient);

  const organizationOptions: SelectOption<string>[] = React.useMemo(
    () => clientConfigs?.map(c => ({ label: c.companyName, value: c.clientId })) || [],
    [clientConfigs]
  );

  const onSubmit = React.useCallback(
    async (formValues: FormValues) => {
      const clientId = formValues.organization;
      await assignClient({ requestBody: { clientId } }, { onSuccess });
    },
    [assignClient, onSuccess]
  );

  if (areClientConfigsLoading) {
    return <LoadingPage />;
  }

  const error = clientConfigsError || assignClientError;

  return (
    <Container maxWidth="md">
      {error && <SnackbarApiError error={error} />}
      <Formik
        initialValues={{
          organization: ""
        }}
        onSubmit={onSubmit}
        validateOnMount={false}
      >
        {({ isSubmitting }) => (
          <Form noValidate={true} autoComplete="off">
            <Stack sx={{ mt: 8 }} spacing={3}>
              <Alert severity="warning">{t("homePage.selectOrganization")}</Alert>
              <SelectOneField
                name="organization"
                label={t("homePage.organization")}
                options={organizationOptions}
                required={true}
                variant="outlined"
              />
              <Box sx={{ mt: 5, mb: 2, textAlign: "center" }}>
                <LoadingButton isLoading={isSubmitting} variant="contained">
                  {t("common.continue")}
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export { ClientSelector };
