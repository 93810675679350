import * as React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import {
  JobRequest,
  JobRequestOrigination,
  JobRequestStatus,
  JobRequestSummary,
  JobRequestType
} from "gen/clients/llts";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { JobRequestUtils } from "utils/jobRequestUtils";

interface LocalProps {
  jobRequest: JobRequest | JobRequestSummary;
}

type Props = LocalProps & TypographyProps;

interface AbstractLocalProps {
  status: JobRequestStatus;
  submittingToXtrfStatus: string;
  submittedToXtrfStatus: string;
  createdStatus: string;
}

type AbstractProps = AbstractLocalProps & TypographyProps;

const StatusComp: React.FC<{ status: string; inProgress?: boolean } & TypographyProps> = ({
  status,
  color,
  inProgress,
  ...others
}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    {inProgress && <CircularProgress size={10} />}
    <Typography color={color} {...others}>
      {status}
    </Typography>
  </Stack>
);

const AbstractJobRequestState: React.FC<AbstractProps> = ({
  status,
  submittingToXtrfStatus,
  submittedToXtrfStatus,
  createdStatus,
  ...others
}) => {
  switch (status) {
    case JobRequestStatus.ANALYZING:
      return (
        <StatusComp
          color="info.main"
          status="Analyzing"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.TRANSLATING:
      return <StatusComp color="info.main" status="Translating" {...others} />;
    case JobRequestStatus.COMPLETED:
      return (
        <StatusComp
          color="success.main"
          status="Completed"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.CREATED:
      return <StatusComp color="info.main" status={createdStatus} {...others} />;
    case JobRequestStatus.ARCHIVED:
      return <StatusComp status="Archived" inProgress={JobRequestUtils.isInProgress(status)} {...others} />;
    case JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW:
      return (
        <StatusComp
          color="success.main"
          status="Quote is ready for review"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.QUOTE_REJECTED:
      return (
        <StatusComp
          color="warning.main"
          status="Quote Rejected"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.QUOTE_ACCEPTED:
      return (
        <StatusComp
          color="success.main"
          status="Quote Accepted"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.READY_FOR_INTERNAL_REVIEW:
      return (
        <StatusComp
          color="info.main"
          status="Processing"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.SCREENING:
      return (
        <StatusComp
          color="info.main"
          status="Processing"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.REJECTED_BY_SCREENING:
      return (
        <StatusComp
          color="info.main"
          status="Processing"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return (
        <StatusComp
          color="info.main"
          inProgress={JobRequestUtils.isInProgress(status)}
          status={submittingToXtrfStatus}
          {...others}
        />
      );
    case JobRequestStatus.SUBMITTED_TO_XTRF:
      return (
        <StatusComp
          color="info.main"
          status={submittedToXtrfStatus}
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.CANCELLED:
      return (
        <StatusComp
          color="error.main"
          status="Cancelled"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.CANCELLING:
      return (
        <StatusComp
          color="error.main"
          status="Cancelling"
          inProgress={JobRequestUtils.isInProgress(status)}
          {...others}
        />
      );
    case JobRequestStatus.FAILED:
      return (
        <StatusComp color="error.main" status="Failed" inProgress={JobRequestUtils.isInProgress(status)} {...others} />
      );
    default:
      return <StatusComp {...others} status={status} inProgress={JobRequestUtils.isInProgress(status)} />;
  }
};

const ProjectStatus: React.FC<{ status: JobRequestStatus } & TypographyProps> = ({ status, ...other }) => (
  <AbstractJobRequestState
    status={status}
    {...other}
    submittingToXtrfStatus="Processing"
    submittedToXtrfStatus="In translation"
    createdStatus="Translating"
  />
);

const QuoteStatus: React.FC<{ status: JobRequestStatus } & TypographyProps> = ({ status, ...other }) => (
  <AbstractJobRequestState
    status={status}
    {...other}
    submittingToXtrfStatus="Processing"
    submittedToXtrfStatus="Estimating"
    createdStatus="Estimating"
  />
);

const JobRequestState: React.FC<Props> = ({ jobRequest, ...other }) => {
  switch (jobRequest.origination) {
    case JobRequestOrigination.WEBFORM:
    case JobRequestOrigination.COUPA:
      return jobRequest.requestType === JobRequestType.QUOTE_REQUEST ? (
        <QuoteStatus status={jobRequest.status} {...other} />
      ) : (
        <ProjectStatus status={jobRequest.status} {...other} />
      );
    default:
      throw new Error(`Unsupported job request origination: ${jobRequest.origination}`);
  }
};

export { JobRequestState };
