/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JobRequestAutoQuote = {
    pricePerPage: number;
    /**
     * Total number of pages
     */
    totalPages?: number;
    /**
     * Page count for each file by file name
     */
    pageCountByFileName?: Record<string, number>;
    /**
     * Total price
     */
    price?: number;
    /**
     * Reason for rejection of the quote
     */
    rejectionReason?: string;
    /**
     * Acceptance status of the quote
     */
    acceptanceStatus?: JobRequestAutoQuote.acceptanceStatus;
    acceptanceDeadline: string;
    creationDate: string;
    acceptanceDate?: string;
}

export namespace JobRequestAutoQuote {

    /**
     * Acceptance status of the quote
     */
    export enum acceptanceStatus {
        ACCEPTED = 'ACCEPTED',
        REJECTED = 'REJECTED',
    }


}
