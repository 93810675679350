import * as React from "react";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { ErrorPage } from "../ErrorPage/ErrorPage";
import { Box } from "@mui/material";

interface Props {
  isLoading?: boolean;
  error?: any;
  children?: React.ReactNode;
}

const DialogBody: React.FC<Props> = ({ isLoading, error, children }) => {
  if (isLoading) {
    return (
      <Box minHeight={150}>
        <LoadingPage />
      </Box>
    );
  }

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  return <>{children}</>;
};

export { DialogBody };
