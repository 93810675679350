import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { UserAccountsService } from "gen/clients/llts";
import { useMutation } from "react-query";
import OTPInput from "react-otp-input";
import { SnackbarApiError } from "../SnackbarApiError/SnackbarApiError";
import { LoadingButton } from "../LoadingButton/LoadingButton";

interface Props {
  email: string;
  onSuccess: (code: string) => void;
  onClose: () => void;
}

const VERIFICATION_CODE_LENGTH = 6;

const EmailVerificationDialog: React.FC<Props> = ({ email, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = React.useState("");

  const {
    mutate: validateEmailVerificationCode,
    data: emailValidationResult,
    isLoading: isValidationInProgress,
    error: validationError
  } = useMutation(UserAccountsService.validateEmailVerificationCode);

  const { mutate: sendEmailVerificationCode, error: sendEmailError } = useMutation(
    UserAccountsService.sendEmailVerificationCode
  );

  const apiError = validationError || sendEmailError;

  React.useEffect(() => {
    sendEmailVerificationCode({
      requestBody: {
        email
      }
    });
  }, [email, sendEmailVerificationCode]);

  React.useEffect(() => {
    if (emailValidationResult?.isValid) {
      onSuccess(verificationCode);
    }
  }, [emailValidationResult, onSuccess, verificationCode]);

  const onResendClick = React.useCallback(() => {
    setVerificationCode("");
    sendEmailVerificationCode({
      requestBody: {
        email
      }
    });
  }, [email, sendEmailVerificationCode]);

  const onVerifyClick = React.useCallback(() => {
    validateEmailVerificationCode({
      requestBody: {
        email,
        verificationCode
      }
    });
  }, [email, validateEmailVerificationCode, verificationCode]);

  const onDialogClose = React.useCallback(
    (event, reason) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Dialog open={true} onClose={onDialogClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>{t("emailVerification.dialogTitle")}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} alignItems="center">
          <Typography variant="body1">{t("emailVerification.emailSentMessage")}</Typography>
          <OTPInput
            onChange={code => {
              setVerificationCode(code);
            }}
            value={verificationCode}
            numInputs={VERIFICATION_CODE_LENGTH}
            inputStyle={{ fontSize: 30, marginLeft: 8, marginRight: 8 }}
            inputType="text"
            renderSeparator={<span />}
            renderInput={props => <input {...props} />}
            shouldAutoFocus
          />
          {emailValidationResult?.isValid === false && (
            <Typography color="error.main" textAlign="center">
              {t("emailVerification.invalidCode")}
            </Typography>
          )}
          <Stack alignItems="center" sx={{ pt: 3 }}>
            <Typography>{t("emailVerification.didntReceiveCode")}</Typography>
            <Button onClick={onResendClick}>{t("emailVerification.resendBtn")}</Button>
          </Stack>
        </Stack>
        {!!apiError && <SnackbarApiError error={apiError} />}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          color="primary"
          type="button"
          variant="contained"
          disabled={verificationCode.length < VERIFICATION_CODE_LENGTH}
          isLoading={isValidationInProgress}
          onClick={onVerifyClick}
        >
          {t("emailVerification.verifyBtn")}
        </LoadingButton>
        <Button onClick={onClose}>{t("common.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export { EmailVerificationDialog };
