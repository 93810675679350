import { default as dayjs } from "dayjs";

const DATE_FORMAT = "MMM DD, YYYY HH:mm:ss";

export function formatEpoch(epochDate: number | undefined): string {
  if (epochDate === undefined) {
    return "";
  }
  return dayjs.unix(epochDate).format(DATE_FORMAT);
}

export function formatDate(date: Date | string | undefined): string {
  if (!date) {
    return "";
  }
  return dayjs(date).format(DATE_FORMAT);
}

/**
 * Converts a Date to Unix epoch time.
 * @param date
 */
export function dateToEpoch(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

/**
 * Converts Unix epoch time to Date
 * @param epochTime
 */
export function epochToDate(epochTime: number): Date {
  return new Date(epochTime * 1000);
}
