// noinspection ES6UnusedImports

import React from "react";
import ReactDOM from "react-dom";
import { Auth } from "@aws-amplify/auth";
import App from "components/app/App";
import { QueryClient, QueryClientProvider } from "react-query";
import { OpenAPI } from "gen/clients/llts";
import initI18n from "./i18n";
import { USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY } from "./utils/constants";
import { UserSessionOverride } from "./hooks/useUserSessionOverride";
import { GlobalErrorHandler } from "./GlobalErrorHandler";

const initThis = async () => {
  await initI18n();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 20 * 60 * 1000 // 20 minutes
      }
    }
  });

  // Set API client token
  OpenAPI.TOKEN = async () => {
    try {
      const session = await Auth.currentSession();
      return session?.getIdToken().getJwtToken();
    } catch (e) {
      return Promise.resolve("");
    }
  };

  OpenAPI.BASE = process.env.REACT_APP_API_URL_OVERRIDE || OpenAPI.BASE;

  OpenAPI.HEADERS = async options => {
    const userSessionOverrideStoredValue = window.sessionStorage.getItem(USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY);
    const headers = options.headers || {};
    const userSessionOverride = userSessionOverrideStoredValue
      ? (JSON.parse(userSessionOverrideStoredValue) as UserSessionOverride)
      : undefined;
    if (userSessionOverride?.username && userSessionOverride) {
      return {
        ...headers,
        "X-Username-Override": userSessionOverride.username
      };
    }
    return headers;
  };

  GlobalErrorHandler.init();

  // eslint-disable-next-line
  // import i18n from './i18n';

  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  // reportWebVitals();
};
initThis().then();
