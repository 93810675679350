import * as React from "react";
import { LanguageGroup, LanguageGroupScope, UserAccountsService } from "gen/clients/llts";
import { DataTable } from "components/DataTable/DataTable";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { AddLanguageGroupDialog } from "./AddLanguageGroupDialog";
import { EditLanguageGroupDialog } from "./EditLanguageGroupDialog";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  languageGroups: LanguageGroup[];
  languageGroupScope: LanguageGroupScope;
  viewOnly?: boolean;
  onChange: () => void;
}

const LanguageGroupsTable: React.FC<Props> = ({ languageGroups, languageGroupScope, viewOnly, onChange }) => {
  const { t } = useTranslation();
  const [selectedLanguageGroup, setSelectedLanguageGroup] = React.useState<LanguageGroup | null>(null);
  const [selectedLanguageGroupIndex, setSelectedLanguageGroupIndex] = React.useState<number>(-1);
  const [isAddDialogOpen, openAddDialog, closeAddDialog] = useDialogState();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useDialogState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();

  const {
    mutate: storeLanguageGroups,
    isLoading: isStoringLanguageGroups,
    error: storeLanguageGroupsError
  } = useMutation(UserAccountsService.storeLanguageGroups);

  const nameCell = React.useCallback((row: LanguageGroup) => {
    return <div>{row.name}</div>;
  }, []);

  const languagesCell = React.useCallback((row: LanguageGroup) => {
    return (
      <div>
        {row.languages
          ?.map(l => l.name)
          .sort((a, b) => a.localeCompare(b))
          .join(", ")}
      </div>
    );
  }, []);

  const onEditClick = React.useCallback(
    (languageGroup: LanguageGroup, index: number) => {
      setSelectedLanguageGroup(languageGroup);
      setSelectedLanguageGroupIndex(index);
      openEditDialog();
    },
    [openEditDialog]
  );

  const onDeleteClick = React.useCallback(
    (languageGroup: LanguageGroup, index: number) => {
      setSelectedLanguageGroup(languageGroup);
      setSelectedLanguageGroupIndex(index);
      openDeleteDialog();
    },
    [openDeleteDialog]
  );

  const onDeleteConfirm = React.useCallback(() => {
    if (selectedLanguageGroup && selectedLanguageGroupIndex >= 0) {
      storeLanguageGroups(
        {
          groupScope: selectedLanguageGroup.scope,
          requestBody: languageGroups.filter((_, i) => i !== selectedLanguageGroupIndex)
        },
        {
          onSuccess: () => {
            onChange();
            closeDeleteDialog();
          }
        }
      );
    }
  }, [
    selectedLanguageGroup,
    selectedLanguageGroupIndex,
    storeLanguageGroups,
    languageGroups,
    onChange,
    closeDeleteDialog
  ]);

  const onAddLanguageGroupSuccess = React.useCallback(() => {
    onChange();
    closeAddDialog();
  }, [onChange, closeAddDialog]);

  return (
    <>
      <Stack spacing={1}>
        {!viewOnly && (
          <div>
            <Button variant="contained" color="primary" onClick={openAddDialog}>
              {t("accountSettings.languageGroups.addLanguageGroup")}
            </Button>
          </div>
        )}
        <DataTable
          columns={[
            {
              id: "name",
              title: <strong>{t("accountSettings.languageGroups.groupName")}</strong>,
              cell: nameCell
            },
            {
              id: "languages",
              title: <strong>{t("accountSettings.languageGroups.languages")}</strong>,
              cell: languagesCell
            }
          ]}
          data={languageGroups}
          rowKey={(row: LanguageGroup) => row.name}
          rowActions={
            viewOnly
              ? undefined
              : [
                  {
                    title: t("accountSettings.languageGroups.edit"),
                    color: "primary.main",
                    action: onEditClick
                  },
                  {
                    title: t("accountSettings.languageGroups.delete"),
                    color: "error.main",
                    action: onDeleteClick
                  }
                ]
          }
          dense={true}
          elevation={0}
          noData={t("accountSettings.languageGroups.noLanguageGroups")}
        />
      </Stack>
      {isAddDialogOpen && (
        <AddLanguageGroupDialog
          onClose={closeAddDialog}
          onSuccess={onAddLanguageGroupSuccess}
          languageGroupScope={languageGroupScope}
          languageGroups={languageGroups}
        />
      )}
      {isEditDialogOpen && selectedLanguageGroupIndex >= 0 && (
        <EditLanguageGroupDialog
          onClose={closeEditDialog}
          languageGroupIndex={selectedLanguageGroupIndex}
          languageGroups={languageGroups}
          onSuccess={onChange}
        />
      )}
      {isDeleteDialogOpen && selectedLanguageGroup && selectedLanguageGroupIndex >= 0 && (
        <ConfirmationDialog
          title={t("accountSettings.languageGroups.deleteDialog.title")}
          content={t("accountSettings.languageGroups.deleteDialog.message", { groupName: selectedLanguageGroup.name })}
          onClose={closeDeleteDialog}
          onConfirm={onDeleteConfirm}
          isLoading={isStoringLanguageGroups}
          error={storeLanguageGroupsError ? <ApiErrorMessage apiError={storeLanguageGroupsError} /> : undefined}
        />
      )}
    </>
  );
};

export { LanguageGroupsTable };
