import { PresignedPost } from "gen/clients/llts";
import axios from "axios";

export type UploadProgressCallback = (delta: number) => void;

export class S3FileUploader {
  public static async upload(
    files: File[],
    presignedPost: PresignedPost,
    onUploadProgress?: UploadProgressCallback
  ): Promise<void> {
    const url = presignedPost.url;
    if (!url) {
      throw new Error("url in presigned post is not set");
    }
    const promises = files.map(file => this.uploadFile(url || "", presignedPost.fields || {}, file, onUploadProgress));
    await Promise.all(promises);
  }

  private static async uploadFile(
    url: string,
    fields: Record<string, string>,
    file: File,
    onUploadProgress?: UploadProgressCallback
  ): Promise<void> {
    const formData = new FormData();
    Object.entries(fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);

    await axios.post(url, formData, {
      onUploadProgress: progressEvent => {
        if (onUploadProgress) {
          const delta =
            progressEvent.loaded > file.size
              ? progressEvent.bytes - (progressEvent.loaded - file.size)
              : progressEvent.bytes;
          onUploadProgress(delta);
        }
      }
    });
  }
}
