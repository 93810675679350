import * as React from "react";
import { createFilterOptions, FormControl, TextField, TextFieldProps } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import { useTranslation } from "react-i18next";
import { SelectOption } from "@mui/base";
import { ListboxComponent, StyledPopper } from "./ListBoxComponent";

interface LocalProps {
  name: string;
  label: string;
  options: SelectOption<string>[];
  required?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

type Props = TextFieldProps & LocalProps;

const filterOptions = createFilterOptions({
  matchFrom: "start",
  trim: true
});

const SelectManyAutocompleteField: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  disabled,
  isLoading,
  isError,
  variant = "standard",
  ...others
}) => {
  const { t } = useTranslation();
  const { errors, touched, isSubmitting } = useFormikContext<Record<string, string>>();

  const validate = React.useCallback(
    (value: SelectOption<string>[] | null) =>
      required && (!value || value.length === 0) ? t("common.validation.required") : undefined,
    [required, t]
  );

  const isOptionEqualToValue = React.useCallback(
    (option: SelectOption<string>, value: string | SelectOption<string>) => {
      return option === value || option.value === value || option.value === (value as SelectOption<string>).value;
    },
    []
  );

  const renderInput = React.useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        // We have to manually set the corresponding fields on the input component
        name={name}
        error={(touched[name] && !!(errors[name] as string)) || isError}
        helperText={(touched[name] && (errors[name] as string)) || others.helperText}
        label={label}
        variant={variant}
        required={required}
        disabled={isSubmitting}
      />
    ),
    [name, touched, errors, isError, others.helperText, label, variant, required, isSubmitting]
  );

  return (
    <FormControl fullWidth>
      <Field
        {...others}
        component={Autocomplete}
        type="text"
        name={name}
        disableListWrap
        renderInput={renderInput}
        placeholder={isLoading ? "Loading..." : (isError && "Error") || ""}
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        options={options}
        renderOption={(props: unknown, option: SelectOption<string>, { selected }: { selected: boolean }) =>
          [props, { selected, label: option.label }] as React.ReactNode
        }
        disabled={disabled || isLoading || isError || isSubmitting}
        required={required}
        validate={validate}
        isOptionEqualToValue={isOptionEqualToValue}
        multiple={true}
        disableCloseOnSelect={true}
        filterOptions={filterOptions}
      />
    </FormControl>
  );
};

export { SelectManyAutocompleteField };
