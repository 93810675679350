import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik/dist/types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { ScrollToFieldError } from "components/ScrollToFieldError/ScrollToFieldError";
import { JobRequestOrigination, JobRequestType } from "gen/clients/llts";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import Stack from "@mui/material/Stack";
import { EmailVerificationDialog } from "components/EmailVerificationDialog/EmailVerificationDialog";
import { validateEmailFormat } from "utils/stringUtils";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { AlertDialog } from "components/AlertDialog/AlertDialog";
import { LinearProgressWithLabel } from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import { DEFAULT_COUNTRY_CODE } from "utils/constants";
import { CreateProjectFormValidator } from "../../CreateProjectPage/components/CreateProjectForm/CreateProjectFormValidator";
import { FormHeader } from "../../CreateProjectPage/components/FormHeader/FormHeader";
import { RequestSubmitted } from "../../CreateProjectPage/components/RequestSubmitted/RequestSubmitted";
import { SubmitType } from "../../CreateProjectPage/components/CreateProjectForm/CreateProjectForm";
import {
  UserContactSectionField,
  UserContactSectionFormValues
} from "../../CreateProjectPage/components/UserContactSection/UserContactSection";
import {
  ProjectInfoFieldName,
  ProjectInfoFormValues
} from "../../CreateProjectPage/components/ProjectInfoSection/ProjectInfoSection";
import {
  ContentInputMethod,
  FilesSectionFieldNames,
  FilesSectionFormValues
} from "../../CreateProjectPage/components/FilesSection/FilesSection";
import { CreateJobRequestForm } from "../components/CreateJobRequestForm/CreateJobRequestForm";
import { useSubmitJobRequest } from "../hooks/useSubmitJobRequest";

interface Props {
  email: string;
  origination: JobRequestOrigination;
  allowSendToTranslation: boolean;
}

type Values = UserContactSectionFormValues & FilesSectionFormValues & ProjectInfoFormValues;

const UnknownUserCreateJobRequestPage: React.FC<Props> = ({ email, origination, allowSendToTranslation }) => {
  const { t } = useTranslation();
  const formikRef = React.useRef<FormikProps<Values>>(null);
  const [requestType, setRequestType] = React.useState<JobRequestType>();
  const [verificationCode, setVerificationCode] = React.useState<string>();
  const [isEmailVerificationDialogOpen, openEmailVerificationDialog, closeEmailVerificationDialog] = useDialogState();

  const formValues = formikRef.current?.values;

  const {
    submitJobRequest,
    isSubmitting,
    error: submitJobRequestError,
    jobRequestCreated,
    progress
  } = useSubmitJobRequest({
    origination,
    formValues,
    requestType,
    emailVerificationCode: verificationCode
  });

  const onSubmit = React.useCallback(() => {
    openEmailVerificationDialog();
  }, [openEmailVerificationDialog]);

  const onEmailVerificationCancel = React.useCallback(() => {
    formikRef.current?.setSubmitting(false);
    closeEmailVerificationDialog();
  }, [closeEmailVerificationDialog]);

  const onEmailVerificationSuccess = React.useCallback(
    (validatedverificationCode: string) => {
      setVerificationCode(validatedverificationCode);
      closeEmailVerificationDialog();
      submitJobRequest();
    },
    [closeEmailVerificationDialog, submitJobRequest]
  );

  const validate = React.useCallback(
    (values: Values) => {
      const errors: Partial<Values> = {};
      // Validate email
      const emailValue = values.email as string;
      if (!emailValue) {
        errors.email = t("common.validation.required");
      } else if (!validateEmailFormat(emailValue)) {
        errors.email = t("createProject.unknownUser.validationMessages.emailFormat");
      }
      const documentInputErrors = CreateProjectFormValidator.validate(values as unknown as Record<string, unknown>, t);
      return { ...errors, ...documentInputErrors };
    },
    [t]
  );

  const initialValues = React.useMemo(() => {
    const standardFieldValues = {
      [UserContactSectionField.companyName]: "",
      [UserContactSectionField.email]: email,
      [UserContactSectionField.firstName]: "",
      [UserContactSectionField.lastName]: "",
      [UserContactSectionField.address1]: "",
      [UserContactSectionField.address2]: "",
      [UserContactSectionField.city]: "",
      [UserContactSectionField.province]: "",
      [UserContactSectionField.zip]: "",
      [UserContactSectionField.country]: DEFAULT_COUNTRY_CODE,
      [UserContactSectionField.phone]: "",
      [ProjectInfoFieldName.sourceLanguage]: null,
      [ProjectInfoFieldName.targetLanguages]: [],
      [ProjectInfoFieldName.notes]: "",
      [ProjectInfoFieldName.projectName]: "",
      [ProjectInfoFieldName.xtrfServiceId]: "",
      [FilesSectionFieldNames.inputMethod]: ContentInputMethod.FILES,
      [FilesSectionFieldNames.files]: [],
      [FilesSectionFieldNames.fileLinks]: [],
      [FilesSectionFieldNames.textInput]: "",
      [FilesSectionFieldNames.includeReferenceFiles]: [],
      [FilesSectionFieldNames.referenceFiles]: []
    };
    return {
      ...standardFieldValues
    };
  }, [email]);

  if (jobRequestCreated && requestType) {
    const submitType: SubmitType =
      requestType === JobRequestType.TRANSLATION_REQUEST ? SubmitType.PROJECT : SubmitType.ESTIMATE;
    return <RequestSubmitted submitType={submitType} />;
  }

  return (
    <>
      <CssBaseline />
      <FormHeader title={t("createProject.unknownUser.header.title")}>
        <p>{t("createProject.unknownUser.header.content1")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("createProject.unknownUser.header.content2") }} />
      </FormHeader>
      <Container maxWidth="md">
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
              validateOnMount={false}
              innerRef={formikRef}
            >
              <Form noValidate={true} autoComplete="off" autoCorrect="off">
                <ScrollToFieldError />
                <CreateJobRequestForm
                  fileUploadLimits={{
                    maxFileCount: 20,
                    maxFileSize: 40 * 1024 * 1024, // 40 MB
                    maxTotalSize: 60 * 1024 * 1024 // 60 MB
                  }}
                  disableEmailInput={true}
                />
                <Box marginY={5}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mr: 1 }}
                    loading={isSubmitting && requestType === JobRequestType.QUOTE_REQUEST}
                    onClick={() => {
                      setRequestType(JobRequestType.QUOTE_REQUEST);
                    }}
                  >
                    {t("createProject.createProjectForm.sendForEstimation")}
                  </LoadingButton>
                  {allowSendToTranslation && (
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting && requestType === JobRequestType.TRANSLATION_REQUEST}
                      onClick={() => {
                        setRequestType(JobRequestType.TRANSLATION_REQUEST);
                      }}
                    >
                      {t("createProject.createProjectForm.sendToTranslation")}
                    </LoadingButton>
                  )}
                </Box>
                {isSubmitting && (
                  <AlertDialog title={t("createProject.createProjectForm.submittingAlert.title")}>
                    <Stack spacing={1}>
                      <Box>{t("createProject.createProjectForm.submittingAlert.text")}</Box>
                      <Box sx={{ width: "100%" }}>
                        <LinearProgressWithLabel value={progress || 0} />
                      </Box>
                    </Stack>
                  </AlertDialog>
                )}
              </Form>
            </Formik>
          </Grid>
        </Grid>
        {submitJobRequestError && <SnackbarApiError error={submitJobRequestError} />}
        {isEmailVerificationDialogOpen && (
          <EmailVerificationDialog
            email={(formikRef?.current?.values[UserContactSectionField.email] as string) || ""}
            onSuccess={onEmailVerificationSuccess}
            onClose={onEmailVerificationCancel}
          />
        )}
      </Container>
    </>
  );
};

export { UnknownUserCreateJobRequestPage };
