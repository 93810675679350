import * as React from "react";
import { Box, IconButton, Toolbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  title: string;
  onClose: () => void;
}

const DrawerHeader: React.FC<Props> = ({ title, onClose }) => (
  <Toolbar>
    <Typography variant="h5">{title}</Typography>
    <Box sx={{ flexGrow: 1 }} />
    <IconButton size="large" onClick={onClose}>
      <CloseIcon />
    </IconButton>
  </Toolbar>
);

export { DrawerHeader };
