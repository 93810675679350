import * as React from "react";
import { Box, Drawer } from "@mui/material";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { LanguageGroupsTab } from "./LanguageGroupsTab/LanguageGroupsTab";
import { ProfileTab } from "./ProfileTab/ProfileTab";

interface Props {
  onClose: () => void;
}

enum TabId {
  profile = "profile",
  languageGroups = "languageGroups"
}

const AccountSettingsPanel: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedTab, setSelectedTab] = React.useState<string>(TabId.profile);

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Drawer open={true} anchor="right" PaperProps={{ sx: { width: isSmallScreen ? "100vw" : "50vw" } }}>
      <DrawerHeader title={t("accountSettings.title")} onClose={onClose} />
      <DrawerBody>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs onChange={onTabChange} value={selectedTab}>
            <Tab value={TabId.profile} label={t("accountSettings.profile.title")} />
            <Tab value={TabId.languageGroups} label={t("accountSettings.languageGroups.title")} />
          </Tabs>
        </Box>
        {selectedTab === TabId.profile && <ProfileTab />}
        {selectedTab === TabId.languageGroups && <LanguageGroupsTab />}
      </DrawerBody>
    </Drawer>
  );
};

export { AccountSettingsPanel };
