import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IPage } from "../../../interfaces/page";
import logging from "../../../config/logging";
import HomeCrumb from "../../../components/Breadcrumb/HomeCrumb";

const AppPlaceholder: React.FC<IPage> = props => {
  const { id } = props;
  const { t } = useTranslation();
  const name = t(`${id}.title`);

  useEffect(() => {
    logging.info(`Loading ${name}`);
  }, [name]);

  // noinspection HtmlUnknownTarget
  const body = React.useMemo(
    () => (
      <>
        <p dangerouslySetInnerHTML={{ __html: `Future home of the ${t(name)} page` }} style={{ textAlign: "center" }} />
      </>
    ),
    [name, t]
  );

  return (
    <div>
      <HomeCrumb />
      <div>{body}</div>
    </div>
  );
};

export default AppPlaceholder;
