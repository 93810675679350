/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportMetadata } from '../models/ReportMetadata';
import type { ReportMetadataSummary } from '../models/ReportMetadataSummary';
import type { ReportRowCollection } from '../models/ReportRowCollection';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * Lists reports
     * @returns ReportMetadataSummary Returns list of reports
     * @throws ApiError
     */
    public static listReports(): CancelablePromise<Array<ReportMetadataSummary>> {
        return __request({
            method: 'GET',
            path: `/reports`,
        });
    }

    /**
     * Returns report
     * @returns ReportMetadata Returns report metadata
     * @throws ApiError
     */
    public static getReport({
        reportId,
    }: {
        /** report Id **/
        reportId: string,
    }): CancelablePromise<ReportMetadata> {
        return __request({
            method: 'GET',
            path: `/reports/${reportId}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Return paginated report rows
     * @returns ReportRowCollection Returns report row collection
     * @throws ApiError
     */
    public static getReportRows({
        reportId,
        page,
        limit,
        filters,
    }: {
        /** report Id **/
        reportId: string,
        /** Sets current page index **/
        page?: number,
        /** Sets the page size **/
        limit?: number,
        /** filters query **/
        filters?: string,
    }): CancelablePromise<ReportRowCollection> {
        return __request({
            method: 'GET',
            path: `/reports/${reportId}/rows`,
            query: {
                'page': page,
                'limit': limit,
                'filters': filters,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

}