import { ReportMetadata, ReportRowCollection } from "gen/clients/llts";

export enum ReportExportType {
  CSV = "CSV",
  JSON = "JSON"
}
// Exporter interface
export interface Exporter {
  exportData(report: ReportMetadata, reportData: ReportRowCollection): { blob: Blob; filename: string };
}
