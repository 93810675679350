/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { Language } from '../models/Language';
import type { LltsLanguage } from '../models/LltsLanguage';
import type { LogMessage } from '../models/LogMessage';
import type { PresignedPost } from '../models/PresignedPost';
import type { Province } from '../models/Province';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UtilsService {

    /**
     * Returns a list of languages available in XTRF
     * @returns Language Returns a list of all languages
     * @throws ApiError
     */
    public static listLanguages(): CancelablePromise<Array<Language>> {
        return __request({
            method: 'GET',
            path: `/languages`,
        });
    }

    /**
     * Returns a list of languages available in LLTS
     * @returns LltsLanguage Returns a list of Llts languages
     * @throws ApiError
     */
    public static listLltsLanguages({
        xtrfIdExists,
        intentoIdExists,
        smartMateIdExists,
        azureIdExists,
    }: {
        /** is Xtrf mapping configured **/
        xtrfIdExists?: boolean,
        /** is Intento mapping configured **/
        intentoIdExists?: boolean,
        /** is SmartMate mapping configured **/
        smartMateIdExists?: boolean,
        /** is Azure Translator mapping configured **/
        azureIdExists?: boolean,
    }): CancelablePromise<Array<LltsLanguage>> {
        return __request({
            method: 'GET',
            path: `/lltsLanguages`,
            query: {
                'xtrfIdExists': xtrfIdExists,
                'intentoIdExists': intentoIdExists,
                'smartMateIdExists': smartMateIdExists,
                'azureIdExists': azureIdExists,
            },
        });
    }

    /**
     * Returns a list of countries available in XTRF
     * @returns Country Returns a list of countries
     * @throws ApiError
     */
    public static listCountries(): CancelablePromise<Array<Country>> {
        return __request({
            method: 'GET',
            path: `/countries`,
        });
    }

    /**
     * Returns a list of provinces available in XTRF
     * @returns Province Returns a list of provinces
     * @throws ApiError
     */
    public static listProvinces(): CancelablePromise<Array<Province>> {
        return __request({
            method: 'GET',
            path: `/provinces`,
        });
    }

    /**
     * Creates a log record in CloudWatch.  Intended for logging UI event on the server.
     * @returns any Successfully accepted request.
     * @throws ApiError
     */
    public static logUiMessage({
        requestBody,
    }: {
        /** log message **/
        requestBody?: LogMessage,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/uiLogs`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a presigned URL for uploading files to temp S3 bucket
     * @returns PresignedPost successful operation
     * @throws ApiError
     */
    public static createTempFileUploadPresignedPost(): CancelablePromise<PresignedPost> {
        return __request({
            method: 'POST',
            path: `/tempFiles/actions/createPresignedPost`,
        });
    }

}