import * as React from "react";
import { Checkbox, FormControl, ListItemText, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";

interface Props {
  name: string;
  label: string;
  options: { label: string; value: string }[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  helperText?: string;
}

const PipeSeparatedSelectManyField: React.FC<Props> = ({
  name,
  label,
  options,
  disabled,
  isLoading,
  isError,
  helperText,
  required
}) => {
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>();

  return (
    <FormControl fullWidth>
      <Field
        name={name}
        label={label}
        render={() => (
          <TextField
            label={label}
            helperText={helperText}
            placeholder={isLoading ? "Loading..." : (isError && "Error") || label}
            required={required}
            variant="standard"
            disabled={disabled || isLoading || isError}
            defaultValue={((values[name] as string) || "").split("|").filter(v => v.length > 0)}
            select={true}
            SelectProps={{
              multiple: true,
              onChange: (event: SelectChangeEvent<unknown>) => {
                const eventValue = event.target.value as string[];
                const pipedValue = eventValue.join("|");
                setFieldValue(name, pipedValue);
              },
              renderValue: selected => (selected as string[]).sort((v1, v2) => v1.localeCompare(v2)).join(", ")
            }}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={(values[name] as string[]).includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </FormControl>
  );
};

export { PipeSeparatedSelectManyField };
