import { SelectOption } from "@mui/base";
import * as React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { ChangeEvent } from "react";

interface LocalProps {
  options: SelectOption<string>[];
  onChange: (selectedValue: string) => void;
}

type SelectInputProps = LocalProps & Omit<TextFieldProps, "onChange">;

const SelectInput: React.FC<SelectInputProps> = ({ options, onChange, ...other }) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as string;
    onChange(selectedValue);
  };

  return (
    <TextField onChange={handleChange} select={true} {...other}>
      {options.map(option => (
        <option key={option.value} disabled={option.disabled} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  );
};

export { SelectInput };
