import * as React from "react";
import {
  AcceptQuoteResponse,
  JobRequest,
  JobRequestFileCategory,
  JobRequestsService,
  JobRequestStatus
} from "gen/clients/llts";
import { Alert } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useMutation, useQuery } from "react-query";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { RejectQuoteDialog } from "./RejectQuoteDialog/RejectQuoteDialog";

interface Props {
  jobRequest: JobRequest;
  refetchJobRequest: () => void;
}

const QuoteTab: React.FC<Props> = ({ jobRequest, refetchJobRequest }) => {
  const quoteFile = jobRequest.files?.find(f => f.category === JobRequestFileCategory.QUOTE_CONFIRMATION_DOCUMENT);
  const [isRejectDialogOpen, openRejectDialog, closeRejectDialog] = useDialogState();
  const { data, isLoading, error } = useQuery(["getJobRequestFileDownloadUrl", jobRequest.id, quoteFile?.fileKey], {
    enabled: !!quoteFile?.fileKey,
    queryFn: () =>
      JobRequestsService.getJobRequestFileDownloadUrl({
        jobRequestId: jobRequest.id,
        fileKey: encodeURIComponent(quoteFile?.fileKey || ""),
        inline: true
      }),
    cacheTime: 0
  });

  const {
    mutate: acceptQuote,
    isLoading: isAcceptQuoteSubmitting,
    error: acceptQuoteError
  } = useMutation(JobRequestsService.acceptQuote, {
    onSuccess: (response: AcceptQuoteResponse) => {
      if (response.redirectUrl) {
        window.location.href = response.redirectUrl;
      }
    }
  });

  if (!quoteFile) {
    return (
      <Alert severity="warning">
        The quote is currently unavailable. We appreciate your patience. Please check back later.
      </Alert>
    );
  }

  const onAcceptQuoteClick = () => {
    acceptQuote({ jobRequestId: jobRequest.id }, { onSuccess: refetchJobRequest });
  };

  const onRejectSuccess = () => {
    closeRejectDialog();
    refetchJobRequest();
  };

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  if (isLoading || !data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Stack spacing={3}>
        <Box>
          <iframe src={data.downloadUrl} style={{ height: 600, width: "100%" }} title="Quote" />
        </Box>
        {jobRequest.status === JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW && (
          <Stack direction="row" spacing={2} justifyContent="center">
            <LoadingButton
              onClick={onAcceptQuoteClick}
              type="button"
              isLoading={isAcceptQuoteSubmitting}
              variant="contained"
            >
              Accept Quote
            </LoadingButton>
            <Button variant="contained" disabled={isAcceptQuoteSubmitting} onClick={openRejectDialog}>
              Reject Quote
            </Button>
          </Stack>
        )}
      </Stack>
      {isRejectDialogOpen && (
        <RejectQuoteDialog jobRequestId={jobRequest.id} onClose={closeRejectDialog} onSuccess={onRejectSuccess} />
      )}
      {acceptQuoteError && <SnackbarApiError error={acceptQuoteError} />}
    </>
  );
};

export { QuoteTab };
