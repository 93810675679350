import * as React from "react";
import { useTranslation } from "react-i18next";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Typography
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { validateEmailFormat } from "../../../../utils/stringUtils";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";
import { LoadingButton } from "../../../../components/LoadingButton/LoadingButton";
import { usePasswordValidator } from "../../../../hooks/usePasswordValidator/usePasswordValidator";

enum FieldNames {
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  password = "password",
  repeatPassword = "repeatPassword",
  agreementChk = "agreementChk"
}

export interface FormValues {
  [FieldNames.email]: string;
  [FieldNames.firstName]: string;
  [FieldNames.lastName]: string;
  [FieldNames.agreementChk]: boolean;
  [FieldNames.password]: string;
  [FieldNames.repeatPassword]: string;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

interface Props {
  logoUrl?: string;
  onSuccess: (signUpData: UserInfo) => void;
  isLoading: boolean;
  error: unknown;
}

const UserInfoInput: React.FC<Props> = ({ logoUrl, onSuccess, isLoading, error }) => {
  const { t } = useTranslation();
  const { validatePassword } = usePasswordValidator();
  const signIn = React.useCallback(() => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
  }, []);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      onSuccess({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password
      });
    },
    [onSuccess]
  );

  const validate = React.useCallback(
    values => {
      const errors: Record<string, string> = {};
      if (!values[FieldNames.firstName].trim()) {
        errors[FieldNames.firstName] = t("common.validation.required");
      }
      if (!values[FieldNames.lastName].trim()) {
        errors[FieldNames.lastName] = t("common.validation.required");
      }
      const email = values[FieldNames.email];
      if (!email.trim()) {
        errors[FieldNames.email] = t("common.validation.required");
      } else if (!validateEmailFormat(email)) {
        errors[FieldNames.email] = "Invalid email address";
      }
      const password = values[FieldNames.password];
      const passwordError = validatePassword(password);
      if (passwordError) {
        errors[FieldNames.password] = passwordError;
      }
      if (password !== values[FieldNames.repeatPassword]) {
        errors[FieldNames.repeatPassword] = t("signUp.repeatPassword.validationMsg");
      }
      if (!values[FieldNames.agreementChk]) {
        errors[FieldNames.agreementChk] = t("common.validation.required");
      }
      return errors;
    },
    [t, validatePassword]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {error && <SnackbarApiError error={error} />}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {logoUrl && <img src={logoUrl} alt={t("signUp.logo.alt")} />}
        {!logoUrl && (
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        )}
        <Typography component="h1" variant="h5">
          {t("signUp.signUp")}
        </Typography>
        <Formik
          initialValues={{
            [FieldNames.firstName]: "",
            [FieldNames.lastName]: "",
            [FieldNames.email]: "",
            [FieldNames.password]: "",
            [FieldNames.repeatPassword]: "",
            [FieldNames.agreementChk]: false
          }}
          onSubmit={onSubmit}
          validate={validate}
          validateOnMount={false}
        >
          {({ errors, handleChange }) => (
            <Box sx={{ mt: 3 }}>
              <Form noValidate={true} autoComplete="off" autoCorrect="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={FieldNames.firstName}
                      component={TextField}
                      placeholder={t("signUp.firstName")}
                      label={t("signUp.firstName")}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      required
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={FieldNames.lastName}
                      component={TextField}
                      placeholder={t("signUp.lastName")}
                      label={t("signUp.lastName")}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      required
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={FieldNames.email}
                      component={TextField}
                      placeholder={t("signUp.email")}
                      label={t("signUp.email")}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      required
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={FieldNames.password}
                      component={TextField}
                      placeholder={t("signUp.password.label")}
                      label={t("signUp.password.label")}
                      variant="outlined"
                      type="password"
                      fullWidth
                      required
                      disabled={isLoading}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={FieldNames.repeatPassword}
                      component={TextField}
                      placeholder={t("signUp.repeatPassword.label")}
                      label={t("signUp.repeatPassword.label")}
                      variant="outlined"
                      type="password"
                      fullWidth
                      required
                      disabled={isLoading}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error>
                      <FormControlLabel
                        name={FieldNames.agreementChk}
                        control={<Checkbox value="agreement" color="primary" />}
                        onChange={handleChange}
                        label={t("signUp.agreement") as string}
                        sx={{ alignItems: "start" }}
                        componentsProps={{ typography: { fontSize: 12 } }}
                        disabled={isLoading}
                      />
                      {errors[FieldNames.agreementChk] && (
                        <FormHelperText>{errors[FieldNames.agreementChk]}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <LoadingButton isLoading={isLoading} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Continue
                </LoadingButton>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="#" variant="body2" onClick={signIn}>
                      {t("signUp.signInLink")}
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Box>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export { UserInfoInput };
