/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FohQuoteType {
    FOHCOMPREHENSIONPASTDUE = 'FOHCOMPREHENSIONPASTDUE',
    FOHHEALTHCAREPASTDUE = 'FOHHEALTHCAREPASTDUE',
    FOHTECHNICALPASTDUE = 'FOHTECHNICALPASTDUE',
    FOHGOVERNMENTPASTDUE = 'FOHGOVERNMENTPASTDUE',
    FOHTAIWANPASTDUE = 'FOHTAIWANPASTDUE',
    UNASSIGNEDPASTDUE = 'UNASSIGNEDPASTDUE',
    FOHCOMPREHENSIONDUETODAY = 'FOHCOMPREHENSIONDUETODAY',
    FOHHEALTHCAREDUETODAY = 'FOHHEALTHCAREDUETODAY',
    FOHTECHNICALDUETODAY = 'FOHTECHNICALDUETODAY',
    FOHGOVERNMENTDUETODAY = 'FOHGOVERNMENTDUETODAY',
    FOHTAIWANDUETODAY = 'FOHTAIWANDUETODAY',
    UNASSIGNEDDUETODAY = 'UNASSIGNEDDUETODAY',
}