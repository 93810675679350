import * as React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { parseApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";

interface Props {
  apiError: unknown;
}

const ErrorPage: React.FC<Props> = ({ apiError }) => {
  const { t } = useTranslation();
  return (
    <Box margin={4} sx={{ color: "danger" }}>
      <h3>{t("common.errorTitle")}</h3>
      <p>{parseApiErrorMessage(apiError, t("common.unexpectedError"))}</p>
    </Box>
  );
};

export { ErrorPage };
