import * as React from "react";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";

const CoupaErrorPage: React.FC = () => {
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const message = queryParams.get("message");
  if (message) {
    return (
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Alert severity="error">Error. {message}</Alert>
      </Container>
    );
  }
  return (
    <Container maxWidth="md" sx={{ mt: 3 }}>
      <Alert severity="error">
        Oops! Something went wrong. Please try again or contact support if the issue persists.
      </Alert>
    </Container>
  );
};

export { CoupaErrorPage };
