/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MachineTranslationSettings } from '../models/MachineTranslationSettings';
import type { MtTranslateFilesRequest } from '../models/MtTranslateFilesRequest';
import type { MtTranslateFilesResponse } from '../models/MtTranslateFilesResponse';
import type { MtTranslateTextRequest } from '../models/MtTranslateTextRequest';
import type { MtTranslateTextResponse } from '../models/MtTranslateTextResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class MachineTranslationService {

    /**
     * Returns text machine translation settings for an authenticated user
     * @returns MachineTranslationSettings Returns machine translation settings
     * @throws ApiError
     */
    public static getMachineTranslationSettings(): CancelablePromise<MachineTranslationSettings> {
        return __request({
            method: 'GET',
            path: `/machineTranslation/settings`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * translates provided text
     * @returns MtTranslateTextResponse Successful operation
     * @throws ApiError
     */
    public static translateText({
        requestBody,
    }: {
        requestBody?: MtTranslateTextRequest,
    }): CancelablePromise<MtTranslateTextResponse> {
        return __request({
            method: 'POST',
            path: `/machineTranslation/actions/translateText`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad input parameters`,
            },
        });
    }

    /**
     * creates a job request for translating files via MT
     * @returns MtTranslateFilesResponse Successful operation
     * @throws ApiError
     */
    public static translateFiles({
        requestBody,
    }: {
        requestBody?: MtTranslateFilesRequest,
    }): CancelablePromise<MtTranslateFilesResponse> {
        return __request({
            method: 'POST',
            path: `/machineTranslation/actions/translateFiles`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad input parameters`,
            },
        });
    }

}