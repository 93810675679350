import * as React from "react";
import { Alert } from "@mui/material";
import { useQuery } from "react-query";
import { intersect } from "utils/arrayUtils";
import { useUserSession } from "hooks/useUserSession";
import { UserAccountsService } from "gen/clients/llts";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import { CustomOAuthState } from "../../types/CustomOAuthState";

interface Props {
  allowedRoles: string[];
  requiredApplications?: string[];
  children: React.ReactNode;
}

const RestrictedPage: React.FC<Props> = ({ allowedRoles, requiredApplications, children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { username, roles = [] } = useUserSession();
  const { data: userAccount, isLoading: isUserAccountLoading } = useQuery("getUserAccount", {
    enabled: requiredApplications && requiredApplications.length > 0,
    queryFn: UserAccountsService.getUserAccount
  });
  const roleSatisfied = React.useMemo(() => intersect(roles, allowedRoles), [roles, allowedRoles]);
  const applicationAccessSatisfied = React.useMemo(() => {
    if (!userAccount?.applications?.length || !requiredApplications?.length) {
      return true;
    }
    return intersect(userAccount.applications, requiredApplications);
  }, [requiredApplications, userAccount?.applications]);

  React.useEffect(() => {
    if (!username) {
      if (window.location.pathname === "/") {
        history.push("/");
      } else {
        const customOAuthState: CustomOAuthState = {
          initialPagePath: window.location.pathname
        };
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Cognito,
          customState: JSON.stringify(customOAuthState)
        });
      }
    }
  }, [username, history]);

  if (isUserAccountLoading) {
    return <LoadingPage />;
  }

  if (!roleSatisfied) {
    return (
      <Alert sx={{ m: 3 }} color="error">
        {t("restrictedPage.accessRestricted")}
      </Alert>
    );
  }

  if (!applicationAccessSatisfied) {
    return (
      <Alert sx={{ m: 3 }} color="error">
        {t("restrictedPage.applicationAccessDisabled")}
      </Alert>
    );
  }

  return <>{children}</>;
};

export { RestrictedPage };
