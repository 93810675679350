import * as React from "react";
import { useUserSession } from "hooks/useUserSession";
import { useQuery } from "react-query";
import { LanguageGroupScope, UserAccountsService, UserGroup } from "gen/clients/llts";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { LanguageGroupsTable } from "./LanguageGroupsTable";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";

const LanguageGroupsTab: React.FC = () => {
  const { t } = useTranslation();
  const { username, roles } = useUserSession();
  const {
    data: languageGroups,
    isFetching,
    error,
    refetch
  } = useQuery(["listLanguageGroups", username], {
    queryFn: UserAccountsService.listLanguageGroups,
    cacheTime: 0
  });

  const organizationLanguageGroups = React.useMemo(
    () =>
      languageGroups
        ?.filter(group => group.scope === LanguageGroupScope.ORGANIZATION)
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [languageGroups]
  );

  const personalLanguageGroups = React.useMemo(
    () =>
      languageGroups
        ?.filter(group => group.scope === LanguageGroupScope.PERSONAL)
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [languageGroups]
  );

  if (isFetching) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  return (
    <Stack spacing={6} mt={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" mb={1}>
            {t("accountSettings.languageGroups.personalLanguageGroups")}
          </Typography>
          <LanguageGroupsTable
            languageGroups={personalLanguageGroups}
            languageGroupScope={LanguageGroupScope.PERSONAL}
            onChange={refetch}
          />
        </CardContent>
      </Card>
      {(organizationLanguageGroups.length > 0 || roles?.includes(UserGroup.CLIENT)) && (
        <Card>
          <CardContent>
            <Typography variant="h6" mb={1}>
              {t("accountSettings.languageGroups.organizationWideLanguageGroups")}
            </Typography>
            <LanguageGroupsTable
              languageGroups={organizationLanguageGroups}
              languageGroupScope={LanguageGroupScope.ORGANIZATION}
              onChange={refetch}
              viewOnly={!roles?.includes(UserGroup.CLIENT)}
            />
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export { LanguageGroupsTab };
