import { SelectOption } from "@mui/base";

/**
 * Validates email format.
 * @param email
 * @return true if email is in a valid email format.
 */
export function validateEmailFormat(email: string): boolean {
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

/**
 * Validates URL format.
 * @param url
 * @return true if URL is in a valid URL format.
 */
export function validateURLFormat(url: string): boolean {
  // Regular expression pattern to match URL format
  const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
  // Test the URL against the pattern
  return urlPattern.test(url);
}

/**
 * Removes illegal characters from a file name and makes it compliant with most systems.
 * @param fileName
 */
export function sanitizeFileName(fileName: string): string {
  // Define a regular expression to match illegal characters
  const illegalCharactersRegex = /[#%&{}\\<>*?/ $!'":@+`|=]/g;

  // Replace illegal characters with hyphens
  const sanitizedName = fileName.replace(illegalCharactersRegex, "-");

  // Remove leading and trailing spaces, periods, hyphens, and underscores
  const trimmedName = sanitizedName.replace(/^[ .\-_]+|[ .\-_]+$/g, "");

  // Ensure the filename is under 255 characters (are there any XTRF limit?)
  const limitedName = trimmedName.slice(0, 255);

  // // Convert the filename to lowercase
  // const lowercaseName = limitedName.toLowerCase();
  return limitedName;
}

export const SELECT_OPTION_COMPARATOR = (o1: SelectOption<string>, o2: SelectOption<string>): number =>
  (o1.label as string).localeCompare(o2.label as string);

export function formatBytes(bytes: number): string {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
}
