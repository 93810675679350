/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TradeCentricCartItem = {
    quantity: number;
    /**
     * The SKU or product number identifying the product in the supplier catalog.
     */
    supplierid: string;
    /**
     * A unique identifier identifying this particular item in this particular cart. If the buyer decides to submit this cart item as part of a purchase order request, TradeCentric will include this identifier in the purchase order line item.
     */
    supplierauxid: string;
    /**
     * The human-readable product name or title that will be returned to the buyer's procurement system to describe the line item.
     */
    description: string;
    /**
     * The contracted per-unit price of the line item after all discounts and special pricing logic has been applied.
     */
    unitprice: number;
    /**
     * The currency of this line item's unit price. Some buyers' procurement system can support multi-currency carts but this will typically invalidate the cart-level total.
     */
    currency: string;
    /**
     * The supplier's unit of measure code for this line item. TradeCentric will be responsible for mapping this to a code that the buyer's procurement system can accept.
     */
    uom: string;
    /**
     * The supplier's classification code for this line item, often based on the UNSPSC schema (www.unspsc.org). TradeCentric will be responsible for mapping this to a code that the buyer's procurement system can accept.
     */
    classification: string;
    /**
     * The supplier's classification schema.
     */
    classdomain?: string;
    /**
     * The localized ISO language code defining the language used in the item description field.
     */
    language?: string;
    /**
     * Any additional product attributes should be included within the data array. Manufacturer and manufacturer ID are the two most common. Typically TradeCentric will concatenate extra product details like color or style options into the description value based on the buyer's requirements.
     */
    data?: {
        /**
         * The original manufacturer, not the vendor or wholesaler, of this item.
         */
        manufacturer?: string;
        /**
         * The manufacturer-assigned identifier for this product, not the vendor or wholesaler-assigned SKU.
         */
        manufacturer_id?: string;
        /**
         * Expected delivery time of the item in days.
         */
        lead_time?: number;
        /**
         * A public-facing URL of an image of the product.
         */
        image_url?: string;
        /**
         * A flag to indicate whether this line item is priced according to the exact weight of the item that is shipped. The buyer should expect a small difference between the ordered weight and shipped weight.
         */
        is_catchweight?: TradeCentricCartItem.is_catchweight;
    };
}

export namespace TradeCentricCartItem {

    /**
     * A flag to indicate whether this line item is priced according to the exact weight of the item that is shipped. The buyer should expect a small difference between the ordered weight and shipped weight.
     */
    export enum is_catchweight {
        '_0' = 0,
        '_1' = 1,
    }


}
