import * as React from "react";
import { useTranslation } from "react-i18next";
import { Hero } from "../../../../components/Hero/Hero";

interface Props {
  title: string;
  children: React.ReactNode;
  bannerUrl?: string;
}

const FormHeader: React.FC<Props> = ({ title, bannerUrl, children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Hero
        paddingTop={bannerUrl ? 2 : 8}
        overTitleContent={
          <>
            {bannerUrl && (
              <img
                src={bannerUrl}
                alt={t("createProject.formHeader.banner.alt")}
                style={{ width: "100%", marginTop: "2rem", marginBottom: "2rem" }}
              />
            )}
          </>
        }
        title={title}
      >
        {children}
      </Hero>
    </>
  );
};

export { FormHeader };
