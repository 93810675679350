/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum StatProjectType {
    COMPREHENSION = 'COMPREHENSION',
    HEALTHCARE = 'HEALTHCARE',
    INSURANCE = 'INSURANCE',
    TECHNICAL = 'TECHNICAL',
    GOVERNMENT = 'GOVERNMENT',
    TAIWAN = 'TAIWAN',
    TATCOMP = 'TATCOMP',
    TATHEALTH = 'TATHEALTH',
    TATINSURANCE = 'TATINSURANCE',
    TATTECH = 'TATTECH',
    TATGOVERMENT = 'TATGOVERMENT',
    TATTAIWAN = 'TATTAIWAN',
    COMPREHENSIONPASTDUE = 'COMPREHENSIONPASTDUE',
    HEALTHCAREPASTDUE = 'HEALTHCAREPASTDUE',
    INSURANCEPASTDUE = 'INSURANCEPASTDUE',
    TECHNICALPASTDUE = 'TECHNICALPASTDUE',
    GOVERNMENTPASTDUE = 'GOVERNMENTPASTDUE',
    TAIWANPASTDUE = 'TAIWANPASTDUE',
    COMPREHENSIONDUETODAY = 'COMPREHENSIONDUETODAY',
    HEALTHCAREDUETODAY = 'HEALTHCAREDUETODAY',
    INSURANCEDUETODAY = 'INSURANCEDUETODAY',
    TECHNICALDUETODAY = 'TECHNICALDUETODAY',
    GOVERNMENTDUETODAY = 'GOVERNMENTDUETODAY',
    TAIWANDUETODAY = 'TAIWANDUETODAY',
}