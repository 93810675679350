/**
 * Returns true if two arrays have at least one common element.  Otherwise, returns false.
 * @param a
 * @param b
 */
export function intersect<T>(a: T[], b: T[]): boolean {
  return a.filter(element => b.includes(element)).length > 0;
}

/**
 * Given an array of objects and the name of a property, returns a new array that has the same elements,
 * but with duplicates removed.  Two objects are considered duplicates if their values for the given
 * property are equal.
 * @param arr The array of objects to remove duplicates from
 * @param propertyName The name of the property to compare when determining duplicates
 * @returns A new array with no duplicate objects
 */
export function removeDuplicatesByProperty<T>(arr: T[], propertyName: string): T[] {
  const uniqueValues = new Set<T>();
  return arr.filter(element => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!uniqueValues.has(element[propertyName])) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      uniqueValues.add(element[propertyName]);
      return true;
    }
    return false;
  });
}
