/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { BillingAccount } from './BillingAccount';

export type PaymentPreferences = {
    paymentType: PaymentPreferences.paymentType;
    billingAccount?: BillingAccount;
    billingContact?: Address;
}

export namespace PaymentPreferences {

    export enum paymentType {
        CREDIT_CARD = 'CREDIT_CARD',
        BILL_TO_ACCOUNT = 'BILL_TO_ACCOUNT',
        INVOICE_DIRECTLY = 'INVOICE_DIRECTLY',
    }


}
