import * as React from "react";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import TranslateIcon from "@mui/icons-material/Translate";
import DescriptionIcon from "@mui/icons-material/Description";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { TextTranslationTab } from "./TextTranslationTab/TextTranslationTab";
import { FileTranslationTab } from "./FileTranslationTab/FileTranslationTab";
import { MachineTranslationService } from "../../gen/clients/llts";
import { LoadingPage } from "../../components/LoadingPage/LoadingPage";
import { ErrorPage } from "../../components/ErrorPage/ErrorPage";

export enum MachineTranslationTabId {
  textTranslation = "textTranslation",
  fileTranslation = "fileTranslation"
}

interface Props {
  selectedTabId?: MachineTranslationTabId;
}

const MachineTranslationPage: React.FC<Props> = ({ selectedTabId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    data: mtSettings,
    isLoading: areMtSettingsLoading,
    error: mtSettingsError
  } = useQuery(["getMtSettings"], {
    queryFn: MachineTranslationService.getMachineTranslationSettings
  });

  const breadcrumbs = React.useMemo(
    () => [
      {
        label: t("crumbText.home"),
        path: "/",
        active: false
      },
      {
        label: t("machineTranslation.title"),
        path: "/machineTranslation",
        active: true
      }
    ],
    [t]
  );

  if (areMtSettingsLoading) {
    return <LoadingPage />;
  }

  if (mtSettingsError) {
    return <ErrorPage apiError={mtSettingsError} />;
  }

  const tabId =
    selectedTabId ||
    (mtSettings?.disableTextTranslation
      ? MachineTranslationTabId.fileTranslation
      : MachineTranslationTabId.textTranslation);

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Box>
        <Tabs
          variant={isSmallScreen ? "fullWidth" : "standard"}
          value={tabId}
          onChange={(event, value) => history.push(`/machineTranslation/${value}`)}
        >
          {!mtSettings?.disableTextTranslation && (
            <Tab
              value={MachineTranslationTabId.textTranslation}
              label={t("machineTranslation.tabs.textTranslation")}
              icon={
                <Icon>
                  <TranslateIcon />
                </Icon>
              }
              iconPosition="start"
              sx={{ minHeight: 0 }}
              wrapped={isSmallScreen}
            />
          )}
          {!mtSettings?.disableFileTranslation && (
            <Tab
              value={MachineTranslationTabId.fileTranslation}
              label={t("machineTranslation.tabs.fileTranslation")}
              icon={
                <Icon>
                  <DescriptionIcon />
                </Icon>
              }
              iconPosition="start"
              sx={{ minHeight: 0 }}
              wrapped={isSmallScreen}
            />
          )}
        </Tabs>
      </Box>
      {tabId === MachineTranslationTabId.textTranslation && !mtSettings?.disableTextTranslation && (
        <TextTranslationTab />
      )}
      {tabId === MachineTranslationTabId.fileTranslation && !mtSettings?.disableFileTranslation && (
        <FileTranslationTab />
      )}
    </Container>
  );
};

export { MachineTranslationPage };
