/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TradeCentricCartItem } from './TradeCentricCartItem';

export type TradeCentricReturnCart = {
    body: {
        /**
         * The sum of all item row totals plus the tax and shipping estimates, if present.
         */
        total: number;
        /**
         * The ISO currency code for the cart.
         */
        currency: string;
        /**
         * Any additional cart-level attributes should be included within the data array. Tax, tax description, shipping, and shipping description are the most common. Note that most buyer-side eProcurement systems cannot receive any cart-level attributes beyond total, currency, tax, and shipping.
         */
        data?: {
            /**
             * Defines whether the supplier's storefront can accept an edit-cart request against this cart object.
             */
            edit_mode?: TradeCentricReturnCart.edit_mode;
            /**
             * An estimate of the shipping charge that would be calculated against this cart if it were to be converted to a purchase order.
             */
            shipping?: number;
            /**
             * A human-readable description of the estimated shipping charge and/or a code identifying it.
             */
            shipping_description?: string;
            /**
             * An estimate of the tax that would be calculated against this cart if it were to be converted to a purchase order.
             */
            tax?: number;
            /**
             * A human-readable description of the estimated tax and/or a code identifying it.
             */
            tax_description?: string;
        };
        items: Array<TradeCentricCartItem>;
    };
}

export namespace TradeCentricReturnCart {

    /**
     * Defines whether the supplier's storefront can accept an edit-cart request against this cart object.
     */
    export enum edit_mode {
        '_1' = 1,
        '_0' = 0,
    }


}
