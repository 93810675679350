import * as React from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import Container from "@mui/material/Container";
import { useEffect } from "react";
import { Alert, Link } from "@mui/material";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { UserAccountsService } from "../../gen/clients/llts";
import { LoadingPage } from "../../components/LoadingPage/LoadingPage";
import { parseApiErrorMessage } from "../../components/ApiErrorMessage/ApiErrorMessage";

const EmailConfirmationPage: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const email = queryParams.get("email");
  const code = queryParams.get("verificationCode");

  const { mutate: confirmEmail, isSuccess, isLoading, error } = useMutation(UserAccountsService.confirmEmail);

  const signIn = React.useCallback(() => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
  }, []);

  useEffect(() => {
    if (email && code) {
      confirmEmail({ requestBody: { email, code } });
    }
  }, [confirmEmail, email, code]);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!email || !code) {
    return <Container maxWidth="lg">{t("emailConfirmation.invalidUrl")}</Container>;
  }

  if (isSuccess) {
    return (
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Alert severity="success">
          {t("emailConfirmation.emailConfirmed")}

          <Link href="#" onClick={signIn} sx={{ ml: 1 }}>
            {t("emailConfirmation.signIn")}
          </Link>
        </Alert>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ mt: 3 }}>
        <Alert severity="error">{parseApiErrorMessage(error, t("common.unexpectedError"))}</Alert>
      </Container>
    );
  }

  return <div />;
};

export { EmailConfirmationPage };
