import * as React from "react";
import { Field, useFormikContext } from "formik";
import { AutocompleteRenderInputParams, Autocomplete } from "formik-mui";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

type Option = { label: string; value: string };

interface Props {
  name: string;
  label: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  helperText?: string;
  variant?: "outlined" | "filled" | "standard";
}

const AutocompleteField: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  disabled,
  isLoading,
  isError,
  helperText,
  variant = "standard"
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [validationError, setValidationError] = React.useState();

  return (
    <Field
      name={name}
      component={Autocomplete}
      options={options}
      isOptionEqualToValue={(option: Option, value: string) => option.value === value}
      disabled={disabled || isLoading || isError}
      onChange={(event: React.SyntheticEvent, value: Option, reason: string) => {
        if (reason === "selectOption") {
          setFieldValue(name, value.value);
        }
      }}
      validate={(value: string) => {
        const valid = required ? value : true;
        if (!valid) {
          setValidationError(t("common.validation.required"));
          return t("common.validation.required");
        }
        setValidationError(undefined);
        return undefined;
      }}
      freeSolo={true}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          // We have to manually set the corresponding fields on the input component
          name={name}
          error={!!(isError || validationError)}
          helperText={helperText || validationError}
          label={label}
          variant={variant}
          required={required}
          onChange={event => setFieldValue(name, event.target.value)}
        />
      )}
    />
  );
};

export { AutocompleteField };
