/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useMutation } from "react-query";
import {
  UserContactSectionField,
  UserContactSectionFormValues
} from "../../CreateProjectPage/components/UserContactSection/UserContactSection";
import {
  ContentInputMethod,
  FilesSectionFieldNames,
  FilesSectionFormValues
} from "../../CreateProjectPage/components/FilesSection/FilesSection";
import {
  ProjectInfoFieldName,
  ProjectInfoFormValues
} from "../../CreateProjectPage/components/ProjectInfoSection/ProjectInfoSection";
import {
  CreateJobRequestPayload,
  CustomFieldDefinition,
  CustomFieldValue,
  FileLink,
  JobRequestFile,
  JobRequestFileCategory,
  JobRequestFileLink,
  JobRequestLanguage,
  JobRequestOrigination,
  JobRequestsService,
  JobRequestType,
  UnauthenticatedCreateJobRequestPayload,
  UtilsService
} from "../../../gen/clients/llts";
import { useS3FileUpload } from "../../../hooks/useS3FileUpload/useS3FileUpload";
import { useCountryOptions } from "../../../hooks/useCountryOptions/useCountryOptions";
import { useProvinceOptions } from "../../../hooks/useProvinceOptions/useProvinceOptions";
import { sanitizeFileName } from "../../../utils/stringUtils";
import { createFileKey } from "../../../utils/s3Utils";
import { useUserSession } from "../../../hooks/useUserSession";
import { sanitizeFieldName } from "../../../components/formikFields/formikUtils";

type Values = UserContactSectionFormValues & FilesSectionFormValues & ProjectInfoFormValues;

interface UseSubmitJobRequestParams {
  origination: JobRequestOrigination;
  formValues?: Values;
  requestType?: JobRequestType;
  projectCustomFieldDefinitions?: CustomFieldDefinition[];
  emailVerificationCode?: string;
  externalReferenceId?: string;
}

interface UseSubmitJobRequestResponse {
  submitJobRequest: () => void;
  isSubmitting: boolean;
  error?: unknown;
  jobRequestCreated?: boolean;
  jobRequestId?: string;
  progress: number;
}

export const useSubmitJobRequest = ({
  origination,
  formValues,
  requestType,
  projectCustomFieldDefinitions,
  emailVerificationCode,
  externalReferenceId
}: UseSubmitJobRequestParams): UseSubmitJobRequestResponse => {
  const { username } = useUserSession();
  const [isSubmitting, setSubmitting] = React.useState(false);

  const {
    mutate: createPresignedPost,
    data: presignedPost,
    error: createPresignedPostError
  } = useMutation(UtilsService.createTempFileUploadPresignedPost);

  const {
    invoke: uploadFiles,
    isSuccess: areFilesUploaded,
    error: filesUploadError,
    uploadProgress
  } = useS3FileUpload();

  const {
    data: jobRequest,
    mutate: createJobRequest,
    isLoading: isCreatingJobRequest,
    error: createJobRequestError,
    isSuccess: jobRequestCreated
  } = useMutation(JobRequestsService.createJobRequest);

  const {
    data: unauthenticatedJobRequest,
    mutate: unauthenticatedCreateJobRequest,
    isLoading: isUnauthenticatedCreatingJobRequest,
    error: unauthenticatedCreateJobRequestError,
    isSuccess: unauthenticatedJobRequestCreated
  } = useMutation(JobRequestsService.unauthenticatedCreateJobRequest);

  const error =
    createPresignedPostError || filesUploadError || createJobRequestError || unauthenticatedCreateJobRequestError;

  const { countryOptions } = useCountryOptions();
  const { provinceOptions: contactProvinceOptions } = useProvinceOptions(
    formValues && formValues[UserContactSectionField.country] ? +formValues[UserContactSectionField.country] : undefined
  );

  // --- Upload files when presigned post is created ---
  React.useEffect(() => {
    if (!presignedPost || !formValues) {
      return;
    }

    const contentInputMethod = formValues[FilesSectionFieldNames.inputMethod];
    if (contentInputMethod === ContentInputMethod.FILELINKS) {
      return;
    }

    const files: File[] = [];
    switch (contentInputMethod) {
      case ContentInputMethod.FILES: {
        const documentFiles: File[] | undefined = formValues[FilesSectionFieldNames.files];
        const referenceFiles: File[] | undefined = formValues[FilesSectionFieldNames.referenceFiles];
        files.push(...(documentFiles || []), ...(referenceFiles || []));
        break;
      }
      case ContentInputMethod.TEXT: {
        const fileName = `${sanitizeFileName(formValues[ProjectInfoFieldName.projectName] as string)}.txt`;
        files.push(
          new File([(formValues[FilesSectionFieldNames.textInput] as string) || ""], fileName, { type: "text/plain" })
        );
        break;
      }
      default:
        throw new Error(`Invalid input type ${contentInputMethod}`);
    }
    uploadFiles({ files, presignedPost });
  }, [presignedPost]);

  // Submit a request to create Job Request once files are uploaded
  React.useEffect(() => {
    if (!formValues || !presignedPost) {
      return;
    }

    const files: JobRequestFile[] = [];
    const contentInputMethod = formValues[FilesSectionFieldNames.inputMethod];
    if (contentInputMethod !== ContentInputMethod.FILELINKS) {
      if (!areFilesUploaded) {
        return;
      }

      switch (contentInputMethod) {
        case ContentInputMethod.FILES: {
          const documentFiles: JobRequestFile[] | undefined = formValues[FilesSectionFieldNames.files]?.map(file => ({
            fileName: file.name,
            category: JobRequestFileCategory.SOURCE_DOCUMENT,
            fileKey: createFileKey(presignedPost, file.name)
          }));
          const referenceFiles: JobRequestFile[] | undefined = formValues[FilesSectionFieldNames.referenceFiles]?.map(
            file => ({
              fileName: file.name,
              category: JobRequestFileCategory.REFERENCE,
              fileKey: createFileKey(presignedPost, file.name)
            })
          );
          files.push(...(documentFiles || []), ...(referenceFiles || []));
          break;
        }
        case ContentInputMethod.TEXT: {
          const fileName = `${sanitizeFileName(formValues[ProjectInfoFieldName.projectName] as string)}.txt`;
          files.push({
            fileName,
            category: JobRequestFileCategory.SOURCE_DOCUMENT,
            fileKey: createFileKey(presignedPost, fileName)
          });
          break;
        }
        default:
          throw new Error(`Invalid input type ${contentInputMethod}`);
      }
    }

    const fileLinks: JobRequestFileLink[] | undefined =
      formValues[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILELINKS
        ? (formValues[FilesSectionFieldNames.fileLinks] as FileLink[]).map(link => ({
            url: link.url,
            name: link.name,
            category: JobRequestFileCategory.SOURCE_DOCUMENT
          }))
        : undefined;

    const sourceLanguageSelectedOption = formValues[ProjectInfoFieldName.sourceLanguage];
    if (!sourceLanguageSelectedOption) {
      throw new Error("Source language is not selected");
    }
    const sourceLanguage: JobRequestLanguage = {
      id: +sourceLanguageSelectedOption.value,
      name: sourceLanguageSelectedOption.label as string
    };
    const targetLanguages: JobRequestLanguage[] = formValues[ProjectInfoFieldName.targetLanguages].map(l => ({
      id: +l.value,
      name: l.label as string
    }));

    const customFieldValues: CustomFieldValue[] | undefined = projectCustomFieldDefinitions?.map(fieldDefinition => ({
      fieldName: fieldDefinition.name,
      mapping: fieldDefinition.mapping,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: formValues[sanitizeFieldName(fieldDefinition.name)]
    }));

    const createJobRequestPayload: CreateJobRequestPayload = {
      origination,
      name: formValues[ProjectInfoFieldName.projectName] || "",
      requestType,
      sourceLanguage,
      targetLanguages,
      files,
      fileLinks,
      customFields: customFieldValues,
      notes: formValues[ProjectInfoFieldName.notes],
      externalReferenceId
    };

    // If the user is signed-in then use an endpoint for authenticated users to create a job request
    if (username) {
      createJobRequest({
        requestBody: createJobRequestPayload
      });
    } else {
      // For unauthenticated users, use API endpoint for unauthenticated users
      if (!emailVerificationCode) {
        throw new Error("Email verification code is required");
      }
      const contactCountry = countryOptions.find(c => c.value === +formValues[UserContactSectionField.country]);
      const contactProvince = contactProvinceOptions.find(
        p => p.value === +formValues[UserContactSectionField.province]
      );
      const unauthenticatedCreateJobRequestPayload: UnauthenticatedCreateJobRequestPayload = {
        ...createJobRequestPayload,
        companyName: formValues[UserContactSectionField.companyName],
        requestorEmail: formValues[UserContactSectionField.email],
        requestorFirstName: formValues[UserContactSectionField.firstName],
        requestorLastName: formValues[UserContactSectionField.lastName],
        contactAddress: {
          address1: formValues[UserContactSectionField.address1] || undefined,
          address2: formValues[UserContactSectionField.address2] || undefined,
          city: formValues[UserContactSectionField.city] || undefined,
          zipCode: formValues[UserContactSectionField.zip] || undefined,
          phoneNumber: formValues[UserContactSectionField.phone] || undefined,
          email: formValues[UserContactSectionField.email] || undefined,
          province: formValues[UserContactSectionField.province]
            ? {
                id: +formValues[UserContactSectionField.province] || 0,
                name: contactProvince?.label as string
              }
            : undefined,
          country: formValues[UserContactSectionField.country]
            ? {
                id: +formValues[UserContactSectionField.country] || 0,
                name: contactCountry?.label as string
              }
            : undefined
        },
        emailVerificationCode
      };
      unauthenticatedCreateJobRequest({
        requestBody: unauthenticatedCreateJobRequestPayload
      });
    }
  }, [areFilesUploaded, requestType, presignedPost, emailVerificationCode, projectCustomFieldDefinitions]);

  const submitJobRequest = React.useCallback(() => {
    setSubmitting(true);
    createPresignedPost();
  }, []);

  const success = jobRequestCreated || unauthenticatedJobRequestCreated;
  React.useEffect(() => {
    if (success || error) {
      setSubmitting(false);
    }
  }, [success, error]);

  const progress = React.useMemo(() => {
    if (jobRequestCreated || unauthenticatedJobRequestCreated) {
      return 100;
    }
    if (isCreatingJobRequest || isUnauthenticatedCreatingJobRequest) {
      return 95;
    }
    return uploadProgress * 0.9;
  }, [
    uploadProgress,
    jobRequestCreated,
    unauthenticatedJobRequestCreated,
    isCreatingJobRequest,
    isUnauthenticatedCreatingJobRequest
  ]);

  return {
    submitJobRequest,
    isSubmitting,
    error,
    jobRequestCreated: success,
    jobRequestId:
      jobRequestCreated || unauthenticatedJobRequestCreated
        ? jobRequest?.id || unauthenticatedJobRequest?.id
        : undefined,
    progress
  };
};
