/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TradeCentricInitializeLogInResponse = {
    username: string;
    token: string;
    action: TradeCentricInitializeLogInResponse.action;
    punchOutSessionId: string;
}

export namespace TradeCentricInitializeLogInResponse {

    export enum action {
        CREATE = 'create',
        EDIT = 'edit',
        INSPECT = 'inspect',
    }


}
