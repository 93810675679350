/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JobRequestStatus {
    CREATED = 'CREATED',
    SCREENING = 'SCREENING',
    REJECTED_BY_SCREENING = 'REJECTED_BY_SCREENING',
    ANALYZING = 'ANALYZING',
    TRANSLATING = 'TRANSLATING',
    READY_FOR_INTERNAL_REVIEW = 'READY_FOR_INTERNAL_REVIEW',
    QUOTE_READY_FOR_CLIENT_REVIEW = 'QUOTE_READY_FOR_CLIENT_REVIEW',
    QUOTE_REJECTED = 'QUOTE_REJECTED',
    QUOTE_ACCEPTED = 'QUOTE_ACCEPTED',
    QUOTE_EXPIRED = 'QUOTE_EXPIRED',
    SUBMITTING_TO_XTRF = 'SUBMITTING_TO_XTRF',
    SUBMITTED_TO_XTRF = 'SUBMITTED_TO_XTRF',
    COMPLETED = 'COMPLETED',
    ARCHIVED = 'ARCHIVED',
    FAILED = 'FAILED',
    CANCELLING = 'CANCELLING',
    CANCELLED = 'CANCELLED',
}