import { ThemeOptions } from "@mui/material/styles/createTheme";

export const escsMuiThemeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
          margin: 0,
          fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,
  "Noto Sans",sans-serif,"Apple Color Emoji",
  "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;`
        },
        body: {
          height: "100%",
          margin: 0,
          fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,
  "Noto Sans",sans-serif,"Apple Color Emoji",
  "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;`
        },
        "#root": {
          display: "flex",
          flexDirection: "column",
          minHeight: "100%"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#005388"
    },
    secondary: {
      main: "#fa9b0e"
    }
  }
};
