import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { JobRequestsService } from "gen/clients/llts";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { Form, Formik } from "formik";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";

interface Props {
  jobRequestId: string;
  oldName?: string;
  onClose: () => void;
  onSuccess: () => void;
}

enum FieldNames {
  name = "name"
}

interface FormValues {
  [FieldNames.name]: string;
}

const RenameJobRequestDialog: React.FC<Props> = ({ jobRequestId, oldName, onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { mutateAsync, error } = useMutation(JobRequestsService.renameJobRequest);

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutateAsync(
        {
          jobRequestId,
          requestBody: {
            newName: values[FieldNames.name]
          }
        },
        { onSuccess }
      );
    },
    [jobRequestId, mutateAsync, onSuccess]
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>{t("renameJobRequestDialog.title")}</DialogTitle>
      <Formik
        initialValues={{
          [FieldNames.name]: oldName || ""
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate={true} autoComplete="off">
            <DialogContent>
              <Stack spacing={1} alignItems="center">
                {!!error && <ApiErrorMessage apiError={error} />}
                <TextInputField
                  name={FieldNames.name}
                  label={t("renameJobRequestDialog.name")}
                  required={true}
                  maxLength={250}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <LoadingButton isLoading={isSubmitting}>{t("renameJobRequestDialog.renameBtn")}</LoadingButton>
              <Button onClick={onClose}>{t("common.cancel")}</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export { RenameJobRequestDialog };
