/* eslint-disable no-nested-ternary */
import React, { FC } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";

import { FohPendingReviewQuoteStat, FohProjectStat, ProjectStat, QaProjectStat } from "gen/clients/llts";
import { useTranslation } from "react-i18next";

export enum DashboardTableType {
  TAT,
  STAT,
  QA,
  FOH,
  FOHPENDING
}

export type DashboardType = {
  data: ProjectStat[] | undefined | QaProjectStat[] | FohProjectStat[] | FohPendingReviewQuoteStat[];
  isLoading: boolean;
  title: string;
  link: string;
  tableType: DashboardTableType;
};

const DashBoardComponent: FC<DashboardType> = props => {
  const { title, data, isLoading, link, tableType = DashboardTableType.STAT } = props;

  const { t } = useTranslation();

  const renderTableHeads = (type: DashboardTableType) => {
    if (type === DashboardTableType.TAT) {
      return (
        <>
          <TableCell>{t("DASHBOARD.projectManager")}</TableCell>
          <TableCell align="right">{t("DASHBOARD.standard")}</TableCell>
          <TableCell align="right">{t("DASHBOARD.rush")}</TableCell>
          <TableCell align="right">{t("DASHBOARD.totals")}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.FOH) {
      return (
        <>
          <TableCell>{t("DASHBOARD.projectCoordinator")}</TableCell>
          <TableCell>{t("DASHBOARD.quotes")}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.FOHPENDING) {
      return (
        <>
          <TableCell>{t("DASHBOARD.quoteReviewStatus")}</TableCell>
          <TableCell>{t("DASHBOARD.quotes")}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.STAT) {
      return (
        <>
          <TableCell>{t("DASHBOARD.projectManager")}</TableCell>
          <TableCell align="right">{t("DASHBOARD.project")}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.QA) {
      return (
        <>
          <TableCell>{t("DASHBOARD.qaSpecialist")}</TableCell>
          <TableCell align="right">{t("DASHBOARD.assignedJobs")}</TableCell>
        </>
      );
    }
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTableData = (type: DashboardTableType, row: any) => {
    const isUnassignedReport = !row.projectCoordinatorId && !row.projectCoordinator;
    const needsReassignment = !row.projectCoordinator;

    if (type === DashboardTableType.TAT) {
      return (
        <>
          <TableCell component="th" scope="row">
            <Link to={`/${link}/${row.projectManager}/${row.projectManagerId}`}>{row.projectManager}</Link>
          </TableCell>
          <TableCell align="right">{row.standard}</TableCell>
          <TableCell align="right">{row.rush}</TableCell>
          <TableCell align="right">{row.totals}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.FOH) {
      return (
        <>
          <TableCell component="th" scope="row">
            <Link to={`/${link}/${row.projectCoordinator ?? "Unassigned"}/${row.projectCoordinatorId}`}>
              {isUnassignedReport
                ? t("DASHBOARD.unassigned")
                : needsReassignment
                  ? t("DASHBOARD.needsReassignment")
                  : row.projectCoordinator}
            </Link>
          </TableCell>
          <TableCell>{row.quotes}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.FOHPENDING) {
      return (
        <>
          <TableCell component="th" scope="row">
            {row.quoteReviewStatus}
          </TableCell>
          <TableCell>{row.quotes}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.STAT) {
      return (
        <>
          <TableCell component="th" scope="row">
            <Link to={`/${link}/${row.projectManager}/${row.projectManagerId}`}>{row.projectManager}</Link>
          </TableCell>
          <TableCell align="right">{row.projectCount}</TableCell>
        </>
      );
    }
    if (type === DashboardTableType.QA) {
      return (
        <>
          <TableCell component="th" scope="row">
            <Link to={`/${link}/${row.qaSpecialist}/${row.contactPersonId}`}>{row.qaSpecialist}</Link>
          </TableCell>
          <TableCell align="right">{row.assignedJobs}</TableCell>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Grid item xs={4}>
        <Card sx={{ backgroundColor: "#f5f5f5" }}>
          <CardHeader title={title} />
          <CardContent>
            {isLoading && <CircularProgress />}
            {data && (
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>{renderTableHeads(tableType)}</TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={`${DashboardTableType[tableType]}${index}`}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0
                        }
                      }}
                    >
                      {renderTableData(tableType, row)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default DashBoardComponent;
