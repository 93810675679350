import { Formik, Form } from "formik";
import * as React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { JobRequestAutoQuote, JobRequestLanguage, JobRequestStatus, JobRequestsService } from "gen/clients/llts";
import { useQuery } from "react-query";
import { useCallback, useMemo, useState } from "react";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import Stack from "@mui/material/Stack";
import { formatDate } from "../../utils/dateUtils";
import TermsAndConditionsDialog from "./components/TermsAndConditionsDialog/TermsAndConditionsDialog";
import { RejectAutoQuoteDialog } from "./components/RejectAutoQuoteDialog/RejectAutoQuoteDialog";
import AutoQuoteAcceptanceUpdated from "./components/AutoQuoteAcceptanceUpdated/AutoQuoteAcceptanceUpdated";
import { CheckboxField } from "../../components/formikFields/CheckboxField/CheckboxField";
import { formatPrice } from "../../utils/numberUtils";
import AutoQuoteAcceptanceExpired from "./components/AutoQuoteAcceptanceExpired/AutoQuoteAcceptanceExpired";
import HomeCrumb from "../../components/Breadcrumb/HomeCrumb";
import { PaymentDialog } from "./components/PaymentDialog/PaymentDialog";

interface PathParams {
  jobRequestId: string;
}

enum FieldNames {
  agreementChk = "agreementChk"
}

const AutoQuotePage: React.FC = () => {
  const { t } = useTranslation();
  const { jobRequestId } = useParams<PathParams>();

  const [acceptanceStatus, setAcceptanceStatus] = useState<JobRequestAutoQuote.acceptanceStatus>();
  const [isTermsDialogOpen, openTermsDialog, closeTermsDialog] = useDialogState();
  const [isRejectDialogOpen, openRejectDialog, closeRejectDialog] = useDialogState();
  const [isPaymentDialogOpen, openPaymentDialog, closePaymentDialog] = useDialogState();

  const {
    data: summary,
    isLoading,
    error: summaryError
  } = useQuery(["getAutoQuote", jobRequestId], {
    queryFn: () => JobRequestsService.getAutoQuote({ jobRequestId })
  });

  const requestAutoQuote = useMemo(() => summary?.autoQuote, [summary]);

  const autoQuoteStatus = useMemo(() => requestAutoQuote?.acceptanceStatus, [requestAutoQuote]);

  const allowClientReview = useMemo(
    () => summary?.status === JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW,
    [summary]
  );

  const acceptanceDeadlineExpired = useMemo(
    () =>
      summary?.status === JobRequestStatus.QUOTE_EXPIRED ||
      (requestAutoQuote ? new Date(requestAutoQuote.acceptanceDeadline) < new Date() : false),
    [requestAutoQuote, summary?.status]
  );

  const translationPairs = useMemo(() => {
    const sourceLanguage = summary?.sourceLanguage;
    if (!summary || !sourceLanguage || !summary.targetLanguages) {
      return [];
    }

    const pairs: { source: JobRequestLanguage; target: JobRequestLanguage }[] = [];
    summary.targetLanguages
      .sort((l1, l2) => l1.name.localeCompare(l2.name))
      .forEach(l => {
        pairs.push({
          source: sourceLanguage,
          target: l
        });
      });
    return pairs;
  }, [summary]);

  const validate = useCallback(values => {
    const errors: { [key: string]: string } = {};
    if (!values[FieldNames.agreementChk]) {
      errors.agreementChk =
        "Please check the box to acknowledge the Terms of Service and Privacy Policy before accepting the quote.";
    }
    return errors;
  }, []);

  const onSubmit = useCallback(
    (_, formikHelpers) => {
      openPaymentDialog();
      formikHelpers.setSubmitting(false);
    },
    [openPaymentDialog]
  );

  const pagesPerLanguage = useMemo(() => {
    if (!requestAutoQuote || !requestAutoQuote?.totalPages) {
      return 0;
    }
    if (!summary?.targetLanguages) {
      return 0;
    }
    return requestAutoQuote?.totalPages / summary?.targetLanguages.length;
  }, [requestAutoQuote, summary?.targetLanguages]);

  const onPaymentSuccess = () => {
    closePaymentDialog();
    setAcceptanceStatus(JobRequestAutoQuote.acceptanceStatus.ACCEPTED);
  };

  const onRejectSuccess = () => {
    closeRejectDialog();
    setAcceptanceStatus(JobRequestAutoQuote.acceptanceStatus.REJECTED);
  };

  if (summaryError) {
    return <ErrorPage apiError={summaryError} />;
  }

  if (isLoading || !summary) {
    return <LoadingPage />;
  }

  if (acceptanceStatus) {
    return <AutoQuoteAcceptanceUpdated status={acceptanceStatus} />;
  }

  if (acceptanceDeadlineExpired) {
    return <AutoQuoteAcceptanceExpired />;
  }

  return (
    <>
      {summary?.status && (
        <>
          <HomeCrumb />
          <Container>
            <Card elevation={3} sx={{ p: 2 }}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {t("autoQuote.ballparkerEstimate", { name: summary?.name || "N/A" })}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Typography variant="body1">
                        <strong>{t("autoQuote.date")}:</strong> {formatDate(requestAutoQuote?.creationDate)}
                      </Typography>
                      <Typography variant="body1">
                        <strong> {t("autoQuote.sourceLanguage")}:</strong> {summary?.sourceLanguage?.name}
                      </Typography>
                      <Typography variant="body1">
                        <strong> {t("autoQuote.languages")}:</strong>{" "}
                        {summary?.targetLanguages
                          ?.sort((l1, l2) => l1.name.localeCompare(l2.name))
                          .map((l, index) => (
                            <>
                              <span style={{ whiteSpace: "nowrap" }}>
                                {l.name}
                                {(summary.targetLanguages?.length || 0) > 1 &&
                                index < (summary.targetLanguages?.length || 0) - 1
                                  ? ","
                                  : ""}
                              </span>{" "}
                            </>
                          ))}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      {requestAutoQuote?.acceptanceStatus && (
                        <Typography variant="body1">
                          <strong>{t("autoQuote.statusLabel")}:</strong>{" "}
                          {t(`autoQuote.status.${requestAutoQuote.acceptanceStatus}`)}
                        </Typography>
                      )}
                      <Typography variant="body1">
                        <strong>{t("autoQuote.referenceId")}:</strong> {jobRequestId}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Typography variant="h6" style={{ marginTop: "16px" }}>
                  {t("autoQuote.projectSummary")}:
                </Typography>
                {translationPairs.map((pair, index) => (
                  <Accordion key={index} style={{ marginTop: "16px" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography>
                            {pair.source.name} to {pair.target.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            {t("autoQuote.languageTotal", {
                              price: formatPrice((requestAutoQuote?.pricePerPage || 0) * pagesPerLanguage)
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <Grid container spacing={2} style={{ padding: "16px" }}>
                        <Grid item xs={5}>
                          <Typography>{t("autoQuote.professionalServices")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{t("autoQuote.units")}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>{t("autoQuote.cost")}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography>{t("autoQuote.translationAndEditing")}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography>{t("autoQuote.pageCount", { pageCount: pagesPerLanguage })}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography>
                            {formatPrice((requestAutoQuote?.pricePerPage || 0) * pagesPerLanguage)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <Grid container justifyContent="flex-end" style={{ marginTop: "16px" }}>
                  <Grid item>
                    <Typography variant="h6">
                      {t("autoQuote.totalProjectCost", { price: formatPrice(requestAutoQuote?.price || 0) })}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {!autoQuoteStatus && allowClientReview && (
              <Formik
                initialValues={{
                  [FieldNames.agreementChk]: false
                }}
                validate={validate}
                onSubmit={onSubmit}
              >
                <Form>
                  <Grid container spacing={2} mt={4}>
                    <Grid item xs={12}>
                      <CheckboxField
                        name={FieldNames.agreementChk}
                        Label={{
                          label: t("autoQuote.agreement") as string,
                          componentsProps: {
                            typography: { fontSize: 12 }
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                      <Button sx={{ mr: 1 }} type="submit" variant="contained" color="primary">
                        {t("autoQuote.acceptQuote")}
                      </Button>
                      <Button onClick={openRejectDialog}>{t("autoQuote.rejectQuote")}</Button>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            )}
            {/* Contact Information */}
            <Grid container justifyContent="center" mt={6} mb={2}>
              <Grid item>
                <Typography variant="body2" align="center">
                  <strong>{t("autoQuote.lls")}</strong>
                </Typography>
                <Typography variant="body2" align="center">
                  {t("autoQuote.llsAddress")}
                </Typography>
                <Typography variant="body2" align="center">
                  <Link href="#" onClick={openTermsDialog}>
                    {t("autoQuote.termsAndConditionsLink")}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            {isPaymentDialogOpen && (
              <PaymentDialog jobRequestId={jobRequestId} onClose={closePaymentDialog} onSuccess={onPaymentSuccess} />
            )}
            {isTermsDialogOpen && <TermsAndConditionsDialog onClose={closeTermsDialog} />}
            {isRejectDialogOpen && (
              <RejectAutoQuoteDialog
                jobRequestId={jobRequestId}
                onClose={closeRejectDialog}
                onSuccess={onRejectSuccess}
              />
            )}
          </Container>
        </>
      )}
    </>
  );
};

export { AutoQuotePage };
