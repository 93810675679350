/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum JobRequestFileCategory {
    SOURCE_DOCUMENT = 'SOURCE_DOCUMENT',
    REFERENCE = 'REFERENCE',
    TRANSLATED_DOCUMENT = 'TRANSLATED_DOCUMENT',
    QUOTE_CONFIRMATION_DOCUMENT = 'QUOTE_CONFIRMATION_DOCUMENT',
    OTHER = 'OTHER',
}