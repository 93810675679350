import { JobRequestStatus } from "gen/clients/llts/models/JobRequestStatus";
import { TFunction } from "react-i18next";

export enum MtRequestStatus {
  COMPLETED = "Completed",
  ARCHIVED = "Archived",
  SUBMITTING_TO_HUMAN_EXPERT = "Submitting to Human Expert",
  SUBMITTED_TO_HUMAN_EXPERT = "Submitted to Human Expert",
  FAILED = "Failed",
  PROCESSING = "Processing",
  UNKNOWN = "Unknown"
}

const mtStatusToJobRequestStatusesMap: Partial<Record<MtRequestStatus, JobRequestStatus[]>> = {
  [MtRequestStatus.COMPLETED]: [JobRequestStatus.COMPLETED],
  [MtRequestStatus.ARCHIVED]: [JobRequestStatus.ARCHIVED],
  [MtRequestStatus.SUBMITTING_TO_HUMAN_EXPERT]: [JobRequestStatus.SUBMITTING_TO_XTRF],
  [MtRequestStatus.SUBMITTED_TO_HUMAN_EXPERT]: [JobRequestStatus.SUBMITTED_TO_XTRF],
  [MtRequestStatus.FAILED]: [JobRequestStatus.CANCELLED, JobRequestStatus.CANCELLING, JobRequestStatus.FAILED],
  [MtRequestStatus.PROCESSING]: [
    JobRequestStatus.ANALYZING,
    JobRequestStatus.CREATED,
    JobRequestStatus.READY_FOR_INTERNAL_REVIEW,
    JobRequestStatus.SCREENING,
    JobRequestStatus.REJECTED_BY_SCREENING,
    JobRequestStatus.TRANSLATING
  ]
};

const jobRequestStatusToClientStatusMap: Partial<Record<JobRequestStatus, MtRequestStatus>> = Object.entries(
  mtStatusToJobRequestStatusesMap
).reduce(
  (acc, [clientStatus, jobRequestStatuses]) => {
    jobRequestStatuses.forEach(status => {
      acc[status] = clientStatus as MtRequestStatus;
    });
    return acc;
  },
  {} as Partial<Record<JobRequestStatus, MtRequestStatus>>
);

export class MtRequestUtils {
  /**
   * Gets the corresponding MtRequestStatus from the given JobRequestStatus.
   *
   * @param status The JobRequestStatus to map.
   * @returns The corresponding MtRequestStatus, or MtRequestStatus.UNKNOWN if the status is not mapped.
   */
  static getMtRequestStatus(status: JobRequestStatus): MtRequestStatus {
    return jobRequestStatusToClientStatusMap[status] || MtRequestStatus.UNKNOWN;
  }

  /**
   * Retrieves the list of JobRequestStatus values associated with the given MtRequestStatus.
   *
   * @param mtStatus The MtRequestStatus to retrieve the associated JobRequestStatus list for.
   * @returns An array of JobRequestStatus associated with the given MtRequestStatus, or an empty array if none are found.
   */
  static getJobRequestStatuses(mtStatus: MtRequestStatus): JobRequestStatus[] {
    return mtStatusToJobRequestStatusesMap[mtStatus] || [];
  }

  /**
   * Describes the MtRequestStatus by translating it into a human-readable string.
   *
   * @param mtStatus The MtRequestStatus to describe.
   * @param t The translation function from react-i18next for internationalization.
   * @returns A string describing the MtRequestStatus, translated into the current language.
   */
  static describeMtRequestStatus(mtStatus: MtRequestStatus, t: TFunction): string {
    const statusTranslationMap: Partial<Record<MtRequestStatus, string>> = {
      [MtRequestStatus.COMPLETED]: t("machineTranslation.requestStatus.completed"),
      [MtRequestStatus.ARCHIVED]: t("machineTranslation.requestStatus.archived"),
      [MtRequestStatus.SUBMITTING_TO_HUMAN_EXPERT]: t("machineTranslation.requestStatus.submittingForHumanTranslation"),
      [MtRequestStatus.SUBMITTED_TO_HUMAN_EXPERT]: t("machineTranslation.requestStatus.submittedForHumanTranslation"),
      [MtRequestStatus.FAILED]: t("machineTranslation.requestStatus.failed"),
      [MtRequestStatus.PROCESSING]: t("machineTranslation.requestStatus.processing")
    };

    return statusTranslationMap[mtStatus] || t("machineTranslation.requestStatus.unknown");
  }
}
