import * as React from "react";
import { useUserSession } from "../../hooks/useUserSession";
import { SignedInUserPage } from "./SignedInUserPage/SignedInUserPage";
import { UnsignedInUserPage } from "./UnsignedInUserPage/UnsignedInUserPage";
import HomeCrumb from "../../components/Breadcrumb/HomeCrumb";

const CreateProjectPage: React.FC = () => {
  const { email } = useUserSession();

  return (
    <>
      <div style={{ position: "absolute" }}>
        <HomeCrumb />
      </div>
      {email ? <SignedInUserPage email={email} /> : <UnsignedInUserPage />}
    </>
  );
};

export { CreateProjectPage };
