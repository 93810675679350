// InlineEditableTextField.tsx
import React, { useState, useEffect, useRef, useCallback } from "react";
import { InputBase, IconButton, Paper } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

interface InlineEditableTextFieldProps {
  initialValue: string;
  onSave: (value: string) => void;
  fontSize?: number;
}

const InlineEditableTextField: React.FC<InlineEditableTextFieldProps> = ({ initialValue, onSave, fontSize }) => {
  const [editing, setEditing] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const handleSaveClick = useCallback(() => {
    if (value.trim() !== initialValue.trim()) {
      onSave(value.trim());
    }
    setEditing(false);
    setMouseOver(false);
    inputRef.current?.blur();
  }, [initialValue, onSave, value]);

  const handleCancelClick = useCallback(() => {
    setValue(initialValue);
    setEditing(false);
    setMouseOver(false);
    inputRef.current?.blur();
  }, [initialValue]);

  const onBlur = (event: React.FocusEvent) => {
    if (event.relatedTarget?.id === "cancelBtn") {
      handleCancelClick();
    } else {
      handleSaveClick();
    }
  };

  const handleTextClick = () => {
    if (!editing) {
      setEditing(true);
    }
  };

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleSaveClick();
      } else if (event.key === "Escape") {
        handleCancelClick();
      }
    },
    [handleSaveClick, handleCancelClick]
  );

  return (
    <Paper
      sx={theme => ({
        display: "flex",
        alignItems: "center",
        padding: 1,
        boxShadow: editing ? 2 : 0,
        cursor: "pointer",
        bgcolor: mouseOver && !editing ? theme.palette.grey.A100 : undefined
      })}
      onClick={handleTextClick}
      onBlur={onBlur}
    >
      <InputBase
        sx={{ flex: 1, fontSize }}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setEditing(true)}
        inputRef={inputRef}
        onClick={handleTextClick}
        onMouseOver={() => setMouseOver(true)}
        onMouseOut={() => setMouseOver(false)}
      />
      {editing ? (
        <>
          <IconButton onClick={handleSaveClick} edge="end" color="primary">
            <CheckIcon />
          </IconButton>
          <IconButton id="cancelBtn" onClick={handleCancelClick} edge="end" color="secondary">
            <CancelIcon />
          </IconButton>
        </>
      ) : (
        <></>
      )}
    </Paper>
  );
};

export { InlineEditableTextField };
