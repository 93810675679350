import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Auth } from "@aws-amplify/auth";
import { red } from "@mui/material/colors";
import { LoadingButton } from "../../../LoadingButton/LoadingButton";
import { TextInputField } from "../../../formikFields/TextInputField/TextInputField";
import { usePasswordValidator } from "../../../../hooks/usePasswordValidator/usePasswordValidator";

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

const ChangePasswordDialog: React.FC<Props> = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { validatePassword } = usePasswordValidator();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const validate = React.useCallback(
    values => {
      const errors: Record<string, string> = {};
      if (!values.oldPassword) {
        errors.oldPassword = t("common.validation.required");
      }
      const passwordError = validatePassword(values.newPassword);
      if (passwordError) {
        errors.newPassword = passwordError;
      }
      if (values.newPassword !== values.repeatNewPassword) {
        errors.repeatNewPassword = t("appHeader.userMenu.changePassword.repeatNotMatching");
      }
      return errors;
    },
    [t, validatePassword]
  );

  const onDialogClose = React.useCallback(
    (event, reason) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    },
    [onClose]
  );

  const onSubmit = React.useCallback(
    async values => {
      setErrorMessage(undefined);
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, values.oldPassword, values.newPassword);
        onSuccess();
      } catch (e) {
        if ((e as Error).name === "NotAuthorizedException") {
          setErrorMessage(t("appHeader.userMenu.changePassword.invalidOldPassword"));
        } else {
          setErrorMessage((e as Error).message);
        }
      }
    },
    [onSuccess, t]
  );

  return (
    <Dialog open={true} onClose={onDialogClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Change Password</DialogTitle>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          repeatNewPassword: ""
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <Form noValidate={true} autoComplete="off" autoCorrect="off">
            <DialogContent>
              {errorMessage && <Box sx={{ color: red["500"], textAlign: "center", mb: 2 }}>{errorMessage}</Box>}
              <Stack spacing={2}>
                <TextInputField
                  name="oldPassword"
                  label={t("appHeader.userMenu.changePassword.oldPassword")}
                  required={true}
                  variant="outlined"
                  type="password"
                  autoComplete="off"
                />
                <TextInputField
                  name="newPassword"
                  label={t("appHeader.userMenu.changePassword.newPassword")}
                  required={true}
                  variant="outlined"
                  type="password"
                  autoComplete="off"
                />
                <TextInputField
                  name="repeatNewPassword"
                  label={t("appHeader.userMenu.changePassword.confirmPassword")}
                  required={true}
                  variant="outlined"
                  type="password"
                  autoComplete="off"
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <LoadingButton isLoading={isSubmitting}>
                {t("appHeader.userMenu.changePassword.changePasswordBtn")}
              </LoadingButton>
              <Button onClick={onClose}>{t("common.cancel")}</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export { ChangePasswordDialog };
