import React, { FC, useState } from "react";
import { IAppCardProps } from "interfaces/appCard";
import { useTranslation } from "react-i18next";
import styles from "./AppCard.module.scss";

const AppCard: FC<IAppCardProps> = props => {
  const { t } = useTranslation();
  const focusBackgroundColor = "#5a97cc";

  const { rootResource, onClick } = props;

  const [isMouseOver, setMouseOver] = useState(false);
  const imgAlt = `${rootResource}_img`;
  const theImage = `/images/services/${rootResource}.svg`;
  const boxMouseOverHandler = () => {
    setMouseOver(true);
  };

  const boxMouseOutHandler = () => {
    setMouseOver(false);
  };
  const cardFocusStyle = { backgroundColor: focusBackgroundColor };
  const imageFocusStyle = { maxWidth: "65%", maxHeight: "70%" };
  const imageTitleStyle = { fontWeight: "bolder", fontStyle: "italic" };

  return (
    <>
      {/* eslint-disable-next-line */}
      <div className={styles.tool}
        id={rootResource}
        onMouseOver={boxMouseOverHandler}
        onMouseOut={boxMouseOutHandler}
        onClick={onClick}
      >
        <div className={styles.wrap} style={isMouseOver ? cardFocusStyle : {}}>
          <img src={theImage} alt={imgAlt} style={isMouseOver ? imageFocusStyle : {}} />
        </div>
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: t(`${rootResource}.title`) }}
          style={isMouseOver ? imageTitleStyle : {}}
        />
      </div>
    </>
  );
};

export default AppCard;
