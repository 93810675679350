import { LogMessage, UtilsService } from "./gen/clients/llts";

export class GlobalErrorHandler {
  public static init() {
    // For uncaught exceptions
    window.addEventListener("error", event => {
      console.error("Global error handler:", event.error);
      const err: Error | undefined = event.error;
      UtilsService.logUiMessage({
        requestBody: {
          level: LogMessage.level.ERROR,
          message: `Global error handler: ${event.error}`,
          details: {
            errorMessage: err?.message,
            errorName: err?.name,
            stackTrace: err?.stack,
            cause: err?.cause,
            error: err
          }
        }
      });
    });
  }
}
