/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FohPendingReviewQuoteStat } from '../models/FohPendingReviewQuoteStat';
import type { FohProjectsByIdStat } from '../models/FohProjectsByIdStat';
import type { FohProjectStat } from '../models/FohProjectStat';
import type { FohQuoteType } from '../models/FohQuoteType';
import type { ProjectsByManagerIdStat } from '../models/ProjectsByManagerIdStat';
import type { ProjectsByQaSpecialistIdStat } from '../models/ProjectsByQaSpecialistIdStat';
import type { ProjectsPastDueByManagerIdStat } from '../models/ProjectsPastDueByManagerIdStat';
import type { ProjectStat } from '../models/ProjectStat';
import type { QaProjectStat } from '../models/QaProjectStat';
import type { StatProjectType } from '../models/StatProjectType';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Fetches project count by project manager
     * @returns ProjectStat Returns statistics data
     * @throws ApiError
     */
    public static getStatisticsByType({
        projectType,
    }: {
        /** Type of project **/
        projectType: StatProjectType,
    }): CancelablePromise<Array<ProjectStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/projectStats/${projectType}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches quote count by project coordinator
     * @returns FohProjectStat Returns statistics data
     * @throws ApiError
     */
    public static getFohPcStatisticsByType({
        quoteType,
    }: {
        /** Type of quote **/
        quoteType: FohQuoteType,
    }): CancelablePromise<Array<FohProjectStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/quoteStats/${quoteType}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches pending review quotes count by project coordinator.
     * @returns FohPendingReviewQuoteStat Returns statistics data
     * @throws ApiError
     */
    public static getFohPendingStatistics(): CancelablePromise<Array<FohPendingReviewQuoteStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/fohPendingReviewStats`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches current quote count by project coordinator.
     * @returns FohProjectStat Returns statistics data
     * @throws ApiError
     */
    public static getFohCurrentStatistics(): CancelablePromise<Array<FohProjectStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/fohCurrentProjectStats`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches completed quote count by project coordinator.
     * @returns FohProjectStat Returns statistics data
     * @throws ApiError
     */
    public static getFohCompletedStatistics(): CancelablePromise<Array<FohProjectStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/fohCompletedProjectStats`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches QA stats for all QA specialist
     * @returns QaProjectStat Returns statistics data
     * @throws ApiError
     */
    public static getQaStats(): CancelablePromise<Array<QaProjectStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/qaStats`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches projects for QA specialist by Id
     * @returns ProjectsByQaSpecialistIdStat Returns statistics data
     * @throws ApiError
     */
    public static getQaSpecialistStatisticsById({
        id,
    }: {
        /** ID of QA specialist **/
        id: string,
    }): CancelablePromise<Array<ProjectsByQaSpecialistIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/qaSpecialistStats/${id}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches projects for project manager by Id
     * @returns ProjectsByManagerIdStat Returns statistics data
     * @throws ApiError
     */
    public static getStatisticsById({
        id,
    }: {
        /** ID of project manager **/
        id: string,
    }): CancelablePromise<Array<ProjectsByManagerIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/projectManagerStats/${id}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches current projects for project coordinator by Id
     * @returns FohProjectsByIdStat Returns statistics data
     * @throws ApiError
     */
    public static getProjectCoordinatorCurrentStatisticsById({
        id,
    }: {
        /** ID of project coordinator **/
        id: string,
    }): CancelablePromise<Array<FohProjectsByIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/projectCoordinatorCurrentStats/${id}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches current Unassigned projects
     * @returns FohProjectsByIdStat Returns statistics data
     * @throws ApiError
     */
    public static getProjectCoordinatorCurrentUnassignedStatistics(): CancelablePromise<Array<FohProjectsByIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/projectCoordinatorCurrentUnassignedStats`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches completed projects for project coordinator by Id
     * @returns FohProjectsByIdStat Returns statistics data
     * @throws ApiError
     */
    public static getProjectCoordinatorCompletedStatisticsById({
        id,
    }: {
        /** ID of project coordinator **/
        id: string,
    }): CancelablePromise<Array<FohProjectsByIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/projectCoordinatorCompletedStats/${id}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches past due projects for project manager by Id
     * @returns ProjectsPastDueByManagerIdStat Returns statistics data
     * @throws ApiError
     */
    public static getPastDueStatisticsById({
        id,
    }: {
        /** ID of project manager **/
        id: string,
    }): CancelablePromise<Array<ProjectsPastDueByManagerIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/statsPastDue/${id}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches due today projects for project manager by Id
     * @returns ProjectsPastDueByManagerIdStat Returns statistics data
     * @throws ApiError
     */
    public static getDueTodayStatisticsById({
        id,
    }: {
        /** ID of project manager **/
        id: string,
    }): CancelablePromise<Array<ProjectsPastDueByManagerIdStat>> {
        return __request({
            method: 'GET',
            path: `/statistics/statsDueToday/${id}`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

}